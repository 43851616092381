import React, { useState } from "react";
import constiCon from "./BranchCon";

const BranchState = (props) => {
  const host = process.env.REACT_APP_URL;
  const token = localStorage.getItem("token");
  const [branchData, setBranchData] = useState([]);
  const [selected_branch, setSelected_branch] = useState({});

  const fetData = async () => {
    console.log("call data");
    setSelected_branch(JSON.parse(localStorage.getItem("selected_branch")));
  };

  const setSelected_branchfunc = async (obj) => {
    localStorage.setItem("selected_branch", JSON.stringify(obj));
    setSelected_branch(JSON.parse(JSON.stringify(obj)));
  };

  const getData = async () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "auth-token": token,
      },
    };
    const response = await fetch(`${host}/api/branch/`, requestOptions);
    const datares = await response.json();

    console.log(datares);
    if (datares.status === "Success") {
      setBranchData(datares.data);
    }
  };

  const addBranch = async (data) => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "auth-token": token,
      },
      body: JSON.stringify(data),
    };
    const response = await fetch(`${host}/api/branch/stepone`, requestOptions);
    const datares = await response.json();

    console.log(datares);
    if (datares.status === "Success") {
      setBranchData(branchData.concat(datares.data));
    }

    return datares;
  };

  const deleteBranch = async (id) => {
    const requestOptions = {
      method: "delete",
      headers: {
        "Content-Type": "application/json",
        "auth-token": token,
      },
    };
    const response = await fetch(`${host}/api/branch/${id}`, requestOptions);
    const datares = await response.json();

    console.log(datares);
    if (datares.status === "Success") {
      const data = branchData.filter((con) => {
        return con._id != id;
      });
      setBranchData(data);
      // const upateData = datares.data.filter((cat) => {
      //   return cat.cat_type === type;
      // });
      // districtget(data.state_id)
    }
    return datares.status;
  };

  const addSession = async (type, id, data) => {
    const requestOptions = {
      method: "put",
      headers: {
        "Content-Type": "application/json",
        "auth-token": token,
      },
      body: JSON.stringify(data),
    };
    const response = await fetch(
      `${host}/api/branch/updateBranch/${type}/${id}`,
      requestOptions
    );
    const datares = await response.json();

    console.log(datares);
    if (datares.status === "Success") {
      const data = branchData.map((con) => {
        return con._id == id ? datares.data : con;
      });
      setBranchData(data);
    }
    return datares.status;
  };

  const activeSession = async (id, s_id) => {
    const requestOptions = {
      method: "put",
      headers: {
        "Content-Type": "application/json",
        "auth-token": token,
      },
    };
    const response = await fetch(
      `${host}/api/branch/setSessionCurrent/${id}/${s_id}`,
      requestOptions
    );
    const datares = await response.json();

    console.log(datares);
    if (datares.status === "Success") {
      const data = branchData.map((con) => {
        return con._id == id ? datares.data : con;
      });
      setBranchData(data);
    }
    return datares.status;
  };

  return (
    <constiCon.Provider
      value={{
        branchData,
        addBranch,
        getData,
        deleteBranch,
        addSession,
        activeSession,
        selected_branch,
        setSelected_branchfunc,
        fetData
      }}
    >
      {props.children}
    </constiCon.Provider>
  );
};
export default BranchState;
