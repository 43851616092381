import React from "react";

const Contact = () => {
  return (
    <div className="container my-5" style={{ textAlign: "left" }}>
      <div className="row">
        <div className="col-10 mx-auto col-md-6">
          <div className="card">
            <div className="card-body">
              <h6 className="card-title">Office</h6>
              <div className="card-text">
                <ul>
                  <li>
                    <strong>Address - </strong> Saket Colony, Hathras, U.P.
                    India 204101{" "}
                  </li>
                  <li>
                    <strong>Phone No. - </strong> +91 6396617714{" "}
                  </li>
                  <li>
                    <strong>Mail - </strong> info@vipsgrow.in{" "}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="col-10 mx-auto col-md-6 mt-5 mt-md-0">
          <h5>Request a call back...</h5>
          <form>
            <div className="mb-3">
              <label for="exampleInputEmail1" className="form-label">
                Email address
              </label>
              <input
                type="email"
                className="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                placeholder="Enter Your Email"
              />
            </div>
            <div className="mb-3">
              <label for="exampleInputPassword1" className="form-label">
                Mobile Number
              </label>
              <input
                type="tel"
                pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                required
                className="form-control"
                id="exampleInputPassword1"
                placeholder="123-456-7890"
              />
            </div>

            <div className="mb-3">
              <label for="message" className="form-label">
                Your Message
              </label>
              <textarea className="form-control" rows={5} id="message" placeholder="Type Your Message..."></textarea>
            </div>
            <button type="submit" className="btn btn-primary">
              Submit
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Contact;
