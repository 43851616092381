import React, { useEffect, useRef, useState } from "react";
import "./header.css";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchDataFromLocal,
  headerConfigUpload,
} from "../../redux/reducer/branchReducer";

const HeaderConfig = () => {
  const bLogo = useRef();
  const header = useRef();
  const footer = useRef();

  const b_data = useSelector((state) => state.fetchDataFromLocal);
  const c_session = useSelector((state) => state.currentSession);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchDataFromLocal());
  }, []);

  const openclick = (type) => {
    switch (type) {
      case "logo":
        bLogo.current.click();
        break;
      case "header":
        header.current.click();
        break;
      case "footer":
        footer.current.click();
        break;
      default:
        break;
    }
  };

  const [chooseFile, setChooseFile] = useState({
    logo: "",
    header: "",
    footer: "",
  });

  const onchangeListener = (e, type) => {
    if (type === "logo") {
      setChooseFile({ ...chooseFile, logo: e.target.files[0] });
    } else if (type === "header") {
      setChooseFile({ ...chooseFile, header: e.target.files[0] });
    } else if (type === "footer") {
      setChooseFile({ ...chooseFile, footer: e.target.files[0] });
    }
  };

  const uploadToServer = () => {
    const formData = new FormData();
    formData.append("b_id", b_data._id);
    formData.append("logo", chooseFile.logo);
    formData.append("header", chooseFile.header);
    formData.append("footer", chooseFile.footer);
    formData.append("session", c_session.session_year);
    dispatch(headerConfigUpload(formData));
  };

  const [uploadedFile, setUploadedFile] = useState(null);

  useEffect(() => {
    if (b_data.config) {
      const data = b_data.config.filter((con) => {
        return con.session === c_session.session_year;
      });
      if (data.length > 0) {
        setUploadedFile(data[0]);
      }
    }
  }, [b_data.config]);

  return (
    <>
      <div className="content-wrapper payments">
        <div className="container-xxl flex-grow-1 container-p-y">
          <div className="row">
            <div className="col-lg-12 mb-4 ">
              <div className="row">
                <div className="col-lg-4 font-bold"></div>

                <div className="col-12 mt-2">
                  <div className="card" style={{ border: "none" }}>
                    <h6 className="card-header text-left">
                      Some Template Configuration
                    </h6>
                    <div className="card-body text-left">
                      <div className="row">
                        <div className="col-lg-2 col-12 mb-4 mb-lg-0">
                          <div
                            className="logo_select_div"
                            onClick={() => {
                              openclick("logo");
                            }}
                          >
                            <span className="">
                              <i className="fa fa-image me-2" />
                              Branch Logo
                            </span>
                            {chooseFile.logo === "" ? (
                              ""
                            ) : (
                              <img
                                src={URL.createObjectURL(chooseFile.logo)}
                                alt="School Logo"
                                className="img-fluid"
                              />
                            )}

                            {uploadedFile && (
                              <img
                                src={uploadedFile.logo}
                                alt="School Logo"
                                className="img-fluid"
                              />
                            )}
                          </div>
                          <input
                            type="file"
                            ref={bLogo}
                            className="form-control d-none"
                            onChange={(e) => {
                              onchangeListener(e, "logo");
                            }}
                          />
                        </div>
                        <div className="col-lg-10 col-12">
                          <div
                            className="header_select_div"
                            onClick={() => {
                              openclick("header");
                            }}
                          >
                            <span className="">
                              <i className="fa fa-image me-2" />
                              Header
                            </span>
                            {chooseFile.header === "" ? (
                              ""
                            ) : (
                              <img
                                src={URL.createObjectURL(chooseFile.header)}
                                alt="Header Logo"
                                className="img-fluid"
                              />
                            )}

                            {uploadedFile && (
                              <img
                                src={uploadedFile.header}
                                alt="School header"
                                className="img-fluid"
                              />
                            )}
                          </div>
                          <input
                            type="file"
                            ref={header}
                            className="form-control d-none"
                            onChange={(e) => {
                              onchangeListener(e, "header");
                            }}
                          />

                          <div
                            className="header_select_div mt-5"
                            onClick={() => {
                              openclick("footer");
                            }}
                          >
                            <span className="">
                              <i className="fa fa-image me-2" />
                              Footer
                            </span>
                            {chooseFile.footer === "" ? (
                              ""
                            ) : (
                              <img
                                src={URL.createObjectURL(chooseFile.footer)}
                                alt="Header Logo"
                                className="img-fluid"
                              />
                            )}

                            {uploadedFile &&
                              (uploadedFile.footer ? (
                                <img
                                  src={uploadedFile.footer}
                                  alt="School footer"
                                  className="img-fluid"
                                />
                              ) : (
                                ""
                              ))}
                          </div>
                          <input
                            type="file"
                            ref={footer}
                            className="form-control d-none"
                            onChange={(e) => {
                              onchangeListener(e, "footer");
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="card-footer text-right">
                      <button
                        className="btn btn-primary"
                        onClick={uploadToServer}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HeaderConfig;
