import React, { useState } from "react";
import { StudentFormCon } from "../AllContext";

export const StudentFormState = (props) => {
  const host = process.env.REACT_APP_URL;
  const token = localStorage.getItem("token");
  const b_data = JSON.parse(localStorage.getItem("selected_branch"));
  const [studentForms, setStudentForms] = useState([]);

  const [byIdForm, setbyIdForm] = useState(null);

  const getData = async () => {
    const b_id = b_data._id;
    
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "auth-token": token,
      },
    };
    const response = await fetch(`${host}/api/studentform/getStuForm/${b_id}`, requestOptions);
    const datares = await response.json();

    console.log(datares);
    if (datares.status === "Success") {
      setbyIdForm(datares.data);
    }

    return datares.status;
  };

  const addform = async (fields) => {
    
    const b_id = b_data._id;
    
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "auth-token": token,
      },
      body: JSON.stringify({ fields, b_id }),
    };
    const response = await fetch(
      `${host}/api/studentform/addStuForm`,
      requestOptions
    );
    const datares = await response.json();

    console.log(datares);
    if (datares.status === "Success") {
      setStudentForms(studentForms.concat(datares.data));
    }

    return {status:datares.status, message:datares.message};
  };


  const getAllDataByB_id = async () => {
    const b_id = b_data._id;
    
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "auth-token": token,
      },
    };
    const response = await fetch(`${host}/api/studentform/getStuFormBranch/${b_id}`, requestOptions);
    const datares = await response.json();

    console.log(datares);
    if (datares.status === "Success") {
      setStudentForms(datares.data);
    }

    return datares.status;
  };

  const [registerationForm, setRegistrationForm] = useState(null)

  const getFormForCreateStudent = async () => {
    const b_id = b_data._id;
    const session = b_data.sessions.filter((ses)=>{
      return ses.current
    });
    const session_year = session[0].session_year;
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "auth-token": token,
      },
    };
    const response = await fetch(`${host}/api/studentform/form_by_id_session/${b_id}/${session_year}`, requestOptions);
    const datares = await response.json();

    console.log(datares);
    if (datares.status === "Success") {
      setRegistrationForm(datares.data);
    }

    return datares;
  }

  return (
    <StudentFormCon.Provider value={{ byIdForm,studentForms, getAllDataByB_id, addform, getData, getFormForCreateStudent,registerationForm }}>
      {props.children}
    </StudentFormCon.Provider>
  );
};

export default StudentFormState;
