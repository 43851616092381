import { showMyAlert } from "../action";
import { STUDENT_FORM } from "../action/constant";
import {
  studentUploadFailure,
  studentUploadSuccess,
} from "../action/studentFormAction";
import serverCallFuction from "../constantFunction";
const iniState = null;
export const CreateStudentForm = (params) => async (dispatch) => {
  try {
    const url = `studentform/form_by_id_session/${params.b_id}/${params.session_year}`;

    const res = await serverCallFuction("GET", url);
    if (res.status === "Success") {
      dispatch(showMyAlert(res.message, "success"));
      dispatch(studentUploadSuccess(res, "createFormFound"));
    } else {
      dispatch(showMyAlert(res.message, "warning"));
    }
  } catch (error) {
    dispatch(studentUploadFailure());
    dispatch(showMyAlert("Exeption", "warning"));
  }
};


const creatForm = (state = iniState, action) => {
  // return state
  switch (action.type) {
    case STUDENT_FORM.success:
      if (action.u_type === "createFormFound") {
        state = action.payload.data;
      }
      return state;
    default:
      return state;
  }
};

export default creatForm;
