import React from 'react'
import "./footer.css"
import { Link } from 'react-router-dom';
const Banner = () => {
  return (
    <>
      <div className="free_trial_banner d-none d-md-block">
        <div className="banner-wrapper d-flex py-2">
          <span className="banner">Explore IVIDHAN with 7 day free trial.</span>
          <Link to={
            "/admin"
          }>
            <span className="explorebtn">
            Go Now
          </span>
          </Link>
        </div>
      </div>
    </>
  );
}

export default Banner
