import React, { useEffect, useState } from "react";
import "./check.css";
import { useNavigate, useParams } from "react-router-dom";
import serverCallFuction from "../../../adminLayout/redux/constantFunction";
const Checkout = () => {
  const params = useParams();
  const [planData, setPlanData] = useState(null);

  const [priceData, setPriceData] = useState({ price: 0, gst: 0, amount: 0 });
  const navigation = useNavigate();

  const [userData, setUserData] = useState(null);

  useEffect(() => {
    const scall = async () => {
      const res = await serverCallFuction(
        "GET",
        "subscription/subs_by_id/" + params.id
      );
      if (res.status === "Success") {
        setPlanData(res.data);
        const price = parseInt(res.data.price.replace(",", ""));
        setPriceData({
          price: price,
          gst: price * 0.18,
          amount: price + price * 0.18,
        });
      }
    };

    const token = localStorage.getItem("token");
    if (token === null) {
      navigation("../../auth/checkout");
    } else {
      const user_local_data = JSON.parse(localStorage.getItem("admin_data")) 
      setUserData(user_local_data);
      if(user_local_data.hasOwnProperty("subscribe_id") && user_local_data.subscribe_id !== null){
        navigation("../../admin");
      }
    }

    scall();
  }, []);

  const [rzp1, setRzp1] = useState(null);
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.async = true;
    document.body.appendChild(script);

    const price = parseInt(planData && planData.price.replace(",", "")) * 100;
    //  rzp_test_CVvYr3xMOI70nb
    script.onload = () => {
      const options = {
        key: "rzp_live_20qqgcXkcf2taV",
        amount: priceData.amount * 100,
        currency: "INR",
        name: "IVIDHAN",
        description: "A School Management System",
        image: "http://ividhan.vipsgrow.in/assets/images/ividhan logo.png",

        prefill: {
          name: userData && userData.fullname, //your customer's name
          email: userData && userData.email,
          contact: userData && userData.phone, //Provide the customer's phone number for better conversion rates
        },
        notes: {
          address: "Razorpay Corporate Office",
        },
        theme: {
          color: "#00025D",
        },

        handler: onResponse,

        // function (response) {
        //   alert(response.razorpay_payment_id);
        //   alert(response.razorpay_order_id);
        //   alert(response.razorpay_signature);
        // },
      };
      const rzpInstance = new window.Razorpay(options);
      rzpInstance.on("payment.failed", function (response) {
        alert(response.error.code);
        alert(response.error.description);
        alert(response.error.source);
        alert(response.error.step);
        alert(response.error.reason);
        alert(response.error.metadata.order_id);
        alert(response.error.metadata.payment_id);
      });
      setRzp1(rzpInstance);
    };

    return () => {
      if (script.parentNode) {
        script.parentNode.removeChild(script);
      }
    };
  }, [planData]);

  const pay_now_function = () => {
    if (rzp1) {
      rzp1.open();
    }
  };

  const onResponse = async (response) => {
    console.log(response.razorpay_payment_id);

    const upgrade = await serverCallFuction("POST", "user/upgrade_plan", {
      sub_id: planData._id,
      userid: userData._id,
      payment_id: response.razorpay_payment_id,
    });

    if (upgrade.status === "Success") {
      alert("Account has been upgraded successfully");
      localStorage.setItem("admin_data", JSON.stringify(upgrade.data));
      navigation("/admin");
      
    } else {
      alert(
        "Something went wrong! Plesae note payment_id - " +
          response.razorpay_payment_id +
          " and mail us on ividhanpay@vipsgrow.in."
      );
    }
  };

  

  return (
    <>
      <div className="container my-3">
        <div className="row">
          <div className="col-lg-12 col-md-12 mt-5">
            <h2 className="checkout_heading">Checkout</h2>
          </div>
          <div className="col-lg-7 col-md-12 mt-4">
            <span className="billing_btn">Billing Details</span>

            <form>
              <div className="row">
                <div className="col">
                  <div class="form-group">
                    <label for="exampleInputPassword1">Name</label>

                    <input
                      type="text"
                      class="form-control mb-2"
                      id="address"
                      disabled
                      aria-describedby="helpId"
                      placeholder="Address"
                      value={userData && userData.fullname}
                    />
                  </div>
                </div>
                <div className="col">
                  <div class="form-group">
                    <label for="exampleInputPassword1">Phone</label>

                    <input
                      type="text"
                      class="form-control mb-2"
                      id="address"
                      disabled
                      aria-describedby="helpId"
                      placeholder="Address"
                      value={userData && userData.phone}
                    />
                  </div>
                </div>
              </div>

              <div class="form-group">
                <label for="exampleInputPassword1">Address</label>

                <input
                  type="address"
                  class="form-control mb-2"
                  id="address"
                  aria-describedby="helpId"
                  placeholder="Address"
                />
              </div>

              <div className="row">
                <div className="col">
                  <div class="form-group">
                    <label for="exampleInputPassword1">State</label>

                    <input
                      type="state"
                      class="form-control mb-2"
                      id="state"
                      aria-describedby="helpId"
                      placeholder="State"
                    />
                  </div>
                </div>

                <div className="col">
                  <div class="form-group">
                    <label for="city">City</label>

                    <input
                      type="city"
                      class="form-control mb-2"
                      id="city"
                      aria-describedby="helpId"
                      placeholder="City"
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col">
                  <div class="form-group">
                    <label for="exampleInputPassword1">Zip Code</label>

                    <input
                      type="zip code"
                      class="form-control mb-2"
                      id="zip code"
                      aria-describedby="helpId"
                      placeholder="Zip Code"
                    />
                  </div>
                </div>

                <div className="col">
                  <div class="form-group">
                    <label for="exampleInputPassword1">Country</label>

                    <input
                      type="country"
                      class="form-control mb-2"
                      id="country"
                      aria-describedby="helpId"
                      placeholder="Country"
                    />
                  </div>
                </div>
              </div>

              <div class="form-group">
                <label for="exampleInputPassword1">GST No. (Optional)</label>

                <input
                  type="text"
                  class="form-control mb-2"
                  name="name"
                  id=""
                  aria-describedby="helpId"
                  placeholder="Phone Number"
                />
              </div>
            </form>
          </div>
          <div className="col-lg-5 col-md-12 mt-5">
            <div className="bod-Container">
              <div className="card">
                <div className="card-header">Your Order</div>
                <div className="card-body" style={{ padding: "35px" }}>
                  <div className="plan_container d-flex justify-content-between">
                    <span className="plan_hed">Plan Name</span>
                    <span className="card-title">
                      {planData && planData.planName}
                    </span>
                  </div>
                  <span className="featuresDiv">Plan Features</span>

                  <ul className="feature_list mt-2">
                    {planData &&
                      planData.features.map((feature, index) => (
                        <li key={index}>{feature.feature_values}</li>
                      ))}
                  </ul>

                  <ul className="m-0 p-0 plan_containern">
                    <li className="d-flex justify-content-between">
                      <div className="plan_hed">Price</div>
                      <div className="priceHold">
                        ₹{priceData && priceData.price}.00
                      </div>
                    </li>

                    <li className="d-flex justify-content-between my-2">
                      <div className="plan_hed">GST 18%</div>
                      <div className="priceHold">
                        ₹{priceData && priceData.gst}
                      </div>
                    </li>

                    <li className="d-flex justify-content-between ">
                      <div className="plan_hed">Total Amount</div>
                      <div className="priceHold">
                        ₹{priceData && priceData.amount}
                      </div>
                    </li>
                  </ul>
                </div>
                <div className="card-footer">
                  <button
                    className="pay_now btn btn-primary"
                    onClick={pay_now_function}
                  >
                    Pay Now
                  </button>
                </div>
              </div>
            </div>

            {/*  <button type="submit" class="btn btn-primary">
              Submit
            </button> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Checkout;
