import serverCallFuction from "../constantFunction";
import { API_CALL_STATUS } from "../action/constant";
import {
  uploadSuccess,
  uploadFailure,
  showAlertAction,
  showMyAlert,
} from "../action";

const initialState = [];

export const getStudentData = (payload) => async (dispatch) => {
  try {
    const res = await serverCallFuction(
      "GET",
      `student/getStudents/${payload.b_id}`
    );
    console.log(res);

    if (res.status === "Failed") {
      dispatch(uploadFailure());
      dispatch(showMyAlert(res.message, "danger"));
      // dispatch(showAlertAction(res.message, "warning"));
      // You can also dispatch showAlert action here if needed
    } else {
      dispatch(uploadSuccess(res, "getStudents"));
      dispatch(showMyAlert(res.message, "success"));
      // You can also dispatch showAlert action here if needed
    }
  } catch (error) {
    console.error("Error uploading image:", error);
    dispatch(uploadFailure());
    // You can also dispatch showAlert action here if needed
  }
};

export const addExamData = (payload, params) => async (dispatch) => {
  try {
    const res = await serverCallFuction(
      "POST",
      `student/exam_entry/${params.s_id}`,
      payload
    );
    console.log(res);

    if (res.status === "Failed") {
      dispatch(uploadFailure());
      dispatch(showMyAlert(res.message, "danger"));
    } else {
      dispatch(uploadSuccess(res, "exam"));
      dispatch(showMyAlert(res.message, "success"));
    }
  } catch (error) {
    console.error("Error uploading image:", error);
    dispatch(uploadFailure());
  }
};

// get Students
export const allStudentList = (params) => async (dispatch) => {
  try {
    const res = await serverCallFuction(
      "GET",
      `student/getStudents/${params.b_id}`
    );

    if (res.status === "Success") {
      dispatch(showMyAlert("Stdents found", "success"));
      dispatch(uploadSuccess(res, "getStudents"));
    } else {
      dispatch(showMyAlert("Something went wrong!", "warning"));
    }
  } catch (error) {
    console.error("Error uploading image:", error);
    dispatch(showMyAlert("Execption e-201", "danger"));
  }
};

export const allStudents = (state = [], action) => {
  switch (action.type) {
    case API_CALL_STATUS.success:
      if (action.u_type === "getStudents") {
        state = action.payload.data;
      }
      return state;
    default:
      return state;
  }
};

export const addStudents = (body, callBack) => async (dispatch) => {
  try {
    const res = await serverCallFuction("POST", `student/add/`, body);

    console.log("create student - ", res);

    if (res.status === "Success") {
      dispatch(showMyAlert("Stdents found", "success"));
      dispatch(uploadSuccess(res, "addStudent"));

      if (typeof callBack === "function") {
        callBack();
      }
    } else {
      dispatch(showMyAlert("Something went wrong!", "warning"));
    }
  } catch (error) {
    console.error("Error uploading image:", error);
    dispatch(showMyAlert("Execption e-201", "danger"));
  }
};

export const DeleteStudents = (id) => async (dispatch) => {
  try {
    const res = await serverCallFuction("DELETE", `student/${id}`);

    if (res.status === "Success") {
      dispatch(showMyAlert("Stdents found", "success"));
      dispatch(uploadSuccess(res, "deleteStudent_" + id));
    } else {
      dispatch(showMyAlert("Something went wrong!", "warning"));
    }
  } catch (error) {
    console.error("Error uploading image:", error);
    dispatch(showMyAlert("Execption e-201", "danger"));
  }
};

const studentReducer = (state = initialState, action) => {
  console.log("Success result student reducer");
  switch (action.type) {
    case API_CALL_STATUS.success:
      // Handle success if needed
      if (action.u_type === "getStudents") {
        state = action.payload.data;
      } else if (action.u_type === "add") {
        state.push(action.payload.data);
      } else if (action.u_type === "update") {
        // const data = state.filter((student)=>{
        //   return student._id === action.payload.data._id
        // })
        console.log(action);
      } else if (action.u_type === "addStudent") {
        state = [...state, action.payload.data];
      } else if (action.u_type.includes("deleteStudent")) {
        const data = action.u_type.split("_");
        state = state.filter((student) => {
          return student._id !== data[1];
        });
      }

      return state;

    case API_CALL_STATUS.failed:
      // Handle failure if needed
      return state;

    default:
      return state;
  }
};

export const feesEntry =
  (params, body, closeModalCallback) => async (dispatch) => {
    try {
      const res = await serverCallFuction(
        "POST",
        `fees/payFees/${params.session}/${params.s_id}`,
        body
      );
      console.log(res);

      if (res.status === "Failed") {
        dispatch(uploadFailure());
        dispatch(showMyAlert(res.message, "danger"));
        // dispatch(showAlertAction(res.message, "warning"));
        // You can also dispatch showAlert action here if needed
      } else {
        dispatch(uploadSuccess(res, "update"));
        dispatch(showMyAlert("Fees entry has been added.", "success"));
        if (typeof closeModalCallback === "function") {
          closeModalCallback(res);
        }
        // You can also dispatch showAlert action here if needed
      }
    } catch (error) {
      console.error("Error uploading image:", error);
      dispatch(uploadFailure());
      // You can also dispatch showAlert action here if needed
    }
  };

export default studentReducer;
