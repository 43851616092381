import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import AddTest from "./AddTest";
import { useReactToPrint } from "react-to-print";
import { useDispatch, useSelector } from "react-redux";
import { getAllBranch } from "../../redux/reducer/branchState";
import {
  GetClassServer,
  addMarksInTest,
} from "../../redux/reducer/class/classReducer";
import { getStudentData } from "../../redux/reducer/studentReducer";
import { showMyAlert } from "../../redux/action";

const AllTest = () => {
  const history = useNavigate();
  const dispatch = useDispatch();
  const [filter, setFilter] = useState("all test");

  const [addTest, setAddTest] = useState(false);
  const pdfRef = useRef();
  const input_ref = useRef();

  const genereatePDF = useReactToPrint({
    content: () => pdfRef.current,
    documentTitle: "Student Data",
  });

  const class_data = useSelector((state) => state.classData);
  const b_data = useSelector((state) => state.fetchDataFromLocal);
  const session = useSelector((state) => state.currentSession);
  const student_list = useSelector((state) => state.getStudentData);
  const [class_filter_id, set_class_filter_id] = useState(null);

  useEffect(() => {
    dispatch(getAllBranch());
  }, []);

  useEffect(() => {
    dispatch(GetClassServer({ b_id: b_data._id }));
    dispatch(getStudentData({ b_id: b_data._id }));
    // dispatch(
    //   CreateStudentForm({
    //     b_id: b_data._id,
    //     session_year: c_session.session_year,
    //   })
    // );
    // dispatch(getImdageData({ b_id: b_data._id }));
  }, [b_data]);

  let sr_no = 0;

  const [selectedData, setSelectedData] = useState(null);

  const [test_student, set_test_student] = useState([]); // {student_id , marks}
  useEffect(() => {
    if (input_ref.current) {
      input_ref.current.value = "";
    }
    if (selectedData) {
      const data = student_list
        .filter((stu) => stu.class_id._id === selectedData.class_id._id)
        .map((stu) => {
          const marks_data = selectedData.student_data.filter((test_stu) => {
            return test_stu.student_id === stu._id;
          });

          const marks =
            marks_data.length > 0 ? parseInt(marks_data[0].marks) : 0;
          return {
            student_id: stu,
            marks: marks,
          };
        });

      set_test_student(data);
    }
  }, [selectedData]);

  console.log("selecte data = ", selectedData);

  console.log("test stu - ", test_student);

  const onChageInTestMarks = (e, obj) => {
    const data = test_student.map((stu) => {
      if (stu.student_id._id === obj.student_id._id) {
        return {
          ...stu,
          marks: e.target.value,
        };
      } else {
        return stu;
      }
    });
    set_test_student(data);
  };

  const updateTestMarks = () => {
    if (test_student.length === 0) {
      dispatch(showMyAlert("Please select test", "warning"));
    } else if (selectedData === null || selectedData === undefined) {
      dispatch(showMyAlert("Please select test", "warning"));
    } else {
      const s_data = test_student.map((stu) => {
        return {
          student_id: stu.student_id._id,
          marks: stu.marks,
        };
      });

      dispatch(
        addMarksInTest(
          {
            session: session.session_year,
            class_id: test_student[0].student_id.class_id._id,
            test_id: selectedData._id,
            student_data: s_data,
          },
          () => {
            setSelectedData(null);
            set_test_student([]);
          }
        )
      );
    }
  };

  return (
    <>
      {addTest && (
        <AddTest
          open={() => {
            setAddTest(false);
            setFilter("all test");
          }}
        />
      )}

      <div className="content-wrapper payments">
        <div className="container-fluid flex-grow-1 container-p-y">
          <div className="row">
            <div className="col-lg-12 mb-4">
              <ul className="slider_button slider-white plain-orange me-2">
                <li
                  className={` me-2 ${
                    filter.includes("all test") ? "slider-active" : ""
                  }`}
                  onClick={() => {
                    setFilter("all test");
                  }}
                >
                  All Tests
                </li>
                <li
                  className={` me-2 ${
                    filter.includes("add test") ? "slider-active" : ""
                  }`}
                  onClick={() => {
                    setFilter("add test");
                    setAddTest(!addTest);
                  }}
                >
                  + Add Test
                </li>
              </ul>
            </div>

            <div className="col-lg-5 mb-4">
              <div
                className="d-flex align-items-center"
                style={{ textAlign: "left", fontSize: "11px" }}
              >
                <span className="me-2">Filter - </span>
                <div class="form-group me-2">
                  <select
                    class="form-control"
                    name="class_filter"
                    id=""
                    onChange={(e) => {
                      set_class_filter_id(e.target.value);
                    }}
                  >
                    <option value={null}>Class Filter</option>
                    {class_data &&
                      class_data.map((c, i) => {
                        return (
                          <option key={i} value={c._id}>
                            {c.c_name}
                          </option>
                        );
                      })}
                  </select>
                </div>
              </div>

              <div className="table-responsive text-nowrap my-2">
                <table className="table table-hover">
                  <thead className="order_history_header">
                    <tr>
                      <th>S.No.</th>
                      <th>Test Name</th>
                      <th>Class</th>
                      <th>Type</th>
                      <th>Marks</th>
                      <th className="action hiddenOnPrint">Action</th>
                    </tr>
                  </thead>
                  <tbody className="table-border-bottom-0 order_history_body">
                    {class_data
                      .filter(
                        (c) => class_filter_id && c._id === class_filter_id
                      )
                      .map((c, i) => {
                        const test_data = c.test_data.filter((t) => {
                          return t.session === session.session_year;
                        });

                        // console.log("test data - ",test_data[0].tests);

                        return (
                          <>
                            {test_data.length > 0 &&
                              test_data[0].tests &&
                              test_data[0].tests.map((test, j) => {
                                sr_no += 1;
                                return (
                                  <>
                                    <tr>
                                      <td>{sr_no}</td>
                                      <td>{test.test_name}</td>
                                      <td>{c.c_name}</td>
                                      <td>{test.test_type}</td>
                                      <td>{test.total_marks}</td>
                                      <td className="action">
                                        {/* <span
                                          className="badge bg-warning me-1"
                                          data-toggle="tooltip"
                                          data-html="true"
                                          data-placement="top"
                                          title="Set Marks"
                                          style={{ cursor: "pointer" }}
                                          onClick={() => {
                                            setSelectedData(test);
                                          }}
                                        >
                                          <i className="fa fa-pencil"></i>
                                        </span> */}
                                        <span
                                          className="badge bg-primary me-1"
                                          data-toggle="tooltip"
                                          data-html="true"
                                          data-placement="top"
                                          title="Show Test Report"
                                          style={{ cursor: "pointer" }}
                                          onClick={() => {
                                            test.class_id = c;
                                            setSelectedData(test);
                                          }}
                                        >
                                          <i className="fa fa-eye"></i>
                                        </span>
                                        <span
                                          className="badge bg-secondary me-1"
                                          data-toggle="tooltip"
                                          data-html="true"
                                          data-placement="top"
                                          title="Delete Student"
                                          style={{ cursor: "pointer" }}
                                          onClick={async () => {}}
                                        >
                                          <i className="fa fa-edit"></i>
                                        </span>
                                        <span
                                          className="badge bg-danger"
                                          data-toggle="tooltip"
                                          data-html="true"
                                          data-placement="top"
                                          title="Delete Student"
                                          style={{ cursor: "pointer" }}
                                          onClick={async () => {}}
                                        >
                                          <i className="fa fa-trash"></i>
                                        </span>
                                      </td>
                                    </tr>
                                  </>
                                );
                              })}
                          </>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>

            <div className="col-lg-7 mb-4">
              <div className="card" style={{ textAlign: "left" }} ref={pdfRef}>
                <div className="card-header d-flex justify-content-between align-items-center">
                  <h5>Test Report</h5>
                  <ul
                    className="d-flex m-0 p-0"
                    style={{ fontSize: "12px!important" }}
                  >
                    <li className="me-2">
                      Test Name - {selectedData && selectedData.test_name}
                    </li>
                    <li className="me-2">
                      Test Type - {selectedData && selectedData.test_type}
                    </li>
                    <li className="me-2">
                      Total Marks - {selectedData && selectedData.total_marks}
                    </li>
                  </ul>
                  <span
                    className="badge bg-primary me-1 hiddenOnPrint"
                    data-toggle="tooltip"
                    data-html="true"
                    data-placement="top"
                    title="Print Form"
                    style={{ cursor: "pointer" }}
                    onClick={genereatePDF}
                  >
                    <i className="fa fa-print"></i>
                  </span>
                </div>
                <div className="card-body">
                  <div
                    
                    className="table-responsive text-nowrap mb-1 test_table"
                  >
                    <table className="table table-hover">
                      <thead className="order_history_header">
                        <tr>
                          <th>Sr No.</th>
                          <th>Test Name</th>
                          <th>Class</th>
                          <th>Sr No. - Student Name</th>
                          <th>Marks</th>
                          <th className="hiddenOnPrint">
                            New Marks (
                            {selectedData && selectedData.total_marks})
                          </th>

                          {/* <th className="action hiddenOnPrint">Action</th> */}
                        </tr>
                      </thead>
                      <tbody className="table-border-bottom-0 order_history_body">
                        {selectedData &&
                          test_student.map((stu, i) => {
                            return (
                              <tr>
                                <td>{i + 1}</td>
                                <td>{selectedData.test_name}</td>
                                <td>{selectedData.class_id.c_name}</td>
                                <td>{stu.student_id.sr_no+" - "+ stu.student_id.fields[0].value}</td>
                                <td>{stu.marks}</td>
                                <td className="hiddenOnPrint">
                                  <div class="form-group">
                                    <input
                                      type="number"
                                      class="form-control"
                                      name=""
                                      id=""
                                      ref={input_ref}
                                      aria-describedby="helpId"
                                      placeholder={`Enter marks ${stu.marks}`}
                                      // defaultValue={stu.marks}
                                      max={
                                        selectedData &&
                                        parseFloat(selectedData.total_marks)
                                      }
                                      onChange={(e) => {
                                        onChageInTestMarks(e, stu);
                                      }}
                                    />
                                  </div>

                                  {/* {stu.marks} */}
                                </td>
                                {/* <td className="action hiddenOnPrint">
                                  <span
                                    className="badge bg-primary me-1"
                                    data-toggle="tooltip"
                                    data-html="true"
                                    data-placement="top"
                                    title="Print Form"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {}}
                                  >
                                    <i className="fa fa-edit"></i>
                                  </span>
                                  <span
                                    className="badge bg-danger"
                                    data-toggle="tooltip"
                                    data-html="true"
                                    data-placement="top"
                                    title="Print Form"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {}}
                                  >
                                    <i className="fa fa-trash"></i>
                                  </span>
                                </td> */}
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div
                  className="card-footer hiddenOnPrint"
                  style={{ textAlign: "right" }}
                >
                  <button
                    className="btn btn-primary"
                    onClick={() => {
                      updateTestMarks();
                    }}
                  >
                    Update
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AllTest;
