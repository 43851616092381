// DropContainer.js
import React, {useState} from 'react';
import { useDrop } from 'react-dnd';

const DropContainer = ({ onDrop, children }) => {
    const [droppedItems, setDroppedItems] = useState([]);


  const [{ isOver }, drop] = useDrop({
    accept: 'ITEM',
    drop: (item,monitor) => {
      

      const delta = monitor.getDifferenceFromInitialOffset();
      const newPosition = {
        x: Math.round( delta.x),
        y: Math.round( delta.y),
      };

    //   const updatedItem = { ...item, style: { backgroundColor: 'lightgreen', border:"1px solid red"} };
      onDrop(item);
      setDroppedItems([...droppedItems, item]);

      
    //   onMove(id, newPosition);

    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  });

  return (
    <div ref={drop} style={{ backgroundColor: isOver ? 'lightgreen' : 'transparent', position: "absolute", width:"54mm", height:"84mm",top:0 }}>
      {children}
      
    </div>
  );
};

export default DropContainer;
