import React from 'react'
import "./team.css"
const Team = () => {
  return (
    <>
      <div className="container team_section mt-5">
        <div className="row">
          <div className="team_heading py-5">Our Team Members</div>
          <div className="col-md-3 col-10 mx-auto mt-3 mt-md-0">
            <div className="card " style={{ backgroundColor: "#F9F9F9" }}>
              <img
                src="./assets/images/avtar.png"
                alt=""
                className="card_image"
              />

              <div class="card-body">
                <h5 class="card-title title">Technical Head</h5>
                <div class="social_links d-flex">
                  <div className="facebook me-3">
                    <i class="fa-brands fa-facebook"></i>
                  </div>
                  <div className="facebook me-3">
                    <i class="fa-brands fa-facebook"></i>
                  </div>
                  <div className="twitter me-3">
                    <i class="fa-brands fa-twitter"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-3 col-10 mx-auto mt-3 mt-md-0">
            <div className="card" style={{ backgroundColor: "#F9F9F9" }}>
              <img
                src="./assets/images/avtar.png"
                alt=""
                className="card_image"
              />
              <div className="card-body">
                <h5 class="card-title title">Technical Assistant</h5>
                <div class="social_links d-flex">
                  <div className="facebook me-3">
                    <i class="fa-brands fa-facebook"></i>
                  </div>
                  <div className="facebook me-3">
                    <i class="fa-brands fa-facebook"></i>
                  </div>
                  <div className="twitter me-3">
                    <i class="fa-brands fa-twitter"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-3 col-10 mx-auto mt-3 mt-md-0">
            <div className="card" style={{ backgroundColor: "#F9F9F9" }}>
              <img
                src="./assets/images/avtar.png"
                alt=""
                className="card_image"
              />
              <div className="card-body">
                <h5 class="card-title title">Sale Executive</h5>
                <div class="social_links d-flex">
                  <div className="facebook me-3">
                    <i class="fa-brands fa-facebook"></i>
                  </div>
                  <div className="facebook me-3">
                    <i class="fa-brands fa-facebook"></i>
                  </div>
                  <div className="twitter me-3">
                    <i class="fa-brands fa-twitter"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-3 col-10 mx-auto mt-3 mt-md-0">
            <div className="card" style={{ backgroundColor: "#F9F9F9" }}>
              <img
                src="./assets/images/avtar.png"
                alt=""
                className="card_image"
              />
              <div className="card-body">
                <h5 class="card-title title">Support Manager</h5>
                <div class="social_links d-flex">
                  <div className="facebook me-3">
                    <i class="fa-brands fa-facebook"></i>
                  </div>
                  <div className="facebook me-3">
                    <i class="fa-brands fa-facebook"></i>
                  </div>
                  <div className="twitter me-3">
                    <i class="fa-brands fa-twitter"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Team
