import React from "react";

const Ledger = () => {
  return (
    <>
      <div className="content-wrapper payments">
        <div className="container-fluid flex-grow-1 container-p-y">
          <div className="row">Coming Soon</div>
        </div>
      </div>
    </>
  );
};

export default Ledger;
