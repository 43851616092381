import React, { useState } from "react";
import { ClassCon } from "../AllContext";

export const ClassState = (props) => {
  const host = process.env.REACT_APP_URL;
  const token = localStorage.getItem("token");
  const [clasData, setclasData] = useState([]);
  const b_data = JSON.parse(localStorage.getItem("selected_branch"));

  const addClass = async (data) => {
    data.b_id = b_data._id;
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "auth-token": token,
      },
      body: JSON.stringify(data),
    };
    const response = await fetch(`${host}/api/class/addClass`, requestOptions);
    const datares = await response.json();

    console.log(datares);
    if (datares.status === "Success") {
      setclasData(clasData.concat(datares.data));
    }

    return datares;
  };

  const getClass = async () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "auth-token": token,
      },
    };
    const response = await fetch(
      `${host}/api/class/getClass/${b_data._id}`,
      requestOptions
    );
    const datares = await response.json();

    console.log(datares);
    if (datares.status === "Success") {
      setclasData(datares.data);
    }

    return datares;
  };

  const deleteClass = async (id) => {
    const requestOptions = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "auth-token": token,
      },
    };
    const response = await fetch(
      `${host}/api/class/deleteClass/${id}`,
      requestOptions
    );
    const datares = await response.json();

    console.log(datares);
    if (datares.status === "Success") {
      const data = clasData.filter((d) => {
        return d._id != id;
      });
      setclasData(data);
    }

    return datares;
  };

  const updateClass = async (id, data) => {
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "auth-token": token,
      },
      body: JSON.stringify(data),
    };
    const response = await fetch(
      `${host}/api/class/updateClass/${id}`,
      requestOptions
    );
    const datares = await response.json();

    console.log(datares);
    if (datares.status === "Success") {
      const data = clasData.map((d) => {
        return d._id === id ? datares.data : d;
      });
      setclasData(data);
    }

    return datares;
  };
  return (
    <ClassCon.Provider
      value={{
        clasData,
        addClass,
        getClass,
        deleteClass,
        updateClass,
      }}
    >
      {props.children}
    </ClassCon.Provider>
  );
};
export default ClassState;
