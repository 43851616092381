import { BRANCH_ACTION } from "./constant";

export const fetchData = () => {
  return {
    type: BRANCH_ACTION.fetch,
  };
};

export const branchUploadSuccess = (res, type) => {
  console.log("branch upload action called success");
  return {
    type: BRANCH_ACTION.success,
    payload: res,
    u_type: type,
  };
};

export const branchUploadFailure = () => {
  console.log("branch upload action called failed");
  return {
    type: BRANCH_ACTION.failed,
  }
};

// export default fetchData;
