import React, { useState } from "react";
import { SubjectCon } from "../AllContext";

export const SubjectState = (props) => {
    const host = process.env.REACT_APP_URL;
    const token = localStorage.getItem("token");
  const [subjects, setSubjects] = useState([]);

  const getSubjects = async () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "auth-token": token,
      },
    };
    const response = await fetch(`${host}/api/subject/getSub`, requestOptions);
    const datares = await response.json();

    console.log(datares);
    if (datares.status === "Success") {
      setSubjects(datares.data);
    }

    return datares;
  };

  const addSubjects = async (data) => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "auth-token": token,
      },
      body: JSON.stringify(data),
    };
    const response = await fetch(`${host}/api/subject/addSub`, requestOptions);
    const datares = await response.json();

    console.log(datares);
    if (datares.status === "Success") {
      setSubjects(subjects.concat(datares.data));
    }

    return datares;
  };

  const deleteSubjects = async (id) => {
    const requestOptions = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "auth-token": token,
      },
    };
    const response = await fetch(
      `${host}/api/subject/deleteSub/${id}`,
      requestOptions
    );
    const datares = await response.json();

    console.log(datares);
    if (datares.status === "Success") {
      setSubjects(
        subjects.filter((sub) => {
          return sub._id != id;
        })
      );
    }

    return datares;
  };

  return (
    <SubjectCon.Provider
      value={{
        subjects,
        getSubjects,
        addSubjects,
        deleteSubjects,
      }}
    >
      {props.children}
    </SubjectCon.Provider>
  );
};

export default SubjectState;
