import React, { useEffect, useState } from "react";
import "./subs.css";
const SubscriptionPage = () => {


  const [rzp1, setRzp1] = useState(null)

  useEffect(() => {
    const script = document.createElement('script')
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.async = true;
    document.body.appendChild(script);

    script.onload = () => {
      const options = {
        key: "rzp_live_20qqgcXkcf2taV",
        amount: "500",
        currency: "INR",
        name: "IVIDHAN",
        description: "Test",
        image: "",
        order_id: "order_9A33XWu170gUtm",
        callback_url:
          "https://webhook.site/6a276aa7-8489-4739-8c7d-daae2648adf7",
        prefill: {
          name: "Gaurav Kumar",
          email: "gaurav.kumar@gmail.com",
          contact: "6396617714",
        },
        notes: {
          address: "Razorpay Corporate Office",
        },
        theme: {
          color: "#3399cc",
        },
      };
      const rzpInstance = new window.Razorpay(options);
      setRzp1(rzpInstance);
    };

    return () => {
      if (script.parentNode) {
        script.parentNode.removeChild(script);
      }
    };


  }, []);

  const handleClick = (e)=>{
    if(rzp1){
      rzp1.open();
    }
  }

  return (
    <>
      <h2 className="plansHeading mt-4">Select Your Plan</h2>
      <div className="container">
        <div className="row">
          <div className="col-10 mx-auto">
            <ul className="subs_ul mt-3 ">
              <li className="subs_li">
                <div className="card card_con" style={{ margin: "12px" }}>
                  <div className="card-body card_item">
                    <h4 className="titlePrice">Basic </h4>
                    <h5 className="pricediv">
                      <span className="ruppee">₹</span> 899.00{" "}
                      <span className="monthdiv">/month</span>
                    </h5>

                    <button className="getbtn" onClick={handleClick}>Get Started</button>
                    <p className="features mt-3">Top Features</p>
                    <ul className="features_con">
                      <li className="features_list">
                        {" "}
                        <a href="/">Multiple Branch Manage</a>{" "}
                      </li>
                      <li className="features_list">
                        {" "}
                        <a href="/"> Class/Subject Configration</a>
                      </li>
                      <li className="features_list">
                        {" "}
                        <a href="/"> Dynamic Student Registration Form</a>
                      </li>
                      <li className="features_list">
                        {" "}
                        <a href="/"> ID Card/ Marksheet Manage</a>
                      </li>
                      <li className="features_list">
                        {" "}
                        <a href="/"> Fees Management</a>
                      </li>
                      <li className="features_list">
                        {" "}
                        <a href="/"> Exam Management</a>
                      </li>
                      <li className="features_list">
                        {" "}
                        <a href="/"> Staff Management</a>
                      </li>
                      <li className="features_list">
                        {" "}
                        <a href="/"> 24X7 Support</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </li>
              <li className="subs_li">
                <div className="card card_con" style={{ margin: "12px" }}>
                  <div className="card-body most_popular">

                    <h4 className="titlePrice">Intermediate</h4>

                    <div className="mrpDi d-flex mx-auto justify-content-center  my-3">
                      <s className="strike me-2">₹9,999.00</s>
                      <br />
                      <span className="badge bg-saveFill">Save 75% </span>
                    </div>

                    <h5 className="pricediv">
                      <span>₹</span> 5,999.00{" "}
                      <span className="monthdiv">/year</span>
                      <br />
                    </h5>
                    <button className="getbtn">Get Started</button>
                    <p className="features mt-3">Top Features</p>
                    <ul className="features_con">
                      <li className="features_list">
                        {" "}
                        <a href="/">Multiple Branch Manage</a>{" "}
                      </li>
                      <li className="features_list">
                        {" "}
                        <a href="/"> Class/Subject Configration</a>
                      </li>
                      <li className="features_list">
                        {" "}
                        <a href="/"> Dynamic Student Registration Form</a>
                      </li>
                      <li className="features_list">
                        {" "}
                        <a href="/"> ID Card/ Marksheet Manage</a>
                      </li>
                      <li className="features_list">
                        {" "}
                        <a href="/"> Fees Management</a>
                      </li>
                      <li className="features_list">
                        {" "}
                        <a href="/"> Exam Management</a>
                      </li>
                      <li className="features_list">
                        {" "}
                        <a href="/"> Staff Management</a>
                      </li>
                      <li className="features_list">
                        {" "}
                        <a href="/"> 24X7 Support</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </li>

              <li className="subs_li">
                <div className="card card_con" style={{ margin: "12px" }}>
                  <div className="card-body">
                    <h4 className="titlePrice">Advance</h4>

                    <div className="mrpDi d-flex mx-auto justify-content-center  my-3">
                      <s className="strike me-2">₹49,999.00</s>
                      <br />
                      <span className="badge bg-saveFill">Save 75% </span>
                    </div>

                    <h5 className="pricediv">
                      <span>₹</span> 26,999.00{" "}
                      <span className="monthdiv">5 year</span>
                      <br />
                    </h5>
                    <button className="getbtn">Get Started</button>
                    <p className="features mt-3">Top Features</p>
                    <ul className="features_con">
                      <li className="features_list">
                        {" "}
                        <a href="/">Multiple Branch Manage</a>{" "}
                      </li>
                      <li className="features_list">
                        {" "}
                        <a href="/"> Class/Subject Configration</a>
                      </li>
                      <li className="features_list">
                        {" "}
                        <a href="/"> Dynamic Student Registration Form</a>
                      </li>
                      <li className="features_list">
                        {" "}
                        <a href="/"> ID Card/ Marksheet Manage</a>
                      </li>
                      <li className="features_list">
                        {" "}
                        <a href="/"> Fees Management</a>
                      </li>
                      <li className="features_list">
                        {" "}
                        <a href="/"> Exam Management</a>
                      </li>
                      <li className="features_list">
                        {" "}
                        <a href="/"> Staff Management</a>
                      </li>
                      <li className="features_list">
                        {" "}
                        <a href="/"> 24X7 Support</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default SubscriptionPage;
