import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

export const S_Form = (props) => {

    const [query, setQuery] = useState("")
    const history = useNavigate()

  return (
    <>
      <div className="content-wrapper payments">
        <div className="container-xxl flex-grow-1 container-p-y">
          <div className="row">
            <div className="col-lg-12 mb-4 ">
              <div className="row">
                <div className="col-lg-6 font-bold">
                  <ul className="slider_button slider-white plain-orange">
                    <li
                      className="slider-active me-2"
                      onClick={() => {
                        history("/admin/add_student_forms");
                      }}
                    >
                      + Add Form
                    </li>
                    
                    
                  </ul>
                </div>
                <div className="col-lg-6 text-right">
                  <input
                    type="text"
                    className="form-control search"
                    placeholder="Search form..."
                    onChange={(e) => {
                      setQuery(e.target.value);
                    }}
                  />
                </div>
              </div>

              <div className="row mt-4" style={{ textAlign: "left" }}>
                
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
