import logo from "./logo.svg";
import "./App.css";
import { BrowserRouter, Link, Outlet, Route, Routes } from "react-router-dom";
import Login from "./authLayout/Login";
import Register from "./authLayout/Register";
import AdminLayout from "./adminLayout/AdminLayout";
import { useContext, useEffect, useState } from "react";
import Branch from "./adminLayout/pages/branch/Branch";
import AddBranch from "./adminLayout/pages/branch/AddBranch";
import BranchState from "./adminLayout/context/Branch/BranchState";
import { Class_Subject } from "./adminLayout/pages/class_subject/Class_Subject";
import Feedback from "./adminLayout/pages/Feedback";
import ID_card from "./adminLayout/pages/id_card/ID_card";
import ClassState from "./adminLayout/context/Class/ClassState";
import SubjectState from "./adminLayout/context/Class/SubjectState";
import StudentFormState from "./adminLayout/context/StudentForm/StudentFormState";
import { S_Form } from "./adminLayout/pages/studentData/S_Form";
import { Add_S_From } from "./adminLayout/pages/studentData/Add_S_From";
import CreateStudent from "./adminLayout/pages/studentData/CreateStudent";
import Alert from "./adminLayout/componant/Alert";
import AllStudents from "./adminLayout/pages/studentData/AllStudents";
import StudentState from "./adminLayout/context/Students/StudentState";
import Teachers from "./adminLayout/pages/teachers/Teachers";
import Preloader from "./adminLayout/componant/Preloader";
import Configure from "./adminLayout/pages/id_card/Configure";
import FeesPage from "./adminLayout/pages/fees/FeesPage";
import { useDispatch, useSelector } from "react-redux";
import StudentMarksheet from "./adminLayout/pages/headerConfig/HeaderConfig";
import Exam from "./adminLayout/pages/studentData/exam/Exam";
import IdConfigure from "./adminLayout/pages/id_card/IdConfigure";
import IdCardPreview from "./adminLayout/pages/id_card/IdCardPreview";
import HeaderConfig from "./adminLayout/pages/headerConfig/HeaderConfig";
import CaroS from "./adminLayout/componant/SliderCaro/CaroS";
import Home from "./website/Pages/Home/Home";
import SubscriptionPage from "./adminLayout/pages/subscription/SubscriptionPage";
import WebsiteLayout from "./website/WebsiteLayout";
import About from "./website/Pages/Home/About";
import Contact from "./website/Pages/Home/Contact";
import PrivacyPolicy from "./website/Pages/Home/PrivacyPolicy";
import Terms_Condition from "./website/Pages/Home/Terms_Condition";
import Checkout from "./website/components/Checkout/Checkout";
import ContentWrapper from "./adminLayout/section/contentWraper";
import Profile from "./adminLayout/pages/profile/Profile";
import Entry from "./adminLayout/pages/account/Entry";
import Ledger from "./adminLayout/pages/account/Ledger";
import PrintTemp from "./adminLayout/pages/print_data/PrintTemp";
import AllTest from "./adminLayout/pages/test/AllTest";
import TestReports from "./adminLayout/pages/test/TestReports";
import AddTest from "./adminLayout/pages/test/AddTest";
import HostelSetup from "./adminLayout/pages/hostel/HostelSetup";
import { showMyAlert } from "./adminLayout/redux/action";
import LiveClasses from "./adminLayout/pages/liveClasses/LiveClasses";
import Print_Fees_reciept from "./adminLayout/pages/print_data/Print_Fees_reciept";

function App() {
  // reducre call

  const myAlert = useSelector((state) => state.showAlert);
  const loader = useSelector((state) => state.loader);
  const dispatch = useDispatch();
  // reducer call

  const showAlert = (message, type) => {
    dispatch(showMyAlert(message, type));
  };

  return (
    <>
      <StudentState>
        <StudentFormState>
          <SubjectState>
            <ClassState>
              <BranchState>
                <div className="App">
                  <Alert alert={myAlert} />
                  <Preloader show={loader} />
                  <BrowserRouter>
                    <Routes>
                      <Route path="/auth" element={<Outlet />}>
                        <Route
                          index
                          element={<Login showAlert={showAlert} />}
                        />
                        <Route
                          path="register"
                          element={<Register showAlert={showAlert} />}
                        />

                        <Route
                          path="upgrade"
                          element={<SubscriptionPage showAlert={showAlert} />}
                        />
                        <Route
                          path="checkout/:id"
                          element={<Checkout showAlert={showAlert} />}
                        />

                        <Route
                          path=":path"
                          element={<Login showAlert={showAlert} />}
                        />
                      </Route>

                      <Route path="/admin" element={<AdminLayout />}>
                        {/* <Route
                          path="slider"
                          element={<CaroS />}
                        /> */}
                        <Route path="live" element={<LiveClasses />} />

                        <Route path="ledger" element={<Ledger />} />
                        <Route path="entry" element={<Entry />} />

                        <Route path="profile" element={<Profile />} />

                        <Route index element={<ContentWrapper />} />
                        <Route path="branch" element={<Branch />} />
                        <Route path="addNewBranch" element={<AddBranch />} />

                        <Route
                          path="edit_branch/:id"
                          element={<AddBranch showAlert={showAlert} />}
                        />

                        <Route
                          path="subjects"
                          element={<Class_Subject showAlert={showAlert} />}
                        />
                        <Route
                          path="feedback-form"
                          element={<Feedback showAlert={showAlert} />}
                        />

                        <Route
                          path="id_marksheet"
                          element={<ID_card showAlert={showAlert} />}
                        />

                        <Route
                          path="marksheet_configure"
                          element={<Configure showAlert={showAlert} />}
                        />

                        <Route
                          path="id_card_configration"
                          element={<IdConfigure showAlert={showAlert} />}
                        />

                        <Route
                          path="student_marksheet"
                          element={
                            <HeaderConfig edit={true} showAlert={showAlert} />
                          }
                        />

                        <Route path="exam_entry" element={<Exam />} />

                        <Route
                          path="fees_data"
                          element={<FeesPage showAlert={showAlert} />}
                        />
                        <Route
                          path="student_forms"
                          element={<S_Form showAlert={showAlert} />}
                        />

                        <Route
                          path="add_student_forms"
                          element={<Add_S_From showAlert={showAlert} />}
                        />

                        <Route
                          path="create_student"
                          element={
                            <CreateStudent edit={false} showAlert={showAlert} />
                          }
                        />

                        <Route path="tests" element={<AllTest />} />

                        {/* <Route path="tests/report" element={<TestReports />} /> */}

                        <Route path="tests/addTest" element={<AddTest />} />

                        <Route
                          path="all_students"
                          element={<AllStudents showAlert={showAlert} />}
                        />

                        <Route
                          path="all_students/print_form"
                          element={<PrintTemp />}
                        />

                        <Route
                          path="fees_data/print"
                          element={<Print_Fees_reciept />}
                        />

                        <Route
                          path="student/:id"
                          element={
                            <CreateStudent edit={true} showAlert={showAlert} />
                          }
                        />

                        <Route
                          path="staff"
                          element={
                            <Teachers
                              showAlert={showAlert}
                              preloader={loader}
                            />
                          }
                        />

                        {/* teachers */}

                        {/* hostel */}
                        <Route path="hostel/setup" element={<HostelSetup />} />
                      </Route>

                      <Route path="/" element={<WebsiteLayout />}>
                        <Route index element={<Home showAlert={showAlert} />} />
                        <Route
                          path="/about-us"
                          element={<About showAlert={showAlert} />}
                        />

                        <Route
                          path="/contact-us"
                          element={<Contact showAlert={showAlert} />}
                        />

                        <Route
                          path="/terms-conditions"
                          element={<Terms_Condition showAlert={showAlert} />}
                        />

                        <Route
                          path="/privacy-policy"
                          element={<PrivacyPolicy showAlert={showAlert} />}
                        />
                      </Route>
                    </Routes>
                  </BrowserRouter>
                </div>
              </BranchState>
            </ClassState>
          </SubjectState>
        </StudentFormState>
      </StudentState>
    </>
  );
}

export default App;
