import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addExamData,
  getStudentData,
} from "../../../redux/reducer/studentReducer";
import { SketchPicker } from "react-color";

const Exam = () => {
  const myState = useSelector((state) => state.getStudentData);
  const b_data = useSelector((state) => state.fetchDataFromLocal);
  const c_session = useSelector((state) => state.currentSession);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getStudentData({ b_id: b_data._id }));
  }, []);

  const [selecteStudent, setSelectStudent] = useState(null);

  const [old_exam_entry, set_old_exan_entry] = useState([]);

  const onStudentSelecte = (e) => {
    const data = JSON.parse(e.target.value);
    setSelectStudent(data);

    // console.log(data);

    const exam_data = data.exam_entry.filter(exam=>exam.class_id === data.class_id._id)
    

    if(exam_data.length > 0){
      set_old_exan_entry(exam_data[0].entry)
      set_exam_fill(exam_data[0].entry)
    }
    
    // session = b_data.sessions.filter((branch) => {
    //   return branch.current;
    // });

    // const f_data = data.fees_entry.filter((fees, index) => {
    //   return fees.session === session[0].session_year;
    // })[0];

    // set_fees_entry(f_data);
  };

  const [exam_fill, set_exam_fill] = useState([]);

  const setDataInput = (e, exam, sub) => {
    let data = exam_fill;
    if (exam_fill.some((exam) => exam.sub_id === sub._id)) {
      console.log("some");
      data = exam_fill.map((exam) => {
        return exam.sub_id === sub._id
          ? { ...exam, [e.target.name]: e.target.value }
          : exam;
      });
      console.log(data);
    } else {
      console.log("not some");
      data.push({ sub_id: sub._id, sub, [e.target.name]: e.target.value });
    }

    set_exam_fill(data);
  };

  const saveData = (e) => {
    e.preventDefault();

    dispatch(
      addExamData(
        {
          session: c_session["session_year"],
          class_id: selecteStudent.class_id._id,
          entry: exam_fill,
        },
        { s_id: selecteStudent._id }
      )
    );
  };





  return (
    <>
      <div className="content-wrapper payments">
        <div className="container-xxl flex-grow-1 container-p-y">
          <div className="row">
            <div className="col-lg-12 mb-4 ">
              <div className="row">
                <div className="col-lg-4 font-bold">
                  <div className="form-group">
                    <select
                      className="form-control"
                      name="student_data"
                      id=""
                      onChange={onStudentSelecte}
                    >
                      <option>Choose Sr.No.</option>
                      {myState.length > 0 &&
                        myState.map((student, index) => {
                          return (
                            <option key={index} value={JSON.stringify(student)}>
                              {student.sr_no} - {}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                </div>

                <div className="col-12 mt-2">
                  <div className="card">
                    <h6 className="card-header text-left">Add Exam Marks</h6>
                    <div className="card-body">
                      <table className="table tabl-hover" width={"100%"}>
                        <thead>
                          <tr>
                            <th className="text-left">Subjects</th>
                            {selecteStudent &&
                              selecteStudent.class_id.exam_data.map((e, i) => {
                                return <th key={i}>{e.e_label}</th>;
                              })}
                          </tr>
                        </thead>
                        <tbody>
                          {selecteStudent &&
                            selecteStudent.class_id.subjects.map(
                              (subject, index) => {
                                const match_data = old_exam_entry.filter((e)=>{
                                  return e.sub_id === subject._id
                                })

                                
                                return (
                                  <tr key={index}>
                                    <th className="text-left">
                                      {subject.sub_name}
                                    </th>
                                    {selecteStudent.class_id.exam_data.map(
                                      (e, i) => {

                                        

                                        return (
                                          <td key={i}>
                                            <div className="form-group">
                                              <input
                                                type="text"
                                                className="form-control"
                                                name={e.e_label}
                                                id=""
                                                aria-describedby="helpId"
                                                placeholder={e.e_total}
                                                onChange={(event) => {
                                                  setDataInput(
                                                    event,
                                                    e,
                                                    subject
                                                  );
                                                }}
                                                defaultValue={match_data.length > 0 ? match_data[0][e.e_label] : ""}
                                              />
                                            </div>
                                          </td>
                                        );
                                      }
                                    )}
                                  </tr>
                                );
                              }
                            )}
                        </tbody>
                      </table>
                    </div>
                    <div className="card-footer text-right">
                      <button className="btn btn-primary" onClick={saveData}>
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Exam;
