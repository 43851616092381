import React from "react";
import { useSelector } from "react-redux";

import { Link, NavLink, useLocation } from "react-router-dom";

export default function NavbarData() {
  let location = useLocation();

  

  const toogleMenu = useSelector((state) => state.toogleMenuReducer);
  const set_login_user_data = useSelector((state) => state.set_login_user_data);

  

  return (
    <>
      <aside
        id="layout-menu"
        className={`layout-menu menu-vertical menu bg-menu-theme ${
          toogleMenu ? "showMenu" : ""
        }`}
      >
        <div className="app-brand demo">
          <Link to="/admin" className="app-brand-link">
            <span className="app-brand-logo demo">
              {/* <img src={logo} alt="logo" width="170" /> */}
            </span>
            <span className="app-brand-text demo menu-text fw-bolder ms-2 text-center">
              iVidhan
            </span>
          </Link>

          <Link
            to="/"
            className="layout-menu-toggle menu-link text-large ms-auto d-block d-xl-none"
          >
            <i className="bx bx-chevron-left bx-sm align-middle"></i>
          </Link>
        </div>

        <div className="menu-inner-shadow"></div>

        <ul className="menu-inner py-1">
          <NavLink
            to="/admin"
            className={`menu-item ${
              location.pathname === "/admin" ? "yes_active" : "no_active"
            }`}
          >
            <li className="menu-link">
              <i className="menu-icon tf-icons bx bx-home-circle"></i>
              <div data-i18n="Analytics">Dashboard</div>
            </li>
          </NavLink>

          <NavLink
            to="/admin/branch"
            className={`menu-item ${
              location.pathname === "/admin/branch" ? "yes_active" : "no_active"
            }`}
          >
            <li className="menu-link">
              <i className="menu-icon tf-icons bx bx-git-branch"></i>
              <div data-i18n="Analytics">Branch</div>
            </li>
          </NavLink>

          <NavLink
            
            to="/admin/all_students"
            disabled={true}
            className={`menu-item ${
              location.pathname === "/admin/all_students" ? "yes_active" : ""
            }`}
          >
            <li className="menu-link">
              <i className="menu-icon tf-icons bx bx-user"></i>
              <div data-i18n="blog-category">All Students</div>
            </li>
          </NavLink>
          <NavLink
            to="/admin/staff"
            className={`menu-item ${
              location.pathname === "/admin/staff" ? "yes_active" : ""
            }`}
          >
            <li className="menu-link">
              <i className="menu-icon tf-icons bx bx-user-pin"></i>
              <div data-i18n="blog-category">Staff</div>
            </li>
          </NavLink>

          <NavLink
            to="/admin/fees_data"
            className={`menu-item ${
              location.pathname === "/admin/fees_data" ? "yes_active" : ""
            }`}
          >
            <li className="menu-link">
              <i className="menu-icon tf-icons bx bx-money"></i>
              <div data-i18n="blog-category">Fees</div>
            </li>
          </NavLink>

          <NavLink
            to="/admin/exam_entry"
            className={`menu-item ${
              location.pathname === "/admin/exam_entry" ? "yes_active" : ""
            }`}
          >
            <li className="menu-link">
              <i className="menu-icon tf-icons bx bx-money"></i>
              <div data-i18n="blog-category">Exam</div>
            </li>
          </NavLink>

          <li className="menu-header small text-uppercase">
            <span className="menu-header-text">Reports</span>
          </li>

          <NavLink
            to="/admin/reportsdata"
            className={`menu-item ${
              location.pathname === "/admin/reportsdata" ? "yes_active" : ""
            }`}
          >
            <li className="menu-link">
              <i className="menu-icon tf-icons bx bx-file"></i>
              <div data-i18n="blog-category">Student Reports</div>
            </li>
          </NavLink>

          <NavLink
            to="/admin/reportsdata"
            className={`menu-item ${
              location.pathname === "/admin/reportsdata" ? "yes_active" : ""
            }`}
          >
            <li className="menu-link">
              <i className="menu-icon tf-icons bx bx-error"></i>
              <div data-i18n="blog-category">Complaints</div>
            </li>
          </NavLink>

          <NavLink
            to="/admin/reportsdata"
            className={`menu-item ${
              location.pathname === "/admin/reportsdata" ? "yes_active" : ""
            }`}
          >
            <li className="menu-link">
              <i className="menu-icon tf-icons bx bx-question-mark"></i>
              <div data-i18n="blog-category">Enquiry</div>
            </li>
          </NavLink>

          <NavLink
            to="/admin/feedback-form"
            className={`menu-item ${
              location.pathname === "/admin/feedback-form" ? "yes_active" : ""
            }`}
          >
            <li className="menu-link">
              <i className="menu-icon tf-icons bx bx-box"></i>
              <div data-i18n="blog-category">Feedback Form</div>
            </li>
          </NavLink>

          <li className="menu-header small text-uppercase">
            <span className="menu-header-text">Testbook</span>
          </li>

          <NavLink
            to="/admin/tests"
            className={`menu-item ${
              location.pathname === "/admin/tests" ? "yes_active" : ""
            }`}
          >
            <li className="menu-link">
              <i className="menu-icon tf-icons bx bx-book-content"></i>

              <div data-i18n="blog-category">Test Book</div>
            </li>
          </NavLink>
          {/* <NavLink
            to="/admin/tests/report"
            className={`menu-item ${
              location.pathname === "/admin/tests/report" ? "yes_active" : ""
            }`}
          >
            <li className="menu-link">
              <i className="menu-icon tf-icons bx bx-file"></i>

              <div data-i18n="blog-category">Reports</div>
            </li>
          </NavLink> */}

          <li className="menu-header small text-uppercase">
            <span className="menu-header-text">Account</span>
          </li>

          <NavLink
            to="/admin/ledger"
            className={`menu-item ${
              location.pathname === "/admin/ledger" ? "yes_active" : ""
            }`}
          >
            <li className="menu-link">
              <i className="menu-icon tf-icons bx bx-transfer-alt"></i>

              <div data-i18n="blog-category">Ledger</div>
            </li>
          </NavLink>

          <NavLink
            to="/admin/entry"
            className={`menu-item ${
              location.pathname === "/admin/entry" ? "yes_active" : ""
            }`}
          >
            <li className="menu-link">
              <i className="menu-icon tf-icons bx bx-edit-alt"></i>
              <div data-i18n="blog-category">Entry</div>
            </li>
          </NavLink>

          {/* {set_login_user_data.config &&
            set_login_user_data.config.hostel_module && (
              <>
                <li className="menu-header small text-uppercase">
                  <span className="menu-header-text">Hostel</span>
                </li>

                <NavLink
                  to="/admin/hostel/setup"
                  className={`menu-item ${
                    location.pathname === "/admin/hostel/setup"
                      ? "yes_active"
                      : ""
                  }`}
                >
                  <li className="menu-link">
                    <i className="menu-icon tf-icons bx bx-edit-alt"></i>
                    <div data-i18n="blog-category">Setup</div>
                  </li>
                </NavLink>
              </>
            )} */}

          <li className="menu-header small text-uppercase">
            <span className="menu-header-text"> Configration </span>
          </li>
          <NavLink
            to="/admin/subjects"
            className={`menu-item ${
              location.pathname === "/admin/subjects" ? "yes_active" : ""
            }`}
          >
            <li className="menu-link">
              <i className="menu-icon tf-icons bx bx-news"></i>
              <div data-i18n="blog-category">Class / Subjects</div>
            </li>
          </NavLink>

          <NavLink
            to="/admin/add_student_forms"
            className={`menu-item ${
              location.pathname === "/admin/add_student_forms"
                ? "yes_active"
                : ""
            }`}
          >
            <li className="menu-link">
              <i className="menu-icon tf-icons bx bx-news"></i>
              <div data-i18n="blog-category">Student Form</div>
            </li>
          </NavLink>

          <NavLink
            to="/admin/id_marksheet"
            className={`menu-item ${
              location.pathname === "/admin/id_marksheet" ? "yes_active" : ""
            }`}
          >
            <li className="menu-link">
              <i className="menu-icon tf-icons bx bx-id-card"></i>
              <div data-i18n="blog-category">Id Card / Marksheet</div>
            </li>
          </NavLink>

          <NavLink
            to="/admin/student_marksheet"
            className={`menu-item ${
              location.pathname === "/admin/student_marksheet"
                ? "yes_active"
                : ""
            }`}
          >
            <li className="menu-link">
              <i className="menu-icon tf-icons bx bx-card"></i>
              <div data-i18n="blog-category">Static Templates</div>
            </li>
          </NavLink>

          <li className="menu-header small text-uppercase">
            <span className="menu-header-text">Setting</span>
          </li>

          <NavLink
            to="https://vipsgrow.in/"
            target="_blank"
            className={`menu-item ${
              location.pathname === "/admin/state" ? "yes_active" : ""
            }`}
          >
            <li className="menu-link">
              <i className="menu-icon tf-icons bx bx-box"></i>
              <div data-i18n="blog-category">Support</div>
            </li>
          </NavLink>
        </ul>
      </aside>
      {/* <div className="layout-overlay layout-menu-toggle"></div> */}
    </>
  );
}
