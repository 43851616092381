import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import CreateTeacher from "./CreateTeacher";
import { useDispatch, useSelector } from "react-redux";

import { branchUploadFailure } from "../../redux/action";
import { addStaff } from "../../redux/reducer/branchReducer";

const Teachers = (props) => {
  const history = useNavigate();

  const dispatch = useDispatch();

  const [addModal, setAddModal] = useState(false);
  const openModal = () => {
    setAddModal(!addModal);
  };

  const myState = useSelector((state) => state.getStudentData);
  const b_data = useSelector((state) => state.fetchDataFromLocal);
  const c_session = useSelector((state) => state.currentSession);

  const [filter, setFilter] = useState("all")

  return (
    <>
      {addModal && (
        <CreateTeacher
          open={openModal}
          edit={false}
          obj={""}
          submit={(body) => {
            dispatch(
              addStaff(body, { b_id: b_data._id }, "add", () => {
                openModal();
              })
            );
            // dispatch(branchUploadFailure());
          }}
        />
      )}
      <div className="content-wrapper payments">
        <div className="container-fluid flex-grow-1 container-p-y">
          <div className="row">
            <div className="col-lg-12 mb-4 ">
              <div className="row">
                <div className="col-6 font-bold">
                  <ul className="slider_button slider-white plain-orange">
                    <li className={`me-2 ${filter === "all" ? 'slider-active':''}`} onClick={()=>{
                      setFilter("all")
                    }}>All</li>
                    <li
                      className={`me-2 ${filter === "teacher" ? 'slider-active':''}`}
                      onClick={() => {
                        setFilter("teacher")
                      }}
                    >
                      Teacher
                    </li>
                    <li
                      className={`me-2 ${filter === "accountant" ? 'slider-active':''}`}
                      onClick={() => {
                        setFilter("accountant")
                      }}
                    >
                      Accountant
                    </li>
                    <li
                      className={`me-2 ${filter === "recepsnist" ? 'slider-active':''}`}
                      onClick={() => {
                        setFilter("recepsnist")
                      }}
                    >
                      Recepsnist
                    </li>
                  </ul>
                </div>

                <div className="col-5">
                  <input
                    type="text"
                    className="form-control me-4"
                    placeholder="Search staff..."
                  />
                </div>
                <div className="col-1">
                  <button className="btn btn-primary" onClick={openModal}>
                    Add Staff
                  </button>
                </div>
              </div>

              <div className="row mt-4" style={{ textAlign: "left" }}>
                <div className="col-12 mx-auto">
                  <div className="table-responsive text-nowrap mb-1">
                    <table
                      className="table table-hover"
                      style={{ textAlign: "left" }}
                    >
                      <thead className="order_history_header">
                        <tr>
                          <th>Sr No.</th>
                          <th>Name</th>
                          <th>Phone</th>
                          <th>Password</th>
                          <th>Role</th>
                          <th>Last login</th>
                          <th>Create At</th>

                          <th className="action">Action</th>
                        </tr>
                      </thead>
                      <tbody className="table-border-bottom-0 order_history_body">
                        {b_data.staff &&
                          b_data.staff.filter((s,i)=>{
                            return filter === "all" ? s : s.role === filter
                          }).map((s, index) => {
                            return (
                              <tr>
                                <td>{index+1}</td>
                                <td>{s.name}</td>
                                <td>{s.phoneNo}</td>
                                <td>{s.string_pass}</td>
                                <td>{s.role}</td>
                                <td>{s.last_login}</td>
                                <td>{s.createAt}</td>
                                <td className="action">
                                  <span className="badge bg-success me-1">
                                    Approved
                                  </span>

                                  <span className="badge bg-warning me-1">
                                    <i className="fa fa-edit"></i>
                                  </span>

                                  <span
                                    className="badge bg-danger"
                                    onClick={() => {
                                      dispatch(
                                        addStaff(
                                          {},
                                          { b_id: b_data._id, staff_id: s._id },
                                          "delete"
                                        )
                                      );
                                    }}
                                  >
                                    <i className="fa fa-trash"></i>
                                  </span>
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Teachers;
