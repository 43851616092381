import { PRINT_DATA } from "../../action/constant";

const student_form = {};

const set_print_object = (state = student_form, action) => {
  switch (action.type) {
    case PRINT_DATA.setObject:
      state = action.payload;
      return state;
    default:
      return state;
  }
};
export default set_print_object;
