import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

function Register(props) {
  const navigation = useNavigate();
  const host = process.env.REACT_APP_URL;
  const [registerData, setRegisterData] = useState({
    phone: "",
    f_name: "",
    l_name: "",
    email: "",
    pass: "",
    con_pass: "",
  });

  const onRegisterChange = (e) => {
    setRegisterData({ ...registerData, [e.target.name]: e.target.value });
  };

  const onRegister = async (e) => {
    e.preventDefault();
    if (
      registerData.phone !== "" &&
      registerData.pass !== "" &&
      registerData.con_pass !== ""
    ) {
      if (registerData.pass === registerData.con_pass) {
        serverCall({
          fullname: registerData.f_name + " " + registerData.l_name,
          phone: registerData.phone,
          email: registerData.email,
          pass: registerData.pass,
        });
      } else {
        alert("Confirm password is not matched.");
      }
    } else {
      alert("Please enter valid details to register.");
      console.log(registerData.pass + " - " + registerData.con_pass);
    }
  };

  const serverCall = async (body) => {
    props.preLoader("preShow");
    const url = `${host}/api/user/signup`;
    console.log(url);

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // "auth-token": token,
      },
      body: JSON.stringify(body),
    };

    try {
      const res = await fetch(url, requestOptions);
      // console.log(res);
      const datares = await res.json();
      props.preLoader("");

      if (datares.status === "Success") {
        alert("Registeration has been successfull. Please login...");
        // localStorage.setItem("token", datares.token);
        // localStorage.setItem("admin_data", JSON.stringify(datares.data));
        navigation("/auth");
      } else {
        alert(datares.message);
      }
    } catch (error) {
      props.preLoader("");
    }
  };

  return (
    <>
      <div class="account-login section">
        <div class="container">
          <div class="row">
            <div class="col-lg-6 offset-lg-3 col-md-10 offset-md-1 col-12">
              <div class="register-form">
                <div class="title">
                  <h3>Create an account</h3>
                  <p>
                    Registration takes less than a minute but gives you full
                    control over your orders.
                  </p>
                </div>
                <form class="row" method="post" onSubmit={onRegister}>
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label for="reg-fn">First Name</label>
                      <input
                        class="form-control"
                        type="text"
                        id="reg-fn"
                        required
                        name="f_name"
                        onChange={onRegisterChange}
                      />
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label for="reg-ln">Last Name</label>
                      <input
                        class="form-control"
                        type="text"
                        id="reg-ln"
                        required
                        name="l_name"
                        onChange={onRegisterChange}
                      />
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label for="reg-email">E-mail Address</label>
                      <input
                        class="form-control"
                        type="email"
                        id="reg-email"
                        required
                        name="email"
                        onChange={onRegisterChange}
                      />
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label for="reg-phone">Phone Number</label>
                      <input
                        class="form-control"
                        type="text"
                        id="reg-phone"
                        required
                        name="phone"
                        onChange={onRegisterChange}
                      />
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label for="reg-pass">Password</label>
                      <input
                        class="form-control"
                        type="password"
                        id="reg-pass"
                        required
                        name="pass"
                        onChange={onRegisterChange}
                      />
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label for="reg-pass-confirm">Confirm Password</label>
                      <input
                        class="form-control"
                        type="password"
                        id="reg-pass-confirm"
                        required
                        name="con_pass"
                        onChange={onRegisterChange}
                      />
                    </div>
                  </div>
                  <div class="button">
                    <button class="btn" type="submit">
                      Register
                    </button>
                  </div>
                  <p class="outer-link">
                    Already have an account? <Link to="../">Login Now</Link>
                  </p>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Register;
