import React from "react";
import Hero from "../../components/Header/HeroSection/Hero";
import Featured from "../../components/FeaturedSection/Featured";
import Team from "../../components/Team/Team";
import How from "../../components/HowTo/How";
import Price from "../../components/Pricing/Price";
import Faq from "../../components/FAQ/Faq";
import Banner from "../../components/Footer/Banner";

const Home = () => {
  return (
    <>
      
      <Hero />
      <Featured />
      <Team />
      {/* <How /> */}
      <Price />
      <Banner />
      {/* <Faq/> */}
      {/* <Footer /> */}
    </>
  );
};

export default Home;
