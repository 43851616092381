export const BRANCH_ACTION = {
  fetch: "FETCHSELECTEDBRANCH",
  add: "ADD_BRANCH",
  delete: "DELETE_BRANCH",
  session: "SET_SESSION",
  selecteDefault: "SET_DEFAULT",
  success: "BRANCH_SUCCESS",
  failed: "BRANCH_FAILED",
};

export const CLASS_ACTION = {
  fetch: "FETCHSELECTEDCLASS",
  add: "ADD_CLASS",
  edit: "EDIT_CLASS",
  remove: "REMOVE_CLASS",
  success: "CLASS_SUCCESS",
  failed: "CLASS_FAILED",
}

export const STUDENT_FORM = {
  create_form:"FETCH_FORM_FOR_CREATE_STUDENT",
  success: "STUDENT_SUCCESS",
  failed: "STUDENT_FAILED",
}

export const ALERT_ACTION = {
  show: "SHOW_ALERT",
  hide: "HIDE_ALERT",
  show_loader:"SHOW_LOADER",
  hide_loader:"HIDE_LOADER"
};

export const API_CALL_STATUS = {
  success: "SUCCESS",
  failed: "FAILED",
  toogleMenu:"toogleMenu",
  get_user_data:"GET_USER_DATA",
  set_user_data:"SET_USER_DATA"
};
export const FEES_ENTRY = {
  add: "FEES_ADD",
  
};

export const PRINT_DATA = {
  setObject : "SET_OBJECT_OF_PRINT"
}