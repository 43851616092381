import { fetchDataFromLocal } from "./reducer/branchReducer";

const host = process.env.REACT_APP_URL;
const token = localStorage.getItem("token");

const isFormData = (body) => {
  return body instanceof FormData;
};

const serverCallFuction = async (
  method = "GET",
  endPoints = "",
  body = null
) => {
  try {
    
    let header = {};

    if (body) {
      if (!isFormData(body)) {
        header = {
          "auth-token": token,
          "Content-Type": "application/json",
        };
      }else{
        header = {
          "auth-token": token,
        }
      }
    }else{
      header = {
        "auth-token": token,
        "Content-Type": "application/json",
      }
    }

    const requestOptions = {
      method: method,
      headers: header,
      body: body ? (isFormData(body) ? body : JSON.stringify(body)) : null,
    };

    
    const response = await fetch(`${host}/api/${endPoints}`, requestOptions);
    const dataresp = await response.json();
    return dataresp;
  } catch (e) {
    return e;
  }
};

export const initializeMiddleware = store => next => action => {
  if (action.type === '@@INIT') {
    // Dispatch your initialization action here
    store.dispatch(fetchDataFromLocal());
  }
  return next(action);
};

export default serverCallFuction;