import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getStudentData } from "../redux/reducer/studentReducer";
import { addStaff } from "../redux/reducer/branchReducer";
import { useDispatch, useSelector } from "react-redux";

export default function ContentWrapper(props) {
  const showalert = () => {
    props.showAlert("danger", "danger");
  };
  const [query, setQuery] = useState("");

  const dispatch = useDispatch();
  const b_data = useSelector((state) => state.fetchDataFromLocal);

  const student_list = useSelector((state) => state.getStudentData);
  useEffect(() => {
    if (b_data && b_data._id) {
      dispatch(getStudentData({ b_id: b_data._id }));
    }
  }, [b_data]);

  return (
    <>
      <div className="content-wrapper payments">
        <div className="container-xxl flex-grow-1 container-p-y">
          <div className="row ">
            <div className="col-lg-3 ">
              <div class="card mb-3" style={{ "max-width": "540px" }}>
                <div class="row g-0 align-items-center">
                  <div class="col-md-4">
                    <img
                      src="../assets/img/avatars/graduation.png"
                      class="img-fluid rounded-start"
                      alt="..."
                    />
                  </div>
                  <div class="col-md-8">
                    <div class="card-body">
                      <h5 class="card-title">{student_list.length}</h5>
                      <p class="card-text">Total Students</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-3 ">
              <div class="card mb-3" style={{ "max-width": "540px" }}>
                <div class="row g-0 align-items-center">
                  <div class="col-md-4">
                    <img
                      src="../assets/img/avatars/sir.png"
                      class="img-fluid rounded-start"
                      alt="..."
                    />
                  </div>
                  <div class="col-md-8">
                    <div class="card-body">
                      <h5 class="card-title">
                        {b_data && (b_data.staff && b_data.staff.length)}
                      </h5>
                      <p class="card-text">Total Staff</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
