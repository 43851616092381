import { STUDENT_FORM } from "./constant";

export const FormForCreateStudent = () => {
  return {
    type: STUDENT_FORM.create_form,
  };
};

export const studentUploadSuccess = (res, type) => {
    
    return {
      type: STUDENT_FORM.success,
      payload: res,
      u_type: type,
    };
  };
  
  export const studentUploadFailure = () => {
    
    return {
      type: STUDENT_FORM.failed,
    }
  };

// export default fetchData;
