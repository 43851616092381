import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { API_CALL_STATUS } from "../redux/action/constant";

export default function TopBar() {
  const host = process.env.REACT_APP_URL;
  const location = useLocation();

  const [activeNavigation, setActiveNavigation] = useState("");
  const [showNotiNumber, setShowNotiNumber] = useState(false);

  const b_data = useSelector((state) => state.fetchDataFromLocal);
  const logged_user_data = useSelector((state) => state.set_login_user_data);

  useEffect(() => {
    switch (location.pathname) {
      case "/admin":
        setActiveNavigation("Dashboard");
        break;
      case "/admin/branch":
        setActiveNavigation("Branch");
        break;
      case "/admin/addNewBranch":
        setActiveNavigation("Add Branch");
        break;
      case "/admin/subjects":
        setActiveNavigation("Class");
        break;
      case "/admin/feedback-form":
        setActiveNavigation("Feedbacks");
        break;
      case "/admin/id_marksheet":
        setActiveNavigation("ID Card/ Marksheet");
        break;
      case "/admin/add_student_forms":
        setActiveNavigation("Student Register Form");
        break;
      case "/admin/all_students":
        setActiveNavigation("All Students");
        break;
      case "/admin/create_student":
        setActiveNavigation("Add New Student");
        break;
      case "/admin/teachers":
        setActiveNavigation("Teachers");
        break;
      case "/admin/marksheet_configure":
        setActiveNavigation("Marksheet Setup");
        break;
      case "/admin/staff":
        setActiveNavigation("Staff");
        break;
      case "/admin/fees_data":
        setActiveNavigation("Fees");
        break;
      case "/admin/exam_entry":
        setActiveNavigation("Exam Entry");
        break;
      case "/admin/staff":
        setActiveNavigation("Staff");
        break;
      case "/admin/tests":
        setActiveNavigation("Test Book");
        break;
      case "/admin/tests/report":
        setActiveNavigation("Test Report");
        break;
      case "/admin/tests/addTest":
        setActiveNavigation("Add Test");
        break;
      case "/admin/hostel/setup":
        setActiveNavigation("Hostel Setup");
        break;
      default:
        setActiveNavigation("not found");
        break;
    }
  }, [location]);

  const [notification] = useState([]);

  const MINUTE_MS = 10000;

  let history = useNavigate();
  const logout = () => {
    localStorage.clear();
    history("/auth");
  };

  // key change function

  const [changeKeyModal, setChangeKeyModal] = useState(false);

  const changeKey = () => {
    setChangeKeyModal(!changeKeyModal);
  };

  const [keyData, setKeyData] = useState({
    username: "admin",
    adminkey: "",
  });

  const keyChangeData = (e) => {
    setKeyData({ ...keyData, adminkey: e.target.value });
  };

  const setKeyInData = async (e) => {
    console.log(keyData);
    e.preventDefault();

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // "auth-token": token,
      },
      body: JSON.stringify(keyData),
    };

    try {
      const res = await fetch(`${host}/api/login/changeKey`, requestOptions);
      const datares = await res.json();

      alert(datares.msg);

      if (datares.status === "Success") {
        changeKey();
      } else {
      }
    } catch (error) {
      console.log(error);
    }
  };

  // key change function

  const dispatch = useDispatch();
  const openMenu = () => {
    dispatch({ type: API_CALL_STATUS.toogleMenu });
  };

  return (
    <>
      {changeKeyModal && (
        <div
          className="modal fade show"
          id="exampleModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          style={{ display: "block", background: "#edededb5" }}
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content" style={{ border: "none" }}>
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Change Key
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={changeKey}
                  style={{
                    border: "none",
                    padding: "0 5px",
                    borderRadius: "20px",
                  }}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body text-left">
                <label htmlFor="username">Enter Key</label>
                <input
                  type="text"
                  className="form-control"
                  name="admin_key"
                  placeholder="Enter username"
                  onChange={keyChangeData}
                />
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                  onClick={changeKey}
                >
                  Close
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={setKeyInData}
                >
                  Send Key
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      <nav
        className="layout-navbar container-xxl navbar navbar-expand-xl navbar-detached align-items-center bg-navbar-theme"
        id="layout-navbar"
      >
        <div
          className="layout-menu-toggle navbar-nav align-items-xl-center me-3 me-xl-0 d-xl-none"
          onClick={openMenu}
        >
          <i className="bx bx-menu bx-sm"></i>
        </div>

        <div
          className="navbar-nav-right d-flex align-items-center"
          id="navbar-collapse"
        >
          {/* <!-- Search --> */}
          <div className="navbar-nav align-items-center">
            <div className="c_name">
              <span
                onClick={changeKey}
                className="select_route"
                style={{ cursor: "pointer" }}
              >
                {activeNavigation}
              </span>
              <small className="mx-lg-4 mx-1 selectr_branch_span">
                (
                {b_data
                  ? "Selected Branch - " + b_data.branch_name
                  : "Selected Branch"}
                )
              </small>
            </div>
          </div>

          {/* <!-- /Search --> */}

          <ul className="navbar-nav flex-row align-items-center ms-auto">
            {/* <!-- User --> */}

            {/* notificaiton section is started from here */}
            <li className="nav-item dropdown-notifications navbar-dropdown dropdown me-3 me-xl-1">
              <Link
                className="nav-link dropdown-toggle hide-arrow"
                data-bs-toggle="dropdown"
                data-bs-auto-close="outside"
                aria-expanded="false"
              >
                <i className="bx bx-bell "></i>
                <span className="badge bg-danger rounded-pill badge-notifications">
                  {showNotiNumber ? notification.length : ""}
                </span>
              </Link>
              <ul className="dropdown-menu dropdown-notification dropdown-menu-end py-0">
                <li className="dropdown-menu-header border-bottom">
                  <div className="dropdown-header d-flex align-items-center py-3">
                    <h5 className="text-body mb-0 me-auto">Notification</h5>
                    <a
                      className="dropdown-notifications-all text-body"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      aria-label="Mark all as read"
                    >
                      <i className="bx fs-4 bx-envelope-open"></i>
                    </a>
                  </div>
                </li>
                <li className="dropdown-notifications-list scrollable-container ps">
                  <ul className="list-group list-group-flush">
                    {notification.length === 0 && "NO Notification"}
                    {notification.map((noti) => {
                      if (!showNotiNumber) {
                        if (noti.markasread) {
                          setShowNotiNumber(!showNotiNumber);
                        }
                      }
                      return (
                        <li
                          className={`list-group-item list-group-item-action dropdown-notifications-item 
                        ${noti.markasread ? "marked-as-read" : ""}`}
                        >
                          <div className="d-flex">
                            <div className="flex-shrink-0 me-3">
                              <div className="avatar">
                                <span className="avatar-initial rounded-circle bg-label-success">
                                  <i className="bx bx-bell"></i>
                                </span>
                              </div>
                            </div>
                            <div className="flex-grow-1">
                              <h6 className="mb-1">{noti.title}</h6>
                              <p className="mb-0">{noti.message}</p>
                              {/* <small className="text-muted">1h ago</small> */}
                            </div>
                            <div className="flex-shrink-0 dropdown-notifications-actions">
                              <a className="dropdown-notifications-read">
                                <span className="badge badge-dot"></span>
                              </a>
                              <a className="dropdown-notifications-archive">
                                <span className="bx bx-x"></span>
                              </a>
                            </div>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                  <div
                    className="ps__rail-x"
                    style={{ left: "0px", bottom: "0px" }}
                  >
                    <div
                      className="ps__thumb-x"
                      tabIndex="0"
                      style={{ left: "0px", width: "0px" }}
                    ></div>
                  </div>
                  <div
                    className="ps__rail-y"
                    style={{ top: "0px", right: "0px" }}
                  >
                    <div
                      className="ps__thumb-y"
                      tabIndex="0"
                      style={{ top: "0px", height: "0px" }}
                    ></div>
                  </div>
                </li>
                <li className="dropdown-menu-footer border-top">
                  <Link className="dropdown-item d-flex justify-content-center p-3">
                    View all notifications
                  </Link>
                </li>
              </ul>
            </li>

            <li>
              <p
                className="dropdown-item"
                onClick={logout}
                style={{ background: "cursor" }}
              >
                {/* <i className="bx bx-power-off me-2"></i> */}
                <span className="align-middle selectr_branch_span me-1">
                  Log Out
                </span>
              </p>
            </li>

            <li className="nav-item navbar-dropdown dropdown-user dropdown">
              <div
                className="nav-link dropdown-toggle hide-arrow"
                to=""
                data-bs-toggle="dropdown"
              >
                <div className="avatar avatar-online">
                  <img
                    src="../assets/img/avatars/1.png"
                    alt="Avtar"
                    className="w-px-40 h-auto rounded-circle"
                  />
                </div>
              </div>
              <ul className="dropdown-menu dropdown-menu-end">
                <li>
                  <Link className="dropdown-item" to="/">
                    <div className="d-flex">
                      <div className="flex-shrink-0 me-3">
                        <div className="avatar avatar-online">
                          <img
                            src="../assets/img/avatars/1.png"
                            alt="avtar"
                            className="w-px-40 h-auto rounded-circle"
                          />
                        </div>
                      </div>
                      <div className="flex-grow-1">
                        <span className="fw-semibold d-block">
                          {logged_user_data && logged_user_data.fullname}
                        </span>
                        <small className="text-muted">Admin</small>
                      </div>
                    </div>
                  </Link>
                </li>
                <li>
                  <div className="dropdown-divider"></div>
                </li>
                <li>
                  <Link className="dropdown-item" to="./profile">
                    <i className="bx bx-user me-2"></i>
                    <span className="align-middle">My Profile</span>
                  </Link>
                </li>

                <li>
                  <Link className="dropdown-item" to="#">
                    <span className="d-flex align-items-center align-middle">
                      <i className="flex-shrink-0 bx bx-credit-card me-2"></i>
                      <span className="flex-grow-1 align-middle">
                        Upgrade Plan
                      </span>
                    </span>
                  </Link>
                </li>

                <li>
                  <Link className="dropdown-item" to="tel:+916396617714">
                    <span className="d-flex align-items-center align-middle">
                      <i className="bx bx-phone me-2"></i>
                      <span className="flex-grow-1 align-middle">Call Us</span>
                    </span>
                  </Link>
                </li>

                <li>
                  <div className="dropdown-divider"></div>
                </li>
                <li onClick={logout}>
                  <Link className="dropdown-item" to="#">
                    <span className="d-flex align-items-center align-middle">
                      <i className="bx bx-power-off me-2"></i>
                      <span className="flex-grow-1 align-middle">Logout</span>
                    </span>
                  </Link>
                </li>
              </ul>
            </li>
            {/* <!--/ User --> */}
          </ul>
        </div>
      </nav>

      {/* <!-- / Navbar -->

          <!-- Content wrapper --> */}
      {/* <ContentWrapper /> */}
    </>
  );
}
