import React, { useState } from "react";
import MediaCon from "./MediaCon";
/* require("dotenv").config(); */
const MediaState = (props) => {
  const host = process.env.REACT_APP_URL;
  //console.log(host);
  const token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7ImlkIjoiNjNkZjg4YWFhNDllYjE2NzFjYTdkZGNhIn0sImlhdCI6MTY3NTU5NTEzN30.xGYO_zIQPGR5YvcYhQFDlInCydDomn6Lrsqv6bZmrMQ";
  //console.log(token);


  const [media, setMedia] = useState([]);


  const deleteMedia = async (id) => {
    const requestOptions = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "auth-token": token
      },
    };
    const response = await fetch(
      `${host}/api/media/deleteMedia/${id}`,
      requestOptions
    );
    const datares = await response.json();

    console.log(datares);
    /* setCategory(datares.data); */
    if(datares.status=== "Success"){
      const updateData = media.filter((cat)=>{
        return cat._id!==id

      })
      setMedia(updateData)
      return datares
    }
  };
  const getAllMedia = async (id) => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "auth-token": token
      },
    };
    const response = await fetch(
      `${host}/api/media`,
      requestOptions
    );
    const datares = await response.json();

    console.log(datares);
    setMedia(datares.data);
  };

  const uploadMedia = async (formdata) => {
    try {
      const requestOptions = {
        method: "POST",
        headers: {
          
          "auth-token": token
        }, body: formdata
      };
      const response = await fetch(
        `${host}/api/media/uploadmedia`,
        requestOptions
      );
      const dataresp = await response.json();
      console.log(dataresp);
      if (dataresp.status === "success") {
        setMedia(media.concat(dataresp.data))
      }
      return dataresp
    }
    catch (e) {

    }
  } 

  return (
    <MediaCon.Provider
      value={{
        media,
        getAllMedia,
        uploadMedia,
        deleteMedia
      }}

    >
      {props.children}
    </MediaCon.Provider>
  );
}
export default MediaState;