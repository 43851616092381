import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { feesEntry } from "../../redux/reducer/studentReducer";

const PayModal = ({
  openEdit,
  feed_data,
  addDataInArray,
  b_data,
  payPrint,
}) => {
  const style = {
    background: "#00000080",
    display: "block",
  };

  const dispatch = useDispatch();
  const [visibleName, setVisibleName] = useState("");

  const [fees_data, set_fees_data] = useState({
    index: 0,
    fees: 0,
    type: "",
    outstanding: 0,
    discount: 0,
    discount_amount: 0,
    late_fees: 0,
    flatt_discount: false,
    total_paid: 0,
    remaining_amount: 0,
  });

  const [old_fees_entry, set_old_fees_entry] = useState([]);

  useEffect(() => {
    const setData = async () => {
      let c_fees = 0;
      let index = 0;
      let type = feed_data.type;

      if (
        feed_data.type === "admission_fees" ||
        feed_data.type === "exam_fees"
      ) {
        setVisibleName(feed_data.type);
        c_fees = feed_data.obj.fees_data[feed_data.type];
      } else if (feed_data.type.includes("installment")) {
        const split_data = feed_data.type.split("_");
        index = parseInt(split_data[1]);
        type = split_data[0];
        c_fees =
          parseFloat(feed_data.obj.fees_data.total_amount) /
          parseInt(feed_data.obj.fees_data.installment);
        setVisibleName(feed_data.type);
      } else if (feed_data.type.includes("additional")) {
        const split_data = feed_data.type.split("_");
        index = parseInt(split_data[1]);
        type = split_data[0];
        c_fees = parseInt(feed_data.obj.additional_fees[index].amount);
        setVisibleName(feed_data.obj.additional_fees[index].name);
      }

      set_fees_data({
        ...fees_data,
        fees: c_fees,
        index: index,
        type: type,
        outstanding: parseFloat(feed_data.fees_data.out_standing),
      });
    };
    setData();
  }, []);

  const onDiscountTypeChange = (e) => {
    set_fees_data({ ...fees_data, flatt_discount: JSON.parse(e.target.value) });
  };

  const discountValueChange = (e) => {
    if (e.target.value !== "") {
      if (e.target.name === "total_paid") {
        const value =
          parseFloat(fees_data.outstanding + fees_data.fees) -
          parseFloat(fees_data.discount_amount) +
          parseFloat(fees_data.late_fees) -
          parseFloat(e.target.value);

        set_fees_data({
          ...fees_data,
          [e.target.name]: e.target.value,
          remaining_amount: value,
        });
      } else {
        set_fees_data({ ...fees_data, [e.target.name]: e.target.value });
      }
    }
  };

  useEffect(() => {
    calculateDiscount();
  }, [fees_data.flatt_discount, fees_data.discount]);

  const calculateDiscount = () => {
    let amount = 0;
    if (fees_data.discount !== 0) {
      if (fees_data.flatt_discount) {
        amount = fees_data.discount;
      } else {
        amount =
          (parseFloat(fees_data.outstanding + fees_data.fees) *
            fees_data.discount) /
          100;
      }

      set_fees_data({ ...fees_data, discount_amount: amount });
    }
  };

  const callServertoAddEntry = (e) => {
    e.preventDefault();

    const session = b_data.sessions.filter((se) => {
      return se.current;
    });

    dispatch(
      feesEntry(
        { s_id: feed_data.s_id, session: session[0].session_year },
        fees_data,
        (res) => {
          
          payPrint(res);
        }
      )
    );
  };

  return (
    <div className="modal reviewModal" style={style}>
      <div className="modal-dialog fields_dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              Pay {feed_data && visibleName} Fees
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={openEdit}
            ></button>
          </div>
          <div className="modal-body" style={{ textAlign: "left" }}>
            <div className="">
              <table width={"100%"} className="myTableForm">
                <tr>
                  <td>
                    <div className="form-group">
                      <label htmlFor="">Fees</label>
                      <input
                        type="text"
                        className="form-control"
                        name=""
                        id=""
                        aria-describedby="helpId"
                        placeholder="0"
                        disabled
                        value={fees_data.fees}
                      />
                    </div>
                  </td>

                  <td>
                    <div className="form-group">
                      <label htmlFor="">Outstanding</label>
                      <input
                        type="text"
                        className="form-control"
                        name=""
                        id=""
                        aria-describedby="helpId"
                        placeholder=""
                        disabled
                        value={fees_data.outstanding}
                      />
                    </div>
                  </td>

                  <td>
                    <div className="form-group">
                      <label htmlFor="">Total</label>
                      <input
                        type="text"
                        className="form-control"
                        name=""
                        id=""
                        aria-describedby="helpId"
                        placeholder=""
                        disabled
                        value={fees_data.outstanding + fees_data.fees}
                      />
                    </div>
                  </td>

                  <td></td>
                </tr>

                <tr>
                  <td>
                    <div className="form-group">
                      <label htmlFor="">Discount</label>
                    </div>
                  </td>

                  <td>
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        name=""
                        id=""
                        aria-describedby="helpId"
                        placeholder=""
                        disabled
                        value={fees_data.discount_amount}
                      />
                    </div>
                  </td>

                  <td>
                    <div className="form-check form-check-inline">
                      <label className="form-check-label">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="discount_type"
                          id=""
                          value={true}
                          onChange={onDiscountTypeChange}
                        />
                        Amount
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <label className="form-check-label">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="discount_type"
                          id=""
                          value={false}
                          onChange={onDiscountTypeChange}
                        />
                        Percentage
                      </label>
                    </div>
                  </td>

                  <td>
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        name="discount"
                        id=""
                        aria-describedby="helpId"
                        placeholder=""
                        onChange={discountValueChange}
                        defaultValue={0}
                      />
                    </div>
                  </td>
                </tr>

                <tr>
                  <td>
                    <label>Payment Amount</label>
                  </td>
                  <td>
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        name=""
                        id=""
                        aria-describedby="helpId"
                        placeholder=""
                        disabled
                        value={
                          parseFloat(fees_data.outstanding + fees_data.fees) -
                          fees_data.discount_amount
                        }
                      />
                    </div>
                  </td>
                </tr>

                <tr>
                  <td>
                    <label>Late Fees</label>
                  </td>
                  <td>
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        name="late_fees"
                        id=""
                        aria-describedby="helpId"
                        placeholder="enter late fees"
                        onChange={discountValueChange}
                        value={fees_data.late_fees}
                      />
                    </div>
                  </td>
                </tr>

                <tr>
                  <td>
                    <label>Total Payable Amount</label>
                  </td>
                  <td>
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        name=""
                        id=""
                        aria-describedby="helpId"
                        placeholder=""
                        disabled
                        value={
                          parseFloat(fees_data.outstanding + fees_data.fees) -
                          fees_data.discount_amount +
                          parseFloat(fees_data.late_fees)
                        }
                      />
                    </div>
                  </td>
                </tr>

                <tr>
                  <td>
                    <label>Total Paid Amount</label>
                  </td>
                  <td>
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        name="total_paid"
                        id=""
                        aria-describedby="helpId"
                        placeholder="0"
                        onChange={discountValueChange}
                        defaultValue={
                          parseFloat(fees_data.outstanding + fees_data.fees) -
                          fees_data.discount_amount +
                          parseFloat(fees_data.late_fees)
                        }
                      />
                    </div>
                  </td>
                  <td>
                    <label>Remaining Amount</label>
                  </td>
                  <td>
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        name="remaining_amount"
                        id=""
                        aria-describedby="helpId"
                        placeholder=""
                        disabled
                        onChange={discountValueChange}
                        value={
                          parseFloat(fees_data.outstanding + fees_data.fees) -
                          fees_data.discount_amount +
                          parseFloat(fees_data.late_fees) -
                          parseFloat(fees_data.total_paid)
                        }
                      />
                    </div>
                  </td>
                </tr>
              </table>
            </div>
          </div>
          <div className="modal-footer">
            <button className="btn btn-primary" onClick={callServertoAddEntry}>
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PayModal;
