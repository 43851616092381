import React, { useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import DraggableItem from "./dragData/DragableItem";

import DropContainer from "./dragData/DropContainer";
import "./Idcard.css";
import ColorPicker from "./dragData/ColorPicker";

const Configure = () => {
  const [items, setItems] = useState([]);

  const handleDrop = (item) => {
    console.log(item);
    setItems([...items, item]);
  };

  

  //   edit setup
  const [editStaus, setEditStatus] = useState(false);
  const [edit_obj, set_edit_obj] = useState(null);


  const updateColor= (name, value)=>{
    setItems( items.map((item, i)=>{
        return item.id === edit_obj.id ? {...item, style:{...item.style , [name]:value}} : item
    }) );

    set_edit_obj({...edit_obj, style:{...edit_obj.style , [name]:value}})

  }

  console.log(items);

  return (
    <DndProvider backend={HTML5Backend}>
      <div className="content-wrapper payments">
        <div className="container-xxl flex-grow-1 container-p-y">
          <div className="row">
            <div className="col-lg-12 mb-4 ">
              <div className="row">
                <div className="col-6 font-bold">
                  {/* <ul className="slider_button slider-white plain-orange">
                  <li
                    className="slider-active me-2"
                    onClick={() => {
                      
                    }}
                  >
                    + Add Student
                  </li>
                </ul> */}
                </div>
              </div>

              <div className="row " style={{ textAlign: "left" }}>
                <div className="col-8 mx-auto">
                  <div
                    className="card"
                    style={{ border: "none", height: "800px" }}
                  >
                    <div className="card-body">
                      <DropContainer
                        onDrop={handleDrop}
                        // style={{ border: "1px solid" }}
                      >
                        <div className="">data is here</div>
                        {items.map((item, i) => (
                          //   tag_value, style,

                          <div
                            key={item.id}
                            style={item.style}
                            onClick={() => {
                              setEditStatus(true);
                              set_edit_obj(item);
                            }}
                          >
                            {item.tag_value}
                          </div>

                        ))}
                      </DropContainer>
                    </div>
                  </div>
                </div>
                <div className="col-4">
                  <div
                    className="card"
                    style={{ border: "none", background: "rgb(231 231 231)" }}
                  >
                    <div className="card-body">
                      {editStaus ? (
                        <>
                          <div className="d-flex justify-content-between">
                            <span className="">Edit {edit_obj.children}</span>{" "}
                            <span
                              onClick={() => {
                                setEditStatus(false);
                              }}
                            >
                              <i className="fa fa-close"></i>
                            </span>
                          </div>

                          <div className="mt-3">
                            <ul className="m-0 p-0">
                              <li className="d-flex justify-content-between">
                                {" "}
                                <span>Color :</span>{" "}
                                <span>
                                  <ColorPicker choosedColor={(color)=>{
                                    updateColor("color", color)
                                  }} old_color={edit_obj.style.color}/>
                                </span>{" "}
                              </li>

                              <li className="d-flex justify-content-between mt-3">
                                {" "}
                                <span>Font Size :</span>{" "}
                                <span>
                                  <input type="number" defaultValue={edit_obj.style.fontSize.replace("px", "")} style={{width:"50px"}} onChange={(e)=>{
                                    updateColor("fontSize", e.target.value+"px")
                                  }}     />
                                </span>
                              </li>


                              <li className="d-flex justify-content-between mt-3">
                                {" "}
                                <span>Position :</span>{" "}
                                <span>
                                  <select onChange={(e)=>{
                                    updateColor("position", e.target.value)
                                  }}>
                                    <option value={"relative"} selected={edit_obj.style.position === "relative"} >Relative</option>
                                    <option value={"absolute"} selected={edit_obj.style.position === "absolute"}>Absolute</option>
                                  </select>
                                </span>
                              </li>

                                  {edit_obj.style.position === "absolute" ?
                              <li className="d-flex justify-content-between mt-3">
                                {" "}
                                <span>X Position :</span>{" "}
                                <span>
                                <input type="number" defaultValue={edit_obj.style.left} style={{width:"50px"}} onChange={(e)=>{
                                    updateColor("left", parseInt(e.target.value, 10))
                                  }}     />
                                </span>

                                <span>Y Position :</span>{" "}
                                <span>
                                <input type="number" defaultValue={edit_obj.style.top} style={{width:"50px"}} onChange={(e)=>{
                                    updateColor("top", parseInt(e.target.value, 10))
                                  }}     />
                                </span>
                              </li> : ""
}
                            </ul>
                          </div>
                        </>
                      ) : (
                        <ul className="p-0 m-0 d-flex flex-wrap justify-content-between dragData">
                          <li>
                            <DraggableItem
                              id="1"
                              tag_value={"{student_name}"}
                              style={{ color: "black", position: "relative" , top:0, left:0, fontSize:"10px", fontWeight:"bold" }}
                            >
                              Student Name new
                            </DraggableItem>
                          </li>
                        </ul>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </DndProvider>
  );
};

export default Configure;
