import { CLASS_ACTION } from "./constant";

export const classUploadSuccess = (res, type) => {
  return {
    type: CLASS_ACTION.success,
    payload: res,
    u_type: type,
  };
};

export const classUploadFailure = () => {
  return {
    type: CLASS_ACTION.failed,
  };
};
