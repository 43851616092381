import React, { useState, useRef, useEffect } from "react";
import "./Idcard.css";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { getImdageData, uploadImage } from "../../redux/reducer/imageUpload";
import { showAlertAction, showMyAlert } from "../../redux/action";
import { fetchDataFromLocal } from "../../redux/reducer/branchReducer";
const ID_card = () => {
  const history = useNavigate();

  // use reducer

  const myState = useSelector((state) => state.uploadImage);
  const b_data = useSelector((state) => state.fetchDataFromLocal);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchDataFromLocal());
  }, []);


  useEffect(()=>{
    dispatch(getImdageData({ b_id: b_data._id }));
  },[b_data])


  const [getData, setGetData] = useState({});

  useEffect(() => {
    if (myState.length > 0) {
      let g_data = { idCardFront: "", idCardBack: "", marksheet: "" };

      
      myState.map((data) => {
        if (data.type === "idCard") {
          for (let index = 0; index < data.file.length; index++) {
            const f = data.file[index];
            console.log(f);
            if (f.type === "idCardFront") {
              g_data.idCardFront = f.path;
            } else {
              g_data.idCardBack = f.path;
              // setGetData({ ...getData, [f.type]: f.path });
            }
          }

          setGetData(g_data);
        } else {
          g_data.marksheet = data.file[0].path;
        }
      });
    }
  }, [myState]);

  console.log(getData);

  const idfrontRef = useRef();
  const idbackRef = useRef();
  const marksheetRef = useRef()
  // marksheet
  const [files, setFiles] = useState([]);
  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const droppedFiles = Array.from(event.dataTransfer.files);
    setFiles(droppedFiles);
  };

  const handleFileSelect = (event) => {
    setSelectdFileData({
      ...selectedFileData,
      [event.target.name]: event.target.files,
    });

    setFiles([...files, { [event.target.name]: event.target.files }]);

    // const selectedFiles = Array.from(event.target.files);
    // setFiles(selectedFiles);
     
  };

  // marksheet

  const openImg = (type) => {
    if (type === "front") {
      idfrontRef.current.click();
    } else if(type==="back") {
      idbackRef.current.click();
    }
    else if(type==="marksheet"){
      marksheetRef.current.click();
    }
  };

  const [selectedFileData, setSelectdFileData] = useState({});

  console.log(selectedFileData);

 const uploadIdCards = (e, type) => {
   e.preventDefault();

   console.log('====================================');
   console.log('files - ', files);
   console.log(type)
   console.log('====================================');
   const body1 = new FormData();
   body1.append("b_id", b_data._id);
   body1.append("session", "2022-24");
   body1.append("type", type);

   if (type === "idCard") {
     files.map((file) => {
       const key = Object.keys(file);
       body1.append(key, file[key][0]);
     });
   } else if (type === "marksheet") {
    files.map((file) => {
      const key = Object.keys(file);
      body1.append(key, file[key][0]);
    });
     
   }

   console.log(body1);
  //  console.log("files - ", files);
   dispatch(uploadImage(body1));
 };

  return (
    <>
      <div className="content-wrapper payments">
        <div className="container-xxl flex-grow-1 container-p-y">
          <div className="row">
            <div className="col-lg-12 mb-4 ">
              <div className="row">
                <div className="col-lg-6 col-12 font-bold">
                  {/* <ul className="slider_button slider-white plain-orange">
                  <li
                    className="slider-active me-2"
                    onClick={() => {
                      
                    }}
                  >
                    + Add Student
                  </li>
                </ul> */}
                </div>
              </div>

              <div className="row " style={{ textAlign: "left" }}>
                <div className="col-lg-6 col-12 mx-auto">
                  <div className="card">
                    <h5 className="card-header">ID Card</h5>
                    <div className="card-body">
                      <ul className="d-flex p-0 m-0">
                        <li
                          className="image-uploader"
                          style={{ width: "50%" }}
                          onClick={() => {
                            openImg("front");
                          }}
                        >
                          <div className="image-container">
                            <i className="fa fa-3x fa-image"></i>
                            <label>Choose ID card Front</label>
                          </div>

                          {selectedFileData.idCardFront ? (
                            <img
                              src={URL.createObjectURL(
                                selectedFileData.idCardFront[0]
                              )}
                              className="img-fluid"
                              alt="ID Card Front"
                            />
                          ) : getData.idCardFront !== "" ? (
                            <img
                              src={getData.idCardFront}
                              className="img-fluid"
                              alt="ID Card Front"
                            />
                          ) : (
                            <></>
                          )}

                          <input
                            type="file"
                            ref={idfrontRef}
                            className="form-control d-none"
                            name="idCardFront"
                            id=""
                            aria-describedby="helpId"
                            placeholder=""
                            onChange={handleFileSelect}
                          />
                        </li>

                        <li
                          className="image-uploader"
                          style={{ width: "50%" }}
                          onClick={() => {
                            openImg("back");
                          }}
                        >
                          <div className="image-container">
                            <i className="fa fa-3x fa-image"></i>
                            <label>Choose ID card Back</label>
                          </div>

                          {selectedFileData.idCardBack ? (
                            <img
                              src={URL.createObjectURL(
                                selectedFileData.idCardBack[0]
                              )}
                              className="img-fluid"
                              alt="ID Card Back"
                            />
                          ) : getData.idCardBack !== "" ? (
                            <img
                              src={getData.idCardBack}
                              className="img-fluid"
                              alt="ID Card back"
                            />
                          ) : (
                            <></>
                          )}

                          

                          <input
                            type="file"
                            ref={idbackRef}
                            className="form-control d-none"
                            name="idCardBack"
                            id=""
                            aria-describedby="helpId"
                            placeholder=""
                            onChange={handleFileSelect}
                          />
                        </li>
                      </ul>
                    </div>

                    <div className="card-footer pt-2">
                      <button
                        className="btn btn-primary"
                        onClick={(e) => {
                          uploadIdCards(e, "idCard");
                        }}
                      >
                        Upload
                      </button>

                      <button
                        className="btn btn-secondary mx-3"
                        onClick={() => {
                          history("/admin/id_card_configration");
                        }}
                      >
                        Id Card Configuration
                      </button>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 col-12 mt-4 mt-lg-0 mx-auto">
                  <div className="card">
                    <h5 className="card-header">Marksheet</h5>
                    <div className="card-body">
                      <ul className="d-flex p-0 m-0">
                        <li
                          className="image-uploader"
                          style={{ width: "100%" }}
                          onDragOver={handleDragOver}
                          onDrop={handleDrop}
                          onClick={() => {
                            openImg("marksheet", marksheetRef);
                          }}
                        >
                          <div className="image-container">
                            <i className="fa fa-3x fa-image"></i>
                            <label>Choose marksheet background</label>
                          </div>

                          {selectedFileData.marksheet ? (
                            <img
                              src={URL.createObjectURL(
                                selectedFileData.marksheet[0]
                              )}
                              className="img-fluid"
                              alt="Marksheet"
                            />
                          ) : getData.marksheet !== "" ? (
                            <img
                              src={getData.marksheet}
                              className="img-fluid"
                              alt="Marksheet"
                            />
                          ) : (
                            <></>
                          )}

                          {/* run this code when state found from server */}

                          <input
                            type="file"
                            className="form-control d-none"
                            name="marksheet"
                            id=""
                            aria-describedby="helpId"
                            placeholder=""
                            onChange={handleFileSelect}
                            ref={marksheetRef} // Reference for the marksheet input
                          />
                        </li>
                      </ul>
                    </div>

                    <div className="card-footer pt-2">
                      <button
                        className="btn btn-primary me-3"
                        onClick={(e) => uploadIdCards(e, "marksheet")}
                      >
                        Upload
                      </button>
                      <button
                        className="btn btn-secondary"
                        onClick={() => {
                          history("/admin/marksheet_configure");
                        }}
                      >
                        marksheet_configure
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ID_card;
