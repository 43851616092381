import { showMyAlert } from "../action";
import { branchUploadFailure, branchUploadSuccess } from "../action/branch";
import { API_CALL_STATUS, BRANCH_ACTION } from "../action/constant";
import serverCallFuction from "../constantFunction";

const selected_branch = {};
const current_session = "";

export const fetchDataFromLocal = () => (dispatch) => {
  console.log("Fetch data called reducer");
  const data = JSON.parse(localStorage.getItem("selected_branch"));
  dispatch(branchUploadSuccess({ data: data }, "getLocalData"));
};

export const localActiveBranch = (object) => (dispatch) => {
  console.log("call local active branch");
  localStorage.setItem("selected_branch", JSON.stringify(object));
  dispatch(branchUploadSuccess({ data: object }, "getLocalData"));
};

export const currentSession = (state = current_session) => {
  const b_data = JSON.parse(localStorage.getItem("selected_branch"));
  if (b_data) {
    const session = b_data.sessions.filter((branch) => {
      return branch.current;
    });

    console.log("session - "+session)
    if(session.length>0){
      state = session[0];
    }
    // state = session[0];
  }
  return state;
};

export const addStaff =
  (payload, params, m_type, closeModalCallback) => async (dispatch) => {
    try {
      let method = "GET";
      let url = "";

      if (m_type === "add") {
        method = "POST";
        url += `addStaff/${params.b_id}`;
      } else if (m_type === "edit") {
        method = "PUT";
        url += `editStaff/${params.b_id}`;
      } else if (m_type === "delete") {
        method = "DELETE";
        url += `deleteStaff/${params.b_id}/${params.staff_id}`;
      }

      const res = await serverCallFuction(method, `branch/${url}`, payload);

      console.log(res);

      if (res.status === "Success") {
        dispatch(showMyAlert(res.message, "success"));
        dispatch(branchUploadSuccess(res, "staff_" + m_type));
        if (typeof closeModalCallback === "function") {
          closeModalCallback();
        }
      } else {
        dispatch(showMyAlert(res.message, "warning"));
        dispatch(branchUploadFailure());
      }
    } catch (error) {
      dispatch(showMyAlert("Exeption Error - 202"));
    }
  };

  export const headerConfigUpload = (payload) => async (dispatch) =>{
    try {
      const res = await serverCallFuction("POST", `image/branch_images`, payload);
      if(res.status === "Success"){
        dispatch(branchUploadSuccess(res, "header_add"));
        dispatch(showMyAlert(res.message, "success"));
      }else{
        dispatch(showMyAlert("Something went wrong!", "warning"));
      }
    } catch (error) {
      dispatch(showMyAlert("Exeption Error - 203", "danger"));
    }
  }

const branchData = (state = selected_branch, action) => {
  console.log("branch data function called");
  switch (action.type) {
    case BRANCH_ACTION.success:
      if (action.u_type === "getLocalData") {
        state = action.payload.data;
      }
      if (action.u_type === "currentSession") {
        state = action.payload.data;
      }

      if (action.u_type.includes("staff")) {
        const data = action.u_type.split("_");
        state = action.payload.data;
        localStorage.setItem(
          "selected_branch",
          JSON.stringify(action.payload.data)
        );
        
      }

      if(action.u_type === "header_add"){
        state = action.payload.data;
      }
      
      return state;
    case BRANCH_ACTION.failed:
      console.log("succes call from branch");
      return state;
    default:
      return state;
  }
};

export default branchData;
