import React from 'react'
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="pb-5 my-5">
      <div className="container py-5">
        <div className="row">
          <div className="col-6">
            <Link to={"/"}>
            <span className="ividhan_logo">IVIDHAN</span>
            </Link>
            <br />
            <span className="edu">Education Mangement Software</span>
            <ul className="sociallinks d-flex my-3">
              <a href="/" className="items">
                <li className="links">
                  <i className="fa-brands fa-facebook"></i>
                </li>
              </a>
              <a href="/" className="items">
                <li className="links">
                  <i className="fa-brands fa-twitter"></i>
                </li>
              </a>
              <a href="/" className="items">
                <li className="links">
                  <i className="fa-brands fa-instagram"></i>
                </li>
              </a>
            </ul>
            <span className="address">
              Saket Colony <br /> Hathras, UP , India , 204101
            </span>
          </div>
          <div className="col-6">
            <span className="quicklinksheading">Quick Links</span>
            <ul className="quicklinks">
              {/* <Link to={"/about-us"} className="items">
                <li className="links">About Us</li>
              </Link> */}
              <Link to={"/contact-us"} className="items">
                <li className="links">Contact Us</li>
              </Link>
              <Link to={"/terms-conditions"} className="items">
                <li className="links">Terms & Conditions</li>
              </Link>
              <Link to={"/privacy-policy"} className="items">
                <li className="links">Privacy Policy</li>
              </Link>
            </ul>
          </div>
          <div className="col-4">
            {/* <span className="quicklinksheading">Social Links</span>
            <ul className="quicklinks">
              <a href="/" className="items">
                <li className="links">About Us</li>
              </a>
              <a href="/" className="items">
                <li className="links">Contact Us</li>
              </a>
              <a href="/" className="items">
                <li className="links">Terms & Conditions</li>
              </a>
              <a href="/" className="items">
                <li className="links">Privacy Policy</li>
              </a>
            </ul> */}
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer
