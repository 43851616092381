import React, { useContext, useEffect, useState } from "react";
import { SubjectCon } from "../../context/AllContext";
import { addClassRedux } from "../../redux/reducer/class/classReducer";
import { useDispatch } from "react-redux";

const SubjectList = ({ openEdit, showAlert }) => {
  const context = useContext(SubjectCon);
  const { subjects, getSubjects, addSubjects, deleteSubjects } = context;

  useEffect(() => {
    getSubjects();
  }, []);

  const style = {
    background: "#00000080",
    display: "block",
  };

  const [newSubject, setNewSubject] = useState({ sub_name: "" });

  return (
    <>
      <div className="modal reviewModal" style={style}>
        <div className="modal-dialog fields_dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Subject List
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={openEdit}
              ></button>
            </div>
            <div className="modal-body" style={{ textAlign: "left" }}>
              <div className="row">
                <div className="col-lg-7 col-12">
                  <ul className="m-0 p-0 subject_list">
                    {subjects.map((sub, i) => {
                      return (
                        <li
                          className="bg-secondary d-flex justify-content-between"
                          key={i}
                        >
                          <span>{sub.sub_name}</span>{" "}
                          <span>
                            <span className="badge bg-primary me-1">
                              <i className="fa fa-edit"></i>
                            </span>

                            <span
                              className="badge bg-danger"
                              onClick={() => {
                                deleteSubjects(sub._id);
                              }}
                            >
                              <i className="fa fa-trash"></i>
                            </span>
                          </span>
                        </li>
                      );
                    })}
                  </ul>
                </div>
                <div className="col-lg-5 col-12 mt-lg-0 mt-4">
                  <h5>Add Subject</h5>
                  <div class="form-group">
                    <label for="">Subject Name</label>
                    <input
                      type="text"
                      class="form-control"
                      name="sub_name"
                      id=""
                      aria-describedby="helpId"
                      value={newSubject.sub_name || ""}
                      placeholder="Enter subject name"
                      onChange={(e) => {
                        setNewSubject({
                          ...newSubject,
                          sub_name: e.target.value,
                        });
                      }}
                    />
                  </div>

                  <button
                    className="btn btn-primary mt-3"
                    onClick={async (e) => {
                      e.preventDefault();
                      const res = await addSubjects(newSubject);
                      if (res.status === "Success") {
                        // openEdit();
                        showAlert("Subject has been added.", "Success");
                        setNewSubject({ sub_name: "" });
                      }
                    }}
                  >
                    Create
                  </button>
                </div>
              </div>
            </div>
            <div className="modal-footer"></div>
          </div>
        </div>
      </div>
    </>
  );
};

const AddClass = ({ b_id, openEdit, addClass, showAlert }) => {
  const style = {
    background: "#00000080",
    display: "block",
  };

  const dispatch = useDispatch();

  const [classData, setClassData] = useState({
    c_name: "",
    c_type: "",
    c_duration: "",
    e_freq: 0,
    b_id: b_id,
    exam_data: [],
  });

  const onInput = (e) => {
    setClassData({ ...classData, [e.target.name]: e.target.value });
  };

  const onExamInput = (e, index) => {
    let data = classData.exam_data;

    if (classData.exam_data.length !== 0) {
      if (classData.exam_data.some((exam) => exam.index === index)) {
        data = classData.exam_data.map((exam, i) => {
          return exam.index === index
            ? { ...exam, [e.target.name]: e.target.value }
            : exam;
        });
      } else {
        data.push({ index: index, [e.target.name]: e.target.value });
      }
    } else {
      data.push({ index: index, [e.target.name]: e.target.value });
    }

    setClassData({ ...classData, exam_data: data });
  };

  return (
    <>
      <div className="modal reviewModal" style={style}>
        <div className="modal-dialog session_dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Add Class
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={openEdit}
              ></button>
            </div>
            <div className="modal-body" style={{ textAlign: "left" }}>
              <div className="row">
                <div className="col-12 text-right">
                  <div class="form-group mb-2">
                    <label for="">Type</label>
                    <select
                      class="form-control"
                      name="c_type"
                      id=""
                      onChange={onInput}
                    >
                      <option>Choose Type</option>
                      <option value={"class"}>Class</option>
                      <option value={"course"}>Course</option>
                      <option value={"degree"}>Degree</option>
                    </select>
                    <small id="helpId" class="form-text text-muted">
                      This type helps to define your class type which will help
                      in improving the performance of the software.
                    </small>
                  </div>

                  <div class="form-group mb-2">
                    <label for="">Name</label>
                    <input
                      type="text"
                      class="form-control"
                      name="c_name"
                      id=""
                      aria-describedby="helpId"
                      placeholder="Enter Name ... ex- 10, SSC-CHSL, etc."
                      onChange={onInput}
                    />
                  </div>

                  <div className="row">
                    <div className="col-6">
                      <div class="form-group mb-2">
                        <label for="">Duration</label>
                        <input
                          type="text"
                          class="form-control"
                          name="c_duration"
                          id=""
                          aria-describedby="helpId"
                          placeholder=""
                          onChange={onInput}
                        />
                      </div>
                    </div>
                    <div className="col-6">
                      <div class="form-group mb-2">
                        <label for="">Exam Freqency</label>
                        <input
                          type="number"
                          class="form-control"
                          name="e_freq"
                          id=""
                          aria-describedby="helpId"
                          placeholder=""
                          onChange={onInput}
                          defaultValue={0}
                        />
                        <small id="helpId" class="form-text text-muted">
                          Hint
                        </small>
                      </div>
                    </div>
                  </div>

                  {(() => {
                    const exam_data = [];

                    for (let i = 0; i < classData.e_freq; i++) {
                      console.log("index - " + i);
                      exam_data.push(
                        <>
                          <div className="row" key={i}>
                            <div className="col-6">
                              <div class="form-group mb-2">
                                <label for="">Exam Label</label>
                                <input
                                  type="text"
                                  class="form-control"
                                  name="e_label"
                                  id=""
                                  aria-describedby="helpId"
                                  placeholder="Ex - quater, half yearly, yearly etc.."
                                  onChange={(e) => {
                                    onExamInput(e, i);
                                  }}
                                />
                              </div>
                            </div>
                            <div className="col-6">
                              <div class="form-group mb-2">
                                <label for="">Total Marks</label>
                                <input
                                  type="number"
                                  class="form-control"
                                  name="e_total"
                                  id=""
                                  aria-describedby="helpId"
                                  placeholder="Out of 50, 100 , ..."
                                  onChange={(e) => {
                                    onExamInput(e, i);
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </>
                      );
                    }
                    return exam_data;
                  })()}

                  <button
                    className="btn btn-primary mt-3"
                    onClick={async () => {
                      dispatch(
                        addClassRedux(classData, () => {
                          openEdit();
                        })
                      );

                      // const res = await addClass(classData);
                      // if (res.status === "Success") {
                      //   openEdit();
                      //   showAlert("Class has been added", "Success");
                      // }
                    }}
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export { AddClass, SubjectList };
