import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import IdCardPreview from "../id_card/IdCardPreview";
import { useDispatch, useSelector } from "react-redux";
import { getImdageData } from "../../redux/reducer/imageUpload";
import { CreateStudentForm } from "../../redux/reducer/studentForm";
import {
  DeleteStudents,
  getStudentData,
} from "../../redux/reducer/studentReducer";
import { getAllBranch } from "../../redux/reducer/branchState";
import CrossList from "./crosslist/CrossList";
import ReactToPrint, { useReactToPrint } from "react-to-print";
import { print_data_set } from "../../redux/action";
import { GetClassServer } from "../../redux/reducer/class/classReducer";
const excelJS = require("exceljs");
const AllStudents = (props) => {
  const history = useNavigate();
  const dispatch = useDispatch();
  const pdfRef = useRef();
  const [query, setQuery] = useState("");

  // pring id card

  const card_data = useSelector((state) => state.uploadImage);
  const b_data = useSelector((state) => state.fetchDataFromLocal);
  const c_session = useSelector((state) => state.currentSession);
  const student_list = useSelector((state) => state.getStudentData);
  const createFormData = useSelector((state) => state.CreateStudentForm);
  const class_data = useSelector((state) => state.classData);
  const [getData, setGetData] = useState({});
  const [visibleField, setVisibleFields] = useState([]);

  useEffect(() => {
    dispatch(getAllBranch());
  }, []);

  useEffect(() => {
    dispatch(GetClassServer({ b_id: b_data._id }));
    dispatch(getStudentData({ b_id: b_data._id }));
    dispatch(
      CreateStudentForm({
        b_id: b_data._id,
        session_year: c_session.session_year,
      })
    );
    dispatch(getImdageData({ b_id: b_data._id }));
  }, [b_data]);

  useEffect(() => {
    if (card_data.length > 0) {
      let g_data = { id: "", idCardFront: "", idCardBack: "", marksheet: "" };

      card_data.map((data) => {
        if (data.type === "idCard") {
          g_data.id = data._id;
          for (let index = 0; index < data.file.length; index++) {
            const f = data.file[index];
            console.log(f);
            if (f.type === "idCardFront") {
              g_data.idCardFront = f.path;
            } else {
              g_data.idCardBack = f.path;
              // setGetData({ ...getData, [f.type]: f.path });
            }
          }

          setGetData(g_data);
        } else {
          // g_data.marksheet = data.file[0].path;
        }
      });
    }
  }, [card_data]);

  useEffect(() => {
    createFormData &&
      setVisibleFields(
        createFormData.fields.map((f, i) => {
          const modifiedValue = f.name;
          return modifiedValue;
        })
      );
  }, [createFormData]);

  // console.log("visible f - ", visibleField);

  const [idCardModal, setIdCardModal] = useState(false);

  const openIdModal = (obj) => {
    setIdCardModal(!idCardModal);
    setSelectedObject(obj);
  };

  const [selectdObject, setSelectedObject] = useState(null);

  const [filter, setFilter] = useState("all student");

  const genereatePDF = useReactToPrint({
    content: () => pdfRef.current,
    documentTitle: "Student Data",
  });

  const exportExcelFile = () => {
    const workbook = new excelJS.Workbook();
    let worksheet = workbook.addWorksheet("Students List");
    worksheet.properties.defaultRowHeight = 20;

    let colums = [
      {
        header: "Sr.No",
        key: "sr",
        width: 10,
      },
      {
        header: "Course",
        key: "course",
        width: 10,
      },
    ];

    createFormData &&
      createFormData.fields.map((f, i) => {
        const modifiedValue = f.name.replace(/enter|choose|select/gi, "");
        visibleField.some((vis_f) => {
          return vis_f === f.name;
        }) &&
          colums.push({
            header: modifiedValue,
            key: modifiedValue,
            width: 10,
          });
      });

    worksheet.columns = colums;

    student_list?.map((stu, i) => {
      let rowData = { sr: stu.sr_no, course: stu.class_id.c_name };

      stu.fields.map((f) => {
        const modifiedValue = f.name.replace(/enter|choose|select/gi, "");
        visibleField.some((vis_f) => {
          return vis_f === f.name;
        }) && (rowData[modifiedValue] = f.value);
      });

      worksheet.addRow(rowData);
    });

    workbook.xlsx.writeBuffer().then((data) => {
      let url = window.URL.createObjectURL(
        new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        })
      );
      const a = document.createElement("a");
      a.href = url;
      a.download = "Students List.xlsx";
      a.click();
      window.URL.revokeObjectURL(url);
    });
  };

  const onchangeShowField = (e, f_name) => {
    const { name, value, checked } = e.target;
    if (checked) {
      setVisibleFields((prevFields) => [...prevFields, f_name]);
    } else {
      setVisibleFields((prevFields) => prevFields.filter((f) => f !== f_name));
    }
  };

  // check is url
  const isUrl = (str) => {
    try {
      new URL(str);
      return true;
    } catch (error) {
      return false;
    }
  };

  // extra column setup
  const [extra_column, setExtraColumn] = useState(0);
  const [class_filter_id, set_class_filter_id] = useState("all");

  const [fil_hostel, set_fil_hostel] = useState(null);

  const [filters, setFilters] = useState([
    { type: "hostel", condition: "equal", value: null },
    { type: "class", condition: "equal", value: "all" },
    { type: "field", condition: "includes", value: null },
  ]);

  console.log("filter - ", filters);
  const student_filter_function = (stu) => {
    // Iterate over each filter
    for (const filter of filters) {
      // return applyFilter(stu, filter);
      if (!applyFilter(stu, filter)) {
        // If any filter returns false, the student does not pass all filters
        return false;
      }
    }
    // If all filters pass, return true
    return true;
  };

  const onFilter = (name, value) => {
    const hostelFilterIndex = filters.findIndex(
      (filter) => filter.type === name
    );
    // If the hostel filter exists in the array
    if (hostelFilterIndex !== -1) {
      // Create a copy of the filters array
      const updatedFilters = [...filters];
      // Update the value of the hostel filter at the found index

      updatedFilters[hostelFilterIndex] = {
        ...updatedFilters[hostelFilterIndex],
        value: value === "null" || value === null ? null : value,
      };

      // Set the updated filters state
      setFilters(updatedFilters);
    }
  };

  const applyFilter = (stu, filter) => {
    const { type, condition, value } = filter;

    switch (type) {
      case "hostel":
        if (condition === "equal") {
          if (value !== null) {
            return stu.hostel === value;
          } else {
            return true;
          }
        }
        break;
      case "class":
        
        if (condition === "equal") {
          if (value !== "all") {
            return stu.class_id._id.toString() === value;
          } else {
            return true;
          }
        }
        break;
      case "field":
        if (condition === "includes") {
          if (value === null) {
            return true;
          }
          return stu.fields.some(
            (field) => field.name === value && field.value.includes(query)
          );
        }
        break;
      default:
        return true; // Default to true if filter type is not recognized
    }
  };

  return (
    <>
      {idCardModal && (
        <IdCardPreview
          obj={selectdObject}
          imageData={getData}
          openmodal={openIdModal}
          allImageData={card_data}
        />
      )}
      <div className="content-wrapper payments">
        <div className="container-fluid flex-grow-1 container-p-y">
          <div className="row">
            <div className="col-lg-12 mb-4 ">
              <div className="row">
                <div className="col-12 col-lg-6 font-bold d-flex">
                  <ul className="slider_button slider-white plain-orange me-2">
                    <li
                      className={` me-2 ${
                        filter.includes("all student") ? "slider-active" : ""
                      }`}
                      onClick={() => {
                        setFilter("all student");
                      }}
                    >
                      All Student
                    </li>
                    <li
                      className={` me-2 ${
                        filter.includes("add student") ? "slider-active" : ""
                      }`}
                      onClick={() => {
                        history("/admin/create_student");
                      }}
                    >
                      + Add Student
                    </li>

                    <li
                      className={` me-2 ${
                        filter.includes("crosslist") ? "slider-active" : ""
                      }`}
                      onClick={() => {
                        setFilter("crosslist");
                      }}
                    >
                      Cross List
                    </li>
                  </ul>
                </div>
                <div className="col-12 col-lg-6 mt-4 mt-lg-0 d-flex justify-content-between">
                  {filter.includes("all student") && (
                    <>
                      <div class="input-group me-3">
                        <input
                          type="text"
                          class="form-control"
                          name=""
                          id=""
                          aria-describedby="helpId"
                          placeholder="Search Student by sr no, class ..."
                          onChange={(e) => {
                            setQuery(e.target.value);
                          }}
                        />
                        <select
                          class="input-group-text w-50"
                          id="basic-addon2"
                          onChange={(e) => {
                            // set_filter_on(e.target.value);
                            onFilter("field", e.target.value);
                          }}
                        >
                          <option value={"null"}>Select filter</option>
                          {createFormData &&
                            createFormData.fields.map((f, i) => {
                              const modifiedValue = f.f_label.replace(
                                /enter|choose|select/gi,
                                ""
                              );

                              return (
                                <>
                                  <option value={f.name}>
                                    {modifiedValue}
                                  </option>
                                </>
                              );
                            })}
                        </select>
                      </div>
                      <div class="form-group w-100 me-3 d-none">
                        <input
                          type="text"
                          class="form-control"
                          name=""
                          id=""
                          aria-describedby="helpId"
                          placeholder="Search Student by sr no, class ..."
                          onChange={(e) => {
                            setQuery(e.target.value);
                          }}
                        />
                        <select className="form-control">
                          <option>Choose filter type</option>
                        </select>
                      </div>
                      <button
                        className="btn btn-primary me-3"
                        onClick={() => {
                          exportExcelFile();
                        }}
                      >
                        Excel
                      </button>
                      <button
                        className="btn btn-primary"
                        onClick={() => {
                          genereatePDF();
                        }}
                      >
                        PDF
                      </button>
                    </>
                  )}
                </div>
              </div>

              {filter.includes("all student") ? (
                <div className="row mt-1" style={{ textAlign: "left" }}>
                  <div className="col-12">
                    <div
                      className="col-12 col-lg-12 mt-3 d-flex align-items-center"
                      style={{ fontSize: "11px" }}
                    >
                      <span>Extra Column - </span>
                      <div className="mx-2 me-4">
                        <div class="form-group">
                          <input
                            type="number"
                            class="form-control"
                            name="extra_column"
                            id=""
                            aria-describedby="helpId"
                            placeholder="0"
                            onChange={(e) => {
                              setExtraColumn(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                      <span className="me-2">Class Filter - </span>
                      <div class="form-group me-4">
                        <select
                          class="form-control"
                          name="class_filter"
                          id=""
                          onChange={(e) => {
                            set_class_filter_id(e.target.value);
                            onFilter("class", e.target.value);
                          }}
                        >
                          <option value={"all"}>All Class</option>
                          {class_data &&
                            class_data.map((c, i) => {
                              return (
                                <option key={i} value={c._id}>
                                  {c.c_name}
                                </option>
                              );
                            })}
                        </select>
                      </div>
                      <span className="me-2">Hostel Filter - </span>
                      <span
                        className="badge bg-secondary"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          set_fil_hostel(null);
                          onFilter("hostel", null);
                        }}
                      >
                        <i className="fa fa-close"></i>
                      </span>
                      <input
                        type="radio"
                        name="hostel_filter"
                        className="mx-2"
                        defaultChecked={
                          fil_hostel !== null ? fil_hostel : false
                        }
                        onChange={(e) => {
                          set_fil_hostel(true);
                          // Find the index of the hostel filter in the filters array
                          onFilter("hostel", true);
                        }}
                      />
                      {"Yes"}

                      <input
                        type="radio"
                        name="hostel_filter"
                        className="mx-2"
                        defaultChecked={
                          fil_hostel !== null ? !fil_hostel : false
                        }
                        onChange={(e) => {
                          set_fil_hostel(false);
                          onFilter("hostel", false);
                        }}
                      />
                      {"No"}
                    </div>
                    <div
                      className="col-12 col-lg-12 mt-3 d-flex flex-wrap align-items-center"
                      style={{ fontSize: "11px" }}
                    >
                      <span
                        data-toggle="tooltip"
                        data-html="true"
                        data-placement="top"
                        title="Clear Fields"
                        className="badge bg-danger me-2"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setVisibleFields([]);
                        }}
                      >
                        <i className="fa fa-close"></i>
                      </span>
                      <span className="me-2"> Visible Fields :</span>{" "}
                      {createFormData &&
                        createFormData.fields.map((f, i) => {
                          const modifiedValue = f.f_label.replace(
                            /enter|choose|select/gi,
                            ""
                          );

                          return (
                            <>
                              <div className="d-flex align-items-center mb-2">
                                <input
                                  type="checkbox"
                                  id={modifiedValue}
                                  autocomplete="off"
                                  checked={visibleField.some((vis_f, i) => {
                                    return vis_f === f.name;
                                  })}
                                  name={modifiedValue}
                                  value={modifiedValue}
                                  onChange={(e) => {
                                    onchangeShowField(e, f.name);
                                  }}
                                />
                                <label
                                  className="mx-2 "
                                  style={{
                                    fontSize: "12px",
                                    "text-wrap": "nowrap",
                                  }}
                                >
                                  {modifiedValue}
                                </label>
                              </div>
                            </>
                          );
                        })}
                    </div>
                  </div>

                  <div className="col-12 mx-auto">
                    <div
                      ref={pdfRef}
                      style={{ width: "100%" }}
                      class="table-responsive text-nowrap mb-1"
                    >
                      <div
                        className="header-print"
                        style={{
                          backgroundImage: `url(${
                            b_data.config &&
                            b_data.config.length > 0 &&
                            b_data.config[0].header
                          })`,
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "cover",
                          minHeight: 150,
                          backgroundPosition: "center",
                          position: "relative",
                          textAlign: "center",
                          padding: "20px",
                        }}
                      ></div>

                      <table
                        class="table table-hover"
                        style={{ textAlign: "left", minHeight: "100%" }}
                      >
                        <thead className="order_history_header">
                          <tr>
                            <th>Sr No.</th>
                            <th>Course</th>
                            <th>Hostel</th>

                            {createFormData &&
                              createFormData.fields.map((f, i) => {
                                const modifiedValue = f.f_label.replace(
                                  /enter|choose|select/gi,
                                  ""
                                );

                                const match = visibleField.some((visf) => {
                                  return visf === f.name;
                                });

                                return <>{match && <th>{modifiedValue}</th>}</>;
                              })}

                            {Array.from({ length: extra_column }, (_, i) => {
                              return <th>Column {i + 1}</th>;
                            })}

                            <th className="action hiddenOnPrint">Action</th>
                          </tr>
                        </thead>
                        <tbody class="table-border-bottom-0 order_history_body">
                          {student_list
                            .filter(student_filter_function)
                            .map((stu, i) => {
                              console.log("stu - ", stu);
                              return (
                                <>
                                  <tr key={i}>
                                    <td>{stu.sr_no}</td>
                                    <td>{stu.class_id.c_name}</td>
                                    <td>{stu.hostel ? "✅" : "❌"}</td>

                                    {stu.fields
                                      // .filter((f) =>
                                      //   f.value
                                      //     .toString()
                                      //     .toLowerCase()
                                      //     .includes(query)
                                      // )
                                      .map((f, j) => {
                                        // console.log("f data - ", f);
                                        const match = visibleField.some(
                                          (vis_f) => {
                                            return vis_f === f.name;
                                          }
                                        );

                                        return (
                                          <>
                                            {match && (
                                              <td key={j}>
                                                {isUrl(f.value) ? (
                                                  <img
                                                    src={f.value}
                                                    className="img-fluid "
                                                    width={25}
                                                    height={25}
                                                    alt={f.name}
                                                  />
                                                ) : (
                                                  f.value
                                                )}
                                              </td>
                                            )}
                                          </>
                                        );
                                      })}

                                    {Array.from(
                                      { length: extra_column },
                                      (_, i) => {
                                        return <td></td>;
                                      }
                                    )}

                                    <td className="action hiddenOnPrint">
                                      {/* <Link
                                        to={{
                                          pathname:
                                            "/admin/all_students/print_form",
                                          state: { data: stu },
                                        }}
                                      > */}
                                      <span
                                        className="badge bg-primary me-1"
                                        data-toggle="tooltip"
                                        data-html="true"
                                        data-placement="top"
                                        title="Print Form"
                                        style={{ cursor: "pointer" }}
                                        onClick={() => {
                                          dispatch(print_data_set(stu));
                                          history(
                                            "/admin/all_students/print_form"
                                          );
                                        }}
                                      >
                                        <i className="fa fa-print"></i>
                                      </span>
                                      {/* </Link> */}

                                      <span
                                        className="badge bg-warning me-1"
                                        data-toggle="tooltip"
                                        data-html="true"
                                        data-placement="top"
                                        title="Preview Id Card"
                                        style={{ cursor: "pointer" }}
                                        onClick={() => {
                                          openIdModal(stu);
                                        }}
                                      >
                                        <i className="fa fa-id-card"></i>
                                      </span>

                                      <span
                                        className="badge bg-secondary me-1"
                                        data-toggle="tooltip"
                                        data-html="true"
                                        data-placement="top"
                                        title="Edit Student"
                                        style={{ cursor: "pointer" }}
                                        onClick={() => {
                                          history("/admin/student/" + stu._id);
                                        }}
                                      >
                                        <i className="fa fa-edit"></i>
                                      </span>

                                      <span
                                        className="badge bg-danger"
                                        data-toggle="tooltip"
                                        data-html="true"
                                        data-placement="top"
                                        title="Delete Student"
                                        style={{ cursor: "pointer" }}
                                        onClick={async () => {
                                          dispatch(DeleteStudents(stu._id));
                                        }}
                                      >
                                        <i className="fa fa-trash"></i>
                                      </span>
                                    </td>
                                  </tr>
                                </>
                              );
                            })}
                        </tbody>
                      </table>

                      <div
                        className="header-print"
                        style={{
                          backgroundImage: `url(${
                            b_data.config &&
                            b_data.config.length > 0 &&
                            b_data.config[0].footer
                          })`,
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "cover",
                          minHeight: 120,
                          backgroundPosition: "center",
                          position: "relative",
                        }}
                      ></div>
                    </div>
                  </div>
                </div>
              ) : (
                <CrossList />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AllStudents;
