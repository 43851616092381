import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchDataFromLocal } from "../../../redux/reducer/branchReducer";
import { GetClassServer } from "../../../redux/reducer/class/classReducer";
import "../StudentCss.css";
import ReactToPrint, { useReactToPrint } from "react-to-print";
const CrossList = () => {
  const dispatch = useDispatch();
  const pdfRef = useRef();
  const b_data = useSelector((state) => state.fetchDataFromLocal);
  const class_data = useSelector((state) => state.classData);
  const studentData = useSelector((state) => state.getStudentData);
  const c_session = useSelector((state) => state.currentSession);
  useEffect(() => {
    dispatch(fetchDataFromLocal());
  }, []);

  useEffect(() => {
    if (b_data._id !== undefined) {
      dispatch(GetClassServer({ b_id: b_data._id }));
    }
  }, [b_data]);

  const [matchStudent, setMatchStudents] = useState([]);

  // filter students
  const onClassSelecte = (e) => {
    const class_id = e.target.value;
    const new_student_list = studentData.filter((clas) => {
      return clas.class_id._id.toString() === class_id;
    });

    setMatchStudents(new_student_list);
  };

  let sub_names = [];


  // print Pdf 
  const genereatePDF = useReactToPrint({
    content: () => pdfRef.current,
    documentTitle: "Student Data",
  });

  return (
    <div className="row mt-4" style={{ textAlign: "left" }}>
      <div className="col-12 mx-auto">
        {/* <h5>Under Construction</h5> */}
        <div className="card">
          <div className="card-header">
            <div className="row">
              <div className="col-10">
                <h6 className="card-title">Cross List</h6>
                <div class="form-group">
                  <select
                    class="form-control"
                    name="class_id"
                    onChange={onClassSelecte}
                  >
                    <option selected>Choose Class</option>
                    {class_data.map((clas, i) => {
                      return <option value={clas._id}>{clas.c_name}</option>;
                    })}
                  </select>
                </div>
              </div>

              <div
                className="col-2 d-flex  align-items-center"
                style={{ justifyContent: "flex-end" }}
              >
                <button className="btn btn-primary" onClick={genereatePDF}>
                  <i className="fa fa-print"></i>
                </button>
              </div>
            </div>
          </div>

          <div className="card-body" ref={pdfRef}>
            <div class="table-responsive text-nowrap mb-1">
              <table class="table table-hover" style={{ textAlign: "left" }}>
                <thead className="order_history_header">
                  <tr>
                    <th colSpan={3}>
                      Class -{" "}
                      {matchStudent.length > 0
                        ? matchStudent[0].class_id.c_name
                        : ""}
                    </th>
                    <th
                      colSpan={
                        matchStudent.length > 0
                          ? matchStudent[0].class_id.subjects.length + 1
                          : 1
                      }
                    >
                      Marks Details
                    </th>
                    <th colSpan={2}>Session - {c_session.session_year}</th>
                  </tr>
                  <tr>
                    <th>Sr. No.</th>
                    <th>Roll No.</th>
                    <th>Student Details</th>
                    <th>Exam</th>
                    {matchStudent.length > 0 &&
                      matchStudent[0].class_id.subjects.map((sub, i) => {
                        sub_names.push(sub.sub_name);
                        return (
                          <th key={i} className="sub_th">
                            <div className="v_text">{sub.sub_name}</div>
                          </th>
                        );
                      })}
                    <td className="cross_fields">Total</td>
                    <td className="cross_fields">Result</td>
                  </tr>
                </thead>
                <tbody class="table-border-bottom-0 order_history_body">
                  {matchStudent.map((student, i) => {
                    let total_sub = [];
                    const exam_count = student.class_id.exam_data.length;

                    const e_entry = student.exam_entry.filter((exam) => {
                      return exam.class_id.toString() === student.class_id._id;
                    });

                    const exam_entry = e_entry.length > 0 && e_entry[0].entry;
                    let t_subject = [];
                    let totalAllExam = 0;

                    console.log("total sub - ", total_sub);
                    return (
                      <>
                        <tr>
                          <td rowSpan={exam_count + 2}>{student.sr_no}</td>
                          <td rowSpan={exam_count + 2}>Roll no</td>
                          <td rowSpan={exam_count + 2}>
                            <div className="d-flex flex-column cross_fields">
                              {student.fields.map((f, i) => {
                                return i < 3 ? (
                                  <span key={i}>
                                    {f.name} - <b>{f.value}</b>
                                  </span>
                                ) : (
                                  <></>
                                );
                              })}
                            </div>
                          </td>
                          {/* <td>
                            {exam_count > 0 &&
                              student.class_id.exam_data[i].e_label}
                          </td> */}
                        </tr>

                        {Array.from({ length: exam_count }).map((_, j) => {
                          const label = student.class_id.exam_data[j].e_label;
                          let total_hori = 0;
                          return (
                            <tr>
                              <td className="cross_fields">{label}</td>
                              {exam_entry.length > 0
                                ? exam_entry
                                    .filter((ex) => {
                                      return sub_names.some((su) => {
                                        return su === ex.sub.sub_name;
                                      });
                                    })
                                    .map((ex, i) => {
                                      const marks = ex[label]
                                        ? parseFloat(ex[label])
                                        : 0;

                                      total_hori += marks;

                                      if (total_sub.length === 0) {
                                        total_sub.push({
                                          sub_name: ex.sub.sub_name,
                                          total: marks,
                                          label,
                                        });
                                      } else {
                                        const match = total_sub.some((s) => {
                                          return s.sub_name === ex.sub.sub_name;
                                        });

                                        if (match) {
                                          total_sub = total_sub.map((s) => {
                                            return s.sub_name ===
                                              ex.sub.sub_name
                                              ? {
                                                  ...s,
                                                  total: marks + s.total,
                                                }
                                              : s;
                                          });
                                        } else {
                                          total_sub.push({
                                            sub_name: ex.sub.sub_name,
                                            total: marks,
                                            label,
                                          });
                                        }
                                      }
                                      return (
                                        <td
                                          key={i}
                                          className="cross_fields text-center"
                                        >
                                          {ex[label] ? ex[label] : "-"}
                                        </td>
                                      );
                                    })
                                : student.class_id.subjects.map((s, i) => {
                                    return (
                                      <td
                                        className="cross_fields text-center"
                                        key={i}
                                      >
                                        {"-"}
                                      </td>
                                    );
                                  })}

                              {Array.from({
                                length:
                                  student.class_id.subjects.length -
                                  exam_entry.length,
                              }).map((_, j) => {
                                return (
                                  <td
                                    key={j}
                                    className="cross_fields text-center"
                                  >
                                    {"-"}
                                  </td>
                                );
                              })}

                              <td className="cross_fields text-center">
                                {total_hori}
                              </td>
                            </tr>
                          );
                        })}

                        <tr>
                          <td className="cross_fields">Total</td>
                          {total_sub.map((s) => {
                            totalAllExam += s.total;
                            return (
                              <td className="cross_fields text-center">
                                {s.total}
                              </td>
                            );
                          })}

                          {Array.from({
                            length:
                              student.class_id.subjects.length -
                              exam_entry.length,
                          }).map((_, j) => {
                            return (
                              <td className="cross_fields text-center" key={j}>
                                {"-"}
                              </td>
                            );
                          })}

                          <td className="cross_fields text-center">
                            <b>{totalAllExam}</b>
                          </td>
                          <td className="cross_fields">Result</td>
                        </tr>
                      </>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CrossList;
