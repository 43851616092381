import React, { useEffect, useState } from "react";

import { Link, useNavigate, useParams } from "react-router-dom";
import { dateDiffrent } from "../globalFunction/CheckDateDiffer";

function Login(props) {
  const host = process.env.REACT_APP_URL;
  // state intilize here
  const [forgetPopup, setForgoetpopup] = useState(false);
  const params = useParams();
  const [loginBody, setLoginBody] = useState({
    phone: "",
    pass: "",
  });

  const enterInField = (e) => {
    console.log(e.target.name);
    console.log(e.target.value);
    setLoginBody({ ...loginBody, [e.target.name]: e.target.value });
  };

  const navigation = useNavigate();

  useEffect(() => {
    console.log(localStorage.getItem("token"));

    if (
      localStorage.getItem("token") !== null &&
      localStorage.getItem("token") !== ""
    ) {
      if (params.path === "checkout") {
        navigation("/#pricing");
      } else {
        navigation("/admin");
      }
    }
  }, []);

  // close forget pass
  const closeForget = () => {
    setForgoetpopup(!forgetPopup);
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    // props.preLoader("preShow");

    const url = host + "/api/user/signin";
    console.log(url);

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // "auth-token": token,
      },
      body: JSON.stringify(loginBody),
    };

    try {
      const res = await fetch(url, requestOptions);
      // console.log(res);
      const datares = await res.json();
      console.log(datares);
      // props.preLoader("");
      if (datares.status === "Success") {
        const admindata = datares.data;
        if (params.path === "checkout") {
          localStorage.setItem("token", datares.token);
          localStorage.setItem("admin_data", JSON.stringify(datares.data));
          window.location.reload();
        } else {
          if (admindata.free_trial) {
            const days = dateDiffrent(admindata.createdAt);
            if (days > 7) {
              alert("Your free trial is expired - " + days);
              navigation("/");
            } else {
              localStorage.setItem("token", datares.token);
              localStorage.setItem("admin_data", JSON.stringify(datares.data));
              window.location.reload();
            }
          } else {
            if (admindata.subscribe_id) {
              const days = dateDiffrent(admindata.subs_at);
              if (
                (admindata.subscribe_id.duration === "1 month" && days > 30) ||
                (admindata.subscribe_id.duration === "1 Year" && days > 365)
              ) {
                alert("Your current plan is expired - plan - " + days);
                navigation("/");
              } else {
                localStorage.setItem("token", datares.token);
                localStorage.setItem(
                  "admin_data",
                  JSON.stringify(datares.data)
                );
                window.location.reload();
              }
            } else {
              console.log(admindata);
              alert("Your free trial is expired - " + admindata.free_trial);
              navigation("/");
            }
          }
        }
      } else {
        alert(datares.message);
      }
    } catch (error) {
      console.log(error);
      console.log(error.message);
    }
  };

  const [forgetData, setForgetData] = useState({
    usernmae: "",
    adminkey: "",
    password: "",
  });
  const forgetChange = (e) => {
    setForgetData({ ...forgetData, [e.target.name]: e.target.value });
  };
  const forgetPass = async (e) => {
    e.preventDefault();

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // "auth-token": token,
      },
      body: JSON.stringify(forgetData),
    };

    try {
      const res = await fetch(`${host}/api/login/changepass`, requestOptions);
      const datares = await res.json();

      if (datares.status === "Success") {
        closeForget();
        alert("Password has been changed");
      } else {
        alert(datares.message);
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      {forgetPopup && (
        <div
          className="modal fade show"
          id="exampleModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          style={{ display: "block", background: "#edededb5" }}
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content" style={{ border: "none" }}>
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Forget Password
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={closeForget}
                  style={{
                    border: "none",
                    padding: "0 5px",
                    borderRadius: "20px",
                  }}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body text-left">
                <label htmlFor="phone">Enter phone</label>
                <input
                  type="text"
                  className="form-control mb-2"
                  name="phone"
                  placeholder="Enter phone"
                  onChange={forgetChange}
                />

                <label htmlFor="adminkey">Enter Admin Key</label>
                <input
                  type="text"
                  className="form-control mb-2"
                  name="adminkey"
                  placeholder="Enter admin key"
                  onChange={forgetChange}
                />

                <label htmlFor="password">Enter Password</label>
                <input
                  type="pasword"
                  className="form-control mb-2"
                  name="password"
                  placeholder="Enter password"
                  onChange={forgetChange}
                />
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                  onClick={closeForget}
                >
                  Close
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={forgetPass}
                >
                  Change
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="account-login section">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-12 d-none d-lg-block">
              <img
                src="../assets/images/about/login.jpg"
                alt="login_illustration"
              />
            </div>

            <div className="col-lg-6  col-md-10  col-12 d-flex justify-content-center">
              <form
                className="card  login-form"
                method="post"
                onSubmit={onSubmit}
              >
                <div className="card-body">
                  <div className="title">
                    <h3>Welcome! IVIDHAN</h3>
                  </div>

                  <div className="form-group input-group">
                    <label htmlFor="reg-fn">Phone</label>
                    <input
                      className="form-control"
                      type="text"
                      id="reg-email"
                      name="phone"
                      required={true}
                      onChange={enterInField}
                    />
                  </div>
                  <div className="form-group input-group">
                    <label htmlFor="reg-fn">Password</label>
                    <input
                      className="form-control"
                      type="password"
                      id="reg-pass"
                      name="pass"
                      required={true}
                      onChange={enterInField}
                    />
                  </div>
                  <div className="d-flex flex-wrap justify-content-between bottom-content">
                    <div className="form-check">
                      <input
                        type="checkbox"
                        className="form-check-input width-auto"
                        id="exampleCheck1"
                      />
                      <label className="form-check-label">Remember me</label>
                    </div>
                    <p
                      className="lost-pass"
                      onClick={() => {
                        closeForget();
                      }}
                    >
                      Forgot password?
                    </p>
                  </div>
                  <div className="button">
                    <button className="btn" type="submit">
                      Login
                    </button>
                  </div>

                  <div className="alt-option">
                    <span>Or</span>
                  </div>

                  {/* <a href=""><span>Create an account</span></a> */}

                  <p className="outer-link">
                    Don't have an account?
                    <Link to="./register"> Register here </Link>
                  </p>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;
