import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import branchCon from "../../context/Branch/BranchCon";
import { useDispatch, useSelector } from "react-redux";
import { localActiveBranch } from "../../redux/reducer/branchReducer";
import { ADDSession, DeleteBranch, getAllBranch, setActiveSession } from "../../redux/reducer/branchState";
require("./Branch.css");
const Branch = (props) => {
  const [query, setQuery] = useState("");
  const history = useNavigate();
  const dispatch = useDispatch();

  const branchS_State = useSelector((state) => state.branchArrayState);
  

  

  useEffect(() => {
    dispatch(getAllBranch());
  }, []);

  // session Add Data
  const style = {
    background: "#00000080",
    display: "block",
  };
  const [SessionModal, setSessionModal] = useState(false);

  const [sessionYears, setSessionYears] = useState([]);
  useEffect(() => {
    const generateSessionYears = () => {
      const currentYear = new Date().getFullYear() - 5;
      const nextYears = 6; // You can adjust this number based on how many years you want to include

      const years = Array.from({ length: nextYears }, (_, index) => {
        const startYear = currentYear + index;
        const endYear = startYear + 1;
        return `${startYear}-${endYear.toString().slice(2)}`;
      });

      setSessionYears(years);
    };

    generateSessionYears();
  }, []);

  const [sessionAddData, setSessionAddData] = useState({ name: "", year: "" });

  const [selectedId, setSelectedId] = useState("");

  const onSessionInput = (e) => {
    setSessionAddData({ ...sessionAddData, [e.target.name]: e.target.value });
  };

  const openAddModal = (id = "") => {
    setSessionModal(!SessionModal);
    setSelectedId(id);
  };
  const updateCall = async (e) => {
    e.preventDefault();

    dispatch(ADDSession("session_add", selectedId, sessionAddData, ()=>{
      openAddModal("");
    }));

  };

  const setActiveSesstion = async (id, s_id) => {


    dispatch(setActiveSession(id, s_id));
    // const res = await activeSession(id, s_id);
  };

  const setActiveBranch = async (obj) => {
    if (obj.sessions.length !== 0) {
      dispatch(localActiveBranch(obj));
    }
  };

  return (
    <>
      {SessionModal && (
        <div className="modal reviewModal" style={style}>
          <div className="modal-dialog session_dialog" >
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Add Session
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={openAddModal}
                ></button>
              </div>
              <div className="modal-body" style={{ textAlign: "left" }}>
                <form onSubmit={updateCall}>
                  <div class="form-group mb-3">
                    <label for="">Session Name</label>
                    <input
                      type="text"
                      class="form-control"
                      name="name"
                      id=""
                      aria-describedby="helpId"
                      placeholder="Enter session name"
                      onChange={onSessionInput}
                    />
                  </div>

                  <div className=" form-group">
                    <label htmlFor="recipient-name" className="text-left">
                      Choose Session Year
                    </label>

                    <select
                      className="form-control"
                      name="year"
                      onChange={onSessionInput}
                    >
                      <option>Choose Session Year</option>
                      {sessionYears.map((session, index) => (
                        <option key={index} value={session}>
                          {session}
                        </option>
                      ))}
                    </select>
                  </div>
                </form>
              </div>
              <div className="modal-footer">
                <button
                  type="submit"
                  className="btn btn-primary orange-btn me-2 mb-2"
                  onClick={updateCall}
                >
                  Add
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="content-wrapper payments">
        <div className="container-fluid flex-grow-1 container-p-y">
          <div className="row">
            <div className="col-lg-12 mb-4 ">
              <div className="row">
                <div className="col-lg-6 font-bold mb-2 ">
                  <ul className="slider_button slider-white plain-orange">
                    <li
                      className="slider-active me-2"
                      onClick={() => {
                        history("/admin/addNewBranch");
                      }}
                    >
                      + Add Branch
                    </li>
                  </ul>
                </div>
                <div className="col-lg-6 text-right">
                  <input
                    type="text"
                    className="form-control search"
                    placeholder="Search state..."
                    onChange={(e) => {
                      setQuery(e.target.value);
                    }}
                  />
                </div>
              </div>

              <div className="row mt-4" style={{ textAlign: "left" }}>
                {branchS_State.filter((branch)=>{
                  return branch.branch_name.toLowerCase().includes(query.toLowerCase())
                })
                .map((branch, i) => {
                  return (
                    <>
                      <div className="col-12 col-lg-6 branch_card mt-4">
                        <div className="card pt-4 pt-lg-0">
                          <h5
                            className="card-header mt-4"
                            style={{ color: "black", fontWeight: "bold" }}
                          >
                            {branch.branch_name}
                          </h5>
                          <div className="card-body">
                            <div className="row">
                              <div className="col-3 col-lg-4">
                                <img
                                  src="https://img.freepik.com/free-vector/bird-colorful-logo-gradient-vector_343694-1365.jpg?size=338&ext=jpg&ga=GA1.1.1412446893.1704585600&semt=ais"
                                  alt="branch logo"
                                  className="img-fluid"
                                />
                              </div>
                              <div className="col-9 col-lg-8">
                                <ul className="branch-data-list">
                                  <li>Type - {branch.b_type}</li>
                                  <li>
                                    Address - {branch.address} {branch.state} (
                                    {branch.zipcode})
                                  </li>
                                  <li>
                                    Contact -{" "}
                                    {branch.contact_details.length === 0 ? (
                                      "N/A"
                                    ) : (
                                      <ul>
                                        <li>
                                          Name -{" "}
                                          {branch.contact_details[0].name}
                                        </li>
                                        <li>
                                          Phone -{" "}
                                          {branch.contact_details[0].phone}
                                        </li>

                                        <li>
                                          Email -{" "}
                                          {branch.contact_details[0].email}
                                        </li>
                                      </ul>
                                    )}
                                  </li>
                                  <li className="d-flex">
                                    Sessions -{" "}
                                    {branch.sessions.length === 0 && (
                                      <>
                                        <span
                                          className="badge bg-success mx-2"
                                          style={{ cursor: "pointer" }}
                                          onClick={() => {
                                            openAddModal(branch._id);
                                          }}
                                        >
                                          Add Session
                                        </span>
                                      </>
                                    )}
                                    <ul className="d-flex  mx-1 m-0 p-0">
                                      {branch.sessions.map((ses, i) => {
                                        return (
                                          <li
                                            className="mx-1 border px-1"
                                            style={
                                              ses.current
                                                ? {
                                                    color: "white",
                                                    background: "red",
                                                    borderRadius: "10px",
                                                    cursor: "pointer",
                                                  }
                                                : {
                                                    borderRadius: "10px",
                                                    cursor: "pointer",
                                                  }
                                            }
                                            onClick={() => {
                                              setActiveSesstion(
                                                branch._id,
                                                ses._id
                                              );
                                            }}
                                          >
                                            {ses.session_year}
                                          </li>
                                        );
                                      })}
                                    </ul>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="option_data">
                          <p
                            className="badge bg-secondary me-1"
                            onClick={() => {
                              setActiveBranch(branch);
                            }}
                          >
                            Set as default
                          </p>

                          <p
                            class="badge bg-warning me-1"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              openAddModal(branch._id);
                              // openEdit("state", user);
                            }}
                          >
                            <i class="fa fa-calendar"></i>
                          </p>
                          <p
                            class="badge bg-success me-1"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              // openEdit("state", user);
                            }}
                          >
                            <i className="fa fa-cog"></i>
                          </p>
                          <p
                            class="badge bg-primary me-1"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              history("../edit_branch/"+branch._id);
                              // openEdit("state", user);
                            }}
                          >
                            <i className="fa fa-edit"></i>
                          </p>

                          <p
                            class="badge bg-danger "
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              // deleteBranch(branch._id);
                              dispatch(DeleteBranch(branch._id))
                              // openEdit("state", user);
                            }}
                          >
                            <i className="fa fa-trash"></i>
                          </p>
                        </div>
                      </div>
                    </>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Branch;
