import React, { useState, useEffect } from "react";
import {
  GetClassServer,
  addTestInClass,
} from "../../redux/reducer/class/classReducer";
import { useDispatch, useSelector } from "react-redux";
import { fetchDataFromLocal } from "../../redux/reducer/branchReducer";
const AddTest = ({ open }) => {
  const style = {
    background: "#00000080",
    display: "block",
  };

  const dispatch = useDispatch();

  const class_data = useSelector((state) => state.classData);
  const b_data = useSelector((state) => state.fetchDataFromLocal);
  const session = useSelector((state) => state.currentSession);

  const [bodyData, setBodyData] = useState({
    session: "",
    test_name: "",
    class_id: "",
    total_marks: "",
    test_type: "",
    test_duration: 1,
    test_time_slot: "",
  });

  // get data
  useEffect(() => {
    if (b_data === null) {
      dispatch(fetchDataFromLocal());
    }
  }, []);

  useEffect(() => {
    if (b_data._id !== undefined) {
      dispatch(GetClassServer({ b_id: b_data._id }));
      setBodyData({ ...bodyData, session: session.session_year });
    }
  }, [b_data]);

  const onChangeSet = (e) => {
    if (e.target.name === "duration") {
      if (parseInt(e.target.value) > 0) {
        setBodyData({ ...bodyData, [e.target.name]: parseInt(e.target.value) });
      }
    } else {
      setBodyData({ ...bodyData, [e.target.name]: e.target.value });
    }
  };

  const addTestToServer = (e) => {
    e.preventDefault();

    console.log("body - ", bodyData);
    dispatch(
      addTestInClass(bodyData, () => {
        open();
      })
    );
  };

  const generateTimeSlots = (duration = 1) => {
    const timeSlots = [];
    const slotsConfig = [
      { start: 0, end: 24, duration: duration }, // Two-hour slot from 00:00 to 02:00
    ];

    slotsConfig.forEach((slot) => {
      for (let i = slot.start; i < slot.end; i += slot.duration) {
        const startTime = `${i.toString().padStart(2, "0")}:00`;
        const endTime = `${(i + slot.duration).toString().padStart(2, "0")}:00`;
        const timeSlot = `${startTime}-${endTime}`;
        timeSlots.push(timeSlot);
      }
    });

    return timeSlots;
  };
  return (
    <>
      <div className="modal reviewModal" style={style}>
        <div className="modal-dialog" style={{ width: "30%" }}>
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Add Test
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={open}
              ></button>
            </div>
            <div className="modal-body" style={{ textAlign: "left" }}>
              <div className="row">
                <div className="col-6">
                  <div class="form-group mb-2">
                    <label for="">Test Name</label>
                    <input
                      required
                      type="text"
                      class="form-control"
                      name="test_name"
                      id=""
                      onChange={onChangeSet}
                      aria-describedby="helpId"
                      placeholder="Enter name"
                    />
                  </div>

                  <div class="form-group mb-2">
                    <label for="">Total Marks</label>
                    <input
                      required
                      type="tel"
                      pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                      minLength={10}
                      class="form-control"
                      name="total_marks"
                      onChange={onChangeSet}
                      id=""
                      aria-describedby="helpId"
                      placeholder="Enter total marks"
                    />
                  </div>
                </div>

                <div className="col-6">
                  <div class="form-group mb-2">
                    <label for="">Choose Class</label>
                    <select
                      placeholder="Choose Class"
                      className="form-control"
                      name="class_id"
                      onChange={onChangeSet}
                    >
                      <option value={""}>Select Class</option>
                      {class_data.map((clas, i) => {
                        return <option value={clas._id}>{clas.c_name}</option>;
                      })}
                      {/* <option value={"class 1"}>class 1</option> */}
                    </select>
                  </div>

                  <div class="form-group mb-2">
                    <label for="">Test Type</label>
                    <select
                      placeholder="Choose Class"
                      className="form-control"
                      name="test_type"
                      onChange={onChangeSet}
                    >
                      <option>Choose Test Type</option>
                      <option value={"online"}>Online</option>
                      <option value={"offline"}>Offline</option>
                    </select>
                  </div>
                </div>

                {bodyData.test_type === "online" && (
                  <div className="col-12">
                    <div className="row">
                      <div className="col-6">
                        <div class="form-group mb-2">
                          <label for="">Time Duration</label>
                          <input
                            required
                            type="number"
                            class="form-control"
                            name="test_duration"
                            onChange={onChangeSet}
                            id=""
                            aria-describedby="helpId"
                            placeholder="Enter duration of test in minutes"
                          />
                        </div>
                      </div>

                      <div className="col-6">
                        <div class="form-group mb-2">
                          <label for="">Time Slot</label>
                          <select
                            className="form-control"
                            name="test_time_slot"
                          >
                            <option>Choose Time Slot</option>
                            {generateTimeSlots(bodyData.duration).map(
                              (slot, index) => (
                                <option key={index} value={slot}>
                                  {slot}
                                </option>
                              )
                            )}
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="modal-footer pt-0">
              <div className="d-flex">
                <button className=" btn btn-secondary me-3" onClick={open}>
                  Cancel
                </button>
                <button
                  className="btn btn-primary"
                  onClick={(e) => {
                    addTestToServer(e);
                  }}
                >
                  Add
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddTest;
