import React, { useRef } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
const PrintTemp = () => {
  //   const { data } = props.location.state;
  const b_data = useSelector((state) => state.fetchDataFromLocal);
  const f_s_data = useSelector((state) => state.set_print_object);

  const pdfRef = useRef();
  const genereatePDF = useReactToPrint({
    content: () => pdfRef.current,
    documentTitle: "Student Data",
  });

  const isUrl = (str) => {
    try {
      new URL(str);
      return true;
    } catch (error) {
      return false;
    }
  };

  return (
    <>
      <div className="content-wrapper mt-5">
        <div className="container">
          <div className="row">
            <div className="col-12 mb-4" style={{ textAlign: "right" }}>
              <span
                className="badge bg-primary"
                style={{ cursor: "pointer" }}
                onClick={genereatePDF}
              >
                <i className="fa fa-print"></i>
              </span>
            </div>

            <div
              className="col-lg-12 p-2 "
              style={{ background: "white" }}
              ref={pdfRef}
            >
              <div
                className="header-print1"
                style={{
                  backgroundImage: `url(${
                    b_data.config &&
                    b_data.config.length > 0 &&
                    b_data.config[0].header
                  })`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "contain",
                  minHeight: 180,
                  backgroundPosition: "center",
                  position: "relative",
                }}
              ></div>

              <div className="formData">
                <p className="ack_title">Aknowledge Print</p>

                <table width={"100%"} className="table table-hover">
                  <tbody>
                    {f_s_data.fields &&
                      f_s_data.fields.map((f, index) => {
                        // Check if the current index is divisible by 3 and if there are corresponding next elements
                        if (
                          index % 3 === 0 &&
                          index + 2 < f_s_data.fields.length
                        ) {
                          return (
                            <tr key={index}>
                              <th>{f.name}</th>
                              <td>
                                {isUrl(f.value) ? (
                                  <img
                                    src={f.value}
                                    className="img-fluid"
                                    alt={f.value}
                                    width={50}
                                  />
                                ) : (
                                  f.value.toString()
                                )}
                              </td>
                              <th>{f_s_data.fields[index + 1].name}</th>
                              <td>
                                {isUrl(f_s_data.fields[index + 1].value) ? (
                                  <img
                                    src={f_s_data.fields[index + 1].value}
                                    className="img-fluid"
                                    alt={f_s_data.fields[index + 1].value}
                                  />
                                ) : (
                                  f_s_data.fields[index + 1].value.toString()
                                )}
                              </td>
                              <th>{f_s_data.fields[index + 2].name}</th>
                              <td>
                                {isUrl(f_s_data.fields[index + 2].value) ? (
                                  <img
                                    src={f_s_data.fields[index + 2].value}
                                    className="img-fluid"
                                    width={50}
                                    alt={f_s_data.fields[index + 2].value}
                                  />
                                ) : (
                                  f_s_data.fields[index + 2].value.toString()
                                )}
                              </td>
                            </tr>
                          );
                        } else if (index === f_s_data.fields.length - 1) {
                          // For leftover elements
                          return (
                            <tr key={index}>
                              <th>{f.name}</th>
                              <td>
                                {isUrl(f.value) ? (
                                  <img
                                    src={f.value}
                                    className="img-fluid"
                                    width={50}
                                    alt={f.value}
                                  />
                                ) : (
                                  f.value.toString()
                                )}
                              </td>
                              <td></td> {/* Empty cell for leftover elements */}
                              <td></td> {/* Empty cell for leftover elements */}
                              <td></td> {/* Empty cell for leftover elements */}
                              <td></td> {/* Empty cell for leftover elements */}
                            </tr>
                          );
                        } else {
                          return null; // Skip elements not forming a complete row
                        }
                      })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PrintTemp;
