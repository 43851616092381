import React, { useContext, useEffect, useRef, useState } from "react";
import {
  ClassCon,
  StudentFormCon,
  StudentsCon,
} from "../../context/AllContext";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { addStudents } from "../../redux/reducer/studentReducer";

import { showMyAlert } from "../../redux/action";
const CreateStudent = (props) => {
  const context = useContext(StudentFormCon);
  const history = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();
  const b_data = useSelector((state) => state.fetchDataFromLocal);
  const c_session = useSelector((state) => state.currentSession);
  const class_data = useSelector((state) => state.classData);
  const set_login_user_data = useSelector((state) => state.set_login_user_data);
  const students = useSelector((state) => state.getStudentData);

  const classContext = useContext(ClassCon);

  const studentContext = useContext(StudentsCon);

  const { udpateStudent } = studentContext;

  const [editData, setEditData] = useState(null);

  let id = "";
  if (props.edit) {
    id = params.id;
    // setEditStatus(true)
  } else {
    // setEditStatus(false)
  }

  const { clasData, getClass } = classContext;

  const { getFormForCreateStudent, registerationForm } = context;
  useEffect(() => {
    const fetch = async () => {
      // if (clasData.length == 0) {
        getClass();
      // }
      const res = await getFormForCreateStudent();
      if (res.status === "Success") {
        const data = res.data.fields.map((f, i) => {
          return { id: f._id, name: f.name, value: "" };
        });

        setfields(data);
      }

      if (props.edit) {
        console.log("params id - ", params.id);
        console.log("student data - ", students);
        const data = await students.filter((f, i) => {
          return f._id === params.id;
        });

        if (data.length > 0) {
          setEditData(data[0]);
          setfields(data[0].fields);

          setNewStudent({
            b_id: data[0].b_id,
            session: data[0].session,
            hostel: data[0].hostel,
            sr_no: data[0].sr_no,
            class_id: data[0].class_id._id,
            fields: data[0].fields,
          });

          setCallServerStatus(true);
        }
      }
    };

    fetch();
  }, []);

  const [newStudent, setNewStudent] = useState({
    b_id: "",
    session: "",
    sr_no: 0,
    class_id: "",
    hostel: false,
    fields: [],
  });

  const [fields, setfields] = useState([]);

  const onInput = (e) => {
    if (e.target.name === "hostel") {
      setNewStudent({ ...newStudent, [e.target.name]: e.target.checked });
    } else {
      if (e.target.name === "class_id") {
        console.log("class id called");
        const data = clasData.filter((f, i) => {
          return f._id === e.target.value;
        });

        const srinc = parseInt(data[0].sr_inc) + 1;

        setNewStudent({
          ...newStudent,
          sr_no: srinc,
          [e.target.name]: e.target.value,
        });
      } else {
        setNewStudent({ ...newStudent, [e.target.name]: e.target.value });
      }
    }
  };

  console.log("new studnee  - ", newStudent);

  const [call_server_status, setCallServerStatus] = useState(false);

  const onFieldSelect = (e, obj) => {
    console.log("object for file input - ", obj);
    if (e.target.value !== "0") {
      const data = fields.map((f, i) => {
        return f.id === obj._id
          ? {
              ...f,
              value: e.target.value,
              name: e.target.name,
              type: obj.f_type,
            }
          : f;
      });

      setfields(data);
      setNewStudent({ ...newStudent, fields: data });
      setCallServerStatus(true);
    } else {
      setCallServerStatus(false);
    }
  };

  const [chooseFile, setChooseFile] = useState({});

  const onchangeListener = (e, obj) => {
    console.log("object for file file selected - ", obj);

    const data = fields.map((f, i) => {
      return f.id === obj._id
        ? {
            ...f,
            value: e.target.files[0],
            name: e.target.name,
            type: obj.f_type,
          }
        : f;
    });

    setfields(data);

    setNewStudent({ ...newStudent, fields: data });
    setChooseFile({ ...chooseFile, [e.target.name]: e.target.files[0] });
  };

  const onHandle = async (e) => {
    e.preventDefault();
    console.log("create student data - ", newStudent);
    if (call_server_status) {
      if (props.edit) {
        const res = await udpateStudent(editData._id, newStudent);
        if (res === "Success") {
          props.showAlert("Student has been updated.", "success");
          history("/admin/all_students");
        }
      } else {
        let serverCallReady = newStudent.fields.every((f) => f.value !== "");

        // if (serverCallReady) {
        newStudent.b_id = b_data._id;
        newStudent.session = c_session.session_year;

        const formData = new FormData();
        formData.append("b_id", b_data._id);
        formData.append("session", c_session.session_year);
        formData.append("class_id", newStudent.class_id);
        formData.append("sr_no", newStudent.sr_no);
        formData.append("hostel", newStudent.hostel);

        formData.append("fields", JSON.stringify(newStudent.fields));

        Object.keys(chooseFile).forEach((key) => {
          formData.append(key, chooseFile[key]);
        });

        dispatch(
          addStudents(formData, () => {
            history("/admin/all_students");
          })
        );
        // } else {
        //   console.log();
        //   dispatch(showMyAlert("All fields are required", "warning"));
        // }
      }
    } else {
      dispatch(showMyAlert("Enter require fields", "warning"));
    }
  };

  return (
    <>
      <div className="content-wrapper payments">
        <div className="container-fluid flex-grow-1 container-p-y">
          <div className="row">
            <div className="col-lg-12 mb-4 ">
              <div className="row" style={{ textAlign: "left" }}>
                <div className="col-12 mx-auto">
                  <div className="card" style={{ border: "none" }}>
                    <h5 className="card-header">Register Student</h5>
                    <div className="card-body">
                      <div className="row">
                        {set_login_user_data.config &&
                          set_login_user_data.config.hostel_module && (
                            <div className="col-12 col-lg-6 mb-2">
                              <div className="form-group">
                                <label for="">Hostel Management</label>
                                <br />
                                <input
                                  type="checkbox"
                                  name="hostel"
                                  onChange={onInput}
                                  checked={
                                    props.edit ? newStudent.hostel : null
                                  }
                                />{" "}
                                Check if student live in hostel
                              </div>
                            </div>
                          )}

                        {registerationForm && (
                          <>
                            <div className="col-12 col-lg-6 mb-2">
                              <div className="form-group">
                                <label for="">Sr. No.</label>
                                <input
                                  type="number"
                                  className="form-control"
                                  name="sr_no"
                                  onChange={onInput}
                                  value={
                                    props.edit
                                      ? editData && editData.sr_no
                                      : newStudent.sr_no
                                  }
                                />
                              </div>
                            </div>

                            <div className="col-12 col-lg-6 mb-2">
                              <div className="form-group">
                                <label for="">Choose Class</label>
                                <select
                                  className="form-control"
                                  name="class_id"
                                  id=""
                                  onChange={(e) => {
                                    onInput(e);
                                  }}
                                >
                                  <option value={0}>Choose class</option>
                                  {clasData.map((clas, i) => {
                                    const selected =
                                      editData &&
                                      clas._id === editData.class_id._id
                                        ? true
                                        : false;

                                    return (
                                      <option
                                        value={clas._id}
                                        selected={selected}
                                      >
                                        {clas.c_name}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                            </div>

                            {registerationForm.fields.map((form, i) => {
                              const data = props.edit
                                ? editData &&
                                  editData.fields.filter((f) => {
                                    // console.log(f._id+" - "+form._id);
                                    return f.id === form._id;
                                  })
                                : [{ name: "", value: "" }];

                              return (
                                <>
                                  {form.f_type != "dropdown" &&
                                    form.f_type != "file" &&
                                    form.f_type != "radio" && (
                                      <div className="col-12 col-lg-6 mb-2">
                                        <div className="form-group">
                                          <label for="">{form.f_label}</label>
                                          <input
                                            type={form.f_type}
                                            className="form-control"
                                            name={form.name}
                                            id={form._id}
                                            aria-describedby="helpId"
                                            placeholder={form.placeholder}
                                            onChange={(e) => {
                                              onFieldSelect(e, form);
                                            }}
                                            defaultValue={
                                              props.edit
                                                ? data &&
                                                  data.length > 0 &&
                                                  data[0].value
                                                : ""
                                            }
                                          />
                                        </div>
                                      </div>
                                    )}

                                  {form.f_type == "dropdown" && (
                                    <div className="col-12 col-lg-6 mb-2">
                                      <div className="form-group">
                                        <label for="">{form.f_label}</label>
                                        <select
                                          className="form-control"
                                          name={form.name}
                                          id=""
                                          onChange={(e) => {
                                            onFieldSelect(e, form);
                                          }}
                                        >
                                          <option value={0}>
                                            {form.placeholder}
                                          </option>
                                          {form.radio_value.map((value, i) => {
                                            return (
                                              <option
                                                value={value.value}
                                                key={i}
                                                selected={
                                                  props.edit
                                                    ? data &&
                                                      value.value ===
                                                        data[0].value
                                                    : ""
                                                }
                                              >
                                                {value.name}
                                              </option>
                                            );
                                          })}
                                        </select>
                                      </div>
                                    </div>
                                  )}

                                  {form.f_type == "file" && (
                                    <div className="col-12 col-lg-6 mb-2">
                                      <div className="form-group">
                                        <label for="">{form.f_label}</label>
                                        <input
                                          type={form.f_type}
                                          className="form-control"
                                          name={form.name}
                                          onChange={(e) => {
                                            onchangeListener(e, form);
                                          }}
                                          id={form._id}
                                        />
                                      </div>
                                    </div>
                                  )}
                                </>
                              );
                            })}
                          </>
                        )}
                      </div>
                    </div>

                    <div className="card-footer">
                      <button className="btn btn-primary" onClick={onHandle}>
                        {props.edit ? "Update" : "Create"}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateStudent;
