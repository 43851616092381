import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";


const CreateTeacher = ({ open, edit = false, obj, submit }) => {
  const style = {
    background: "#00000080",
    display: "block",
  };

  const [object, setobject] = useState({
    name: "",
    address: "",
    phone: "",
    password: "",
    role: "",
  });

  const onChangeSet = (e) => {
    setobject({ ...object, [e.target.name]: e.target.value });
  };

  const dispatch = useDispatch();
  const history = useNavigate();
  const addteacherServer = async (e) => {
    e.preventDefault();
    if (object.name === "") {
      alert("Please Enter Teacher Name");
      return;
    } else if (object.address === "") {
      alert("Please Enter Teacher Address");
      return;
    } else if (object.password === "") {
      alert("Please Enter the password");
      return;
    } else if (object.phone === "") {
      alert("Please Enter the Phone");
      return;
    } else if (object.role === "") {
      alert("Please Enter Role");
      return;
    } else {
      submit(object, ()=>{
        history("../teachers");
      });
      
    }
  };

  return (
    <div className="modal reviewModal" style={style}>
      <div className="modal-dialog" style={{ width: "50%" }}>
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              Add Staff
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={open}
            ></button>
          </div>
          <div className="modal-body" style={{ textAlign: "left" }}>
            <div className="row">
              <div className="col-6">
                <div class="form-group mb-2">
                  <label for="">Name</label>
                  <input
                    required
                    type="text"
                    class="form-control"
                    name="name"
                    id=""
                    onChange={onChangeSet}
                    aria-describedby="helpId"
                    placeholder="Enter name"
                  />
                </div>

                <div class="form-group mb-2">
                  <label for="">Address</label>
                  <textarea
                    required
                    className="form-control"
                    placeholder="Enter address"
                    name="address"
                    rows={5}
                    onChange={onChangeSet}
                  ></textarea>
                </div>
              </div>

              <div className="col-6">
                <div class="form-group mb-2">
                  <label for="">Phone</label>
                  <input
                    required
                    type="tel"
                    pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                    minLength={10}
                    class="form-control"
                    name="phone"
                    id=""
                    onChange={onChangeSet}
                    aria-describedby="helpId"
                    placeholder="Enter phone"
                  />
                </div>

                <div class="form-group mb-2">
                  <label for="">Password</label>
                  <input
                    required
                    type="tel"
                    pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                    minLength={10}
                    class="form-control"
                    name="password"
                    onChange={onChangeSet}
                    id=""
                    aria-describedby="helpId"
                    placeholder="Enter password"
                  />
                </div>

                <div class="form-group mb-2">
                  <label for="">Select Role</label>
                  <select
                    required
                    className="form-control"
                    name="role"
                    onChange={onChangeSet}
                  >
                    <option>Choose role</option>
                    <option value={"accountant"}>Accountant</option>
                    <option value={"teacher"}>Teacher</option>
                    <option value={"recepsnist"}>Recepsnist</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer pt-0">
            <div className="d-flex">
              <button className=" btn btn-secondary me-3" onClick={open}>
                Cancel
              </button>
              <button
                className="btn btn-primary"
                onClick={(e) => {
                  addteacherServer(e);
                }}
              >
                Add
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateTeacher;
