import React, { useContext, useEffect, useState } from "react";
import { ClassCon, SubjectCon } from "../../context/AllContext";
const Fees_add = ({ openModal, s_data, updateSData }) => {
  const style = {
    background: "#00000080",
    display: "block",
  };

  const context = useContext(ClassCon);
  const { updateClass } = context;

  const [newFees, setNewFees] = useState({
    total_amount: s_data.fees_data.total_amount,
    installment: s_data.fees_data.installment,
    admission_fees: s_data.fees_data.admission_fees,
    exam_fees: s_data.fees_data.exam_fees,
  });

  const inputData = (e) => {
    setNewFees({ ...newFees, [e.target.name]: e.target.value });
  };

  const updateHandler = async (e) => {
    e.preventDefault();

    const res = await updateClass(s_data._id, { fees_data: newFees });

    if (res.status === "Success") {
      s_data = res.data;
      updateSData(res.data);
    }

    openModal();
  };

  return (
    <div className="modal reviewModal" style={style}>
      <div className="modal-dialog session_dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              Add Fees Detail in {s_data.c_name}
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={openModal}
            ></button>
          </div>
          <div className="modal-body" style={{ textAlign: "left" }}>
            <div className="row">
              <div className="col-6 text-right">
                <div class="form-group mb-2">
                  <label for="">Total Amount</label>
                  <input
                    type="number"
                    class="form-control"
                    name="total_amount"
                    id=""
                    aria-describedby="helpId"
                    placeholder="0"
                    onChange={inputData}
                    defaultValue={s_data.fees_data.total_amount}
                  />
                </div>

                <div class="form-group mb-2">
                  <label for="">Installment</label>
                  <input
                    type="number"
                    class="form-control"
                    name="installment"
                    id=""
                    aria-describedby="helpId"
                    placeholder="0"
                    onChange={inputData}
                    defaultValue={s_data.fees_data.installment}
                  />
                </div>
              </div>
              <div className="col-6 text-right">
                <div class="form-group mb-2">
                  <label for="">Exam Fees</label>
                  <input
                    type="number"
                    class="form-control"
                    name="exam_fees"
                    id=""
                    aria-describedby="helpId"
                    placeholder="0"
                    onChange={inputData}
                    defaultValue={s_data.fees_data.exam_fees}
                  />
                </div>

                <div class="form-group mb-2">
                  <label for="">Admission Fees</label>
                  <input
                    type="number"
                    class="form-control"
                    name="admission_fees"
                    id=""
                    aria-describedby="helpId"
                    placeholder="0"
                    onChange={inputData}
                    defaultValue={s_data.fees_data.admission_fees}
                  />
                </div>

                <button
                  className="btn btn-primary mt-3"
                  onClick={updateHandler}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const AdditionalFees = ({ openModal, s_data, updateSData }) => {
  const style = {
    background: "#00000080",
    display: "block",
  };

  const context = useContext(ClassCon);
  const { updateClass } = context;

  const [newFees, setNewFees] = useState([
    {
      id: 0,
      name: s_data.fees_data.total_amount,
      amount: s_data.fees_data.installment,
    },
  ]);

  useEffect(() => {
    if (s_data.additional_fees.length > 0) {
      setNewFees(
        s_data.additional_fees.map((f, i) => {
          return { name: f.name, amount: f.amount, id: i };
        })
      );
    }
  }, []);

  const inputData = (e, id) => {
    const data = newFees.map((fee, i) => {
      return fee.id === id ? { ...fee, [e.target.name]: e.target.value } : fee;
    });

    setNewFees(data);
  };

  const updateHandler = async (e) => {
    e.preventDefault();

    const res = await updateClass(s_data._id, { additional_fees: newFees });

    if (res.status === "Success") {
      s_data = res.data;
      updateSData(res.data);
    }

    openModal();
  };

  console.log(newFees);

  return (
    <div className="modal reviewModal" style={style}>
      <div className="modal-dialog fields_dialog" >
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              Add Additional Fees in {s_data.c_name}
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={openModal}
            ></button>
          </div>
          <div className="modal-body" style={{ textAlign: "left" }}>
            <div className="row">
              {newFees.map((fees, i) => {
                return (
                  <>
                    <div className="col-6 text-right">
                      <div class="form-group mb-2">
                        <label for="">Name</label>
                        <input
                          type="text"
                          class="form-control"
                          name="name"
                          id=""
                          aria-describedby="helpId"
                          placeholder="Additional Fees Name"
                          onChange={(e) => {
                            inputData(e, fees.id);
                          }}
                          // defaultValue={fees.name}
                        />
                      </div>
                    </div>
                    <div className="col-6 text-right">
                      <div class="form-group mb-2">
                        <label for="">Amount</label>
                        <input
                          type="number"
                          class="form-control"
                          name="amount"
                          id=""
                          aria-describedby="helpId"
                          placeholder="0"
                          onChange={(e) => {
                            inputData(e, fees.id);
                          }}
                          // defaultValue={fees.amount}
                        />
                      </div>

                      <div className="d-flex justify-content-end">
                        <span
                          className="badge bg-danger me-2"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            if (newFees.length > 0) {
                              setNewFees(
                                newFees.filter((fee, i) => {
                                  return fee.id != fees.id;
                                })
                              );
                            }
                          }}
                        >
                          <i className="fa fa-trash"></i>
                        </span>
                        <span
                          className="badge bg-success"
                          onClick={() => {
                            setNewFees([
                              ...newFees,
                              { id: newFees.length + 20, name: "", amount: "" },
                            ]);
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          <i className="fa fa-plus"></i>
                          {/* {fees.id} */}
                        </span>
                      </div>
                    </div>
                  </>
                );
              })}
            </div>
          </div>

          <div className="modal-footer">
            <button className="btn btn-primary m-0" onClick={updateHandler}>
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};



const HostelFees = ({ openModal, s_data, updateSData }) => {
  const style = {
    background: "#00000080",
    display: "block",
  };

  const context = useContext(ClassCon);
  const { updateClass } = context;

  const [newFees, setNewFees] = useState([
    {
      id: 0,
      name: s_data.fees_data.total_amount,
      amount: s_data.fees_data.installment,
    },
  ]);

  useEffect(() => {
    if (s_data.additional_fees.length > 0) {
      setNewFees(
        s_data.additional_fees.map((f, i) => {
          return { name: f.name, amount: f.amount, id: i };
        })
      );
    }
  }, []);

  const inputData = (e, id) => {
    const data = newFees.map((fee, i) => {
      return fee.id === id ? { ...fee, [e.target.name]: e.target.value } : fee;
    });

    setNewFees(data);
  };

  const updateHandler = async (e) => {
    e.preventDefault();

    const res = await updateClass(s_data._id, { hostel_fees: newFees });

    if (res.status === "Success") {
      s_data = res.data;
      updateSData(res.data);
    }

    openModal();
  };

  

  return (
    <div className="modal reviewModal" style={style}>
      <div className="modal-dialog fields_dialog" >
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              Add Hostel Fees in {s_data.c_name}
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={openModal}
            ></button>
          </div>
          <div className="modal-body" style={{ textAlign: "left" }}>
            <div className="row">
              {newFees.map((fees, i) => {
                return (
                  <>
                    <div className="col-6 text-right">
                      <div class="form-group mb-2">
                        <label for="">Name</label>
                        <input
                          type="text"
                          class="form-control"
                          name="name"
                          id=""
                          aria-describedby="helpId"
                          placeholder="Hostel Fees Name"
                          onChange={(e) => {
                            inputData(e, fees.id);
                          }}
                          // defaultValue={fees.name}
                        />
                      </div>
                    </div>
                    <div className="col-6 text-right">
                      <div class="form-group mb-2">
                        <label for="">Amount</label>
                        <input
                          type="number"
                          class="form-control"
                          name="amount"
                          id=""
                          aria-describedby="helpId"
                          placeholder="0"
                          onChange={(e) => {
                            inputData(e, fees.id);
                          }}
                          // defaultValue={fees.amount}
                        />
                      </div>

                      <div className="d-flex justify-content-end">
                        <span
                          className="badge bg-danger me-2"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            if (newFees.length > 0) {
                              setNewFees(
                                newFees.filter((fee, i) => {
                                  return fee.id != fees.id;
                                })
                              );
                            }
                          }}
                        >
                          <i className="fa fa-trash"></i>
                        </span>
                        <span
                          className="badge bg-success"
                          onClick={() => {
                            setNewFees([
                              ...newFees,
                              { id: newFees.length + 20, name: "", amount: "" },
                            ]);
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          <i className="fa fa-plus"></i>
                          
                        </span>
                      </div>
                    </div>
                  </>
                );
              })}
            </div>
          </div>

          <div className="modal-footer">
            <button className="btn btn-primary m-0" onClick={updateHandler}>
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};


const SetSubjects = ({ openModal, s_data, updateSData }) => {
  const style = {
    background: "#00000080",
    display: "block",
  };

  const context = useContext(SubjectCon);
  const { subjects, getSubjects } = context;

  const classcontext = useContext(ClassCon);
  const { updateClass } = classcontext;

  const [selected, setSelected] = useState([]);

  const onInput = (e) => {
    if (e.target.checked) {
      setSelected([...selected, { sub_id: e.target.value }]);
    } else {
      setSelected(
        selected.filter((sub) => {
          return sub.sub_id != e.target.value;
        })
      );
    }
  };

  console.log(selected);

  useEffect(() => {
    const fetch = async () => {
      const res = await getSubjects();
      setSelected(
        subjects.map((sub) => {
          return { sub_id: sub.sub_id._id };
        })
      );
    };

    fetch();
  }, []);

  const updateHandler = async (e) => {
    e.preventDefault();

    const res = await updateClass(s_data._id, { subjects: selected });

    if (res.status === "Success") {
      s_data = res.data;
      updateSData(res.data);
    }

    openModal();
  };

  return (
    <div className="modal reviewModal" style={style}>
      <div className="modal-dialog fields_dialog" >
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              Set Subjects in {s_data.c_name}
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={openModal}
            ></button>
          </div>
          <div className="modal-body" style={{ textAlign: "left" }}>
            <div className="row">
              <div className="col-12">
                <ul className="m-0 p-0 subject_list">
                  {subjects.map((sub, i) => {
                    return (
                      <li className="bg-secondary d-flex">
                        <input
                          type="checkbox"
                          name=""
                          value={sub._id}
                          onChange={onInput}
                          // defaultValue={selected.length > 0 && selected.has(sub._id)}
                        />{" "}
                        <span className="mx-3">{sub.sub_name}</span>
                      </li>
                    );
                  })}
                </ul>
              </div>

              {/* <div className="col-6"></div> */}
            </div>
          </div>

          <div className="modal-footer">
            <button className="btn btn-primary m-0" onClick={updateHandler}>
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export { Fees_add, AdditionalFees, SetSubjects, HostelFees };
