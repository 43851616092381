import React from 'react'
import "./hero.css"
import { Link } from 'react-router-dom';
const Hero = () => {
  return (
    <>
      <div className="container heroCont ">
        <div className="row ">
          <div className="col-md-6 col-10 mx-auto d-flex align-items-center hero-c-1">
            <div className="register_wrapper mt-5">
              <span className="registerBtn">Switch To Online</span>
              <span className="content mb-1 mb-md-2">
                Set up your school, coaching, college online
              </span>
              <Link to={"/admin"} style={{textAlign:"left"}}>
              <span className="exploreBtn mt-5">Explore Now</span>
              </Link>
            </div>
          </div>
          <div className="col-md-6 col-10 d-flex align-items-center hero-c-1 ">
            <img src="./assets/images/cro.png" alt="" />
          </div>
        </div>
      </div>
    </>
  );
}

export default Hero
