import { loaderAction, showMyAlert } from "../../action";
import { classUploadSuccess } from "../../action/classAction";
import { CLASS_ACTION } from "../../action/constant";
import serverCallFuction from "../../constantFunction";

const intialState = [];

export const deleteClassData = (id) => async (dispatch) => {
  try {
    const res = await serverCallFuction("DELETE", `class/deleteClass/${id}`);
    if (res.status === "Success") {
      dispatch(showMyAlert("Class has been deleted", "success"));
      dispatch(classUploadSuccess(res, "deleteClass_" + id));
    } else {
      dispatch(showMyAlert("Something went wrong", "warning"));
    }
  } catch (error) {
    dispatch(showMyAlert("Execption Error (Class) - 202", "danger"));
  }
};

export const addClassRedux = (body, callback) => async (dispatch) => {
  dispatch(loaderAction(true));
  try {
    const res = await serverCallFuction("POST", `class/addClass`, body);
    if (res.status === "Success") {
      dispatch(showMyAlert("Class has been addded", "success"));
      dispatch(classUploadSuccess(res, "addClass"));
      callback();
    } else {
      dispatch(showMyAlert("Something went wrong", "warning"));
    }
  } catch (error) {
    dispatch(showMyAlert("Execption Error (Class) - 202", "danger"));
  }
  dispatch(loaderAction(false));
};

export const addTestInClass = (body, callback) => async (dispatch) => {
  dispatch(loaderAction(true));
  console.log("add test in class");
  try {
    const res = await serverCallFuction("POST", `class/addTest`, body);
    if (res.status === "Success") {
      dispatch(showMyAlert("Test has been added", "success"));
      dispatch(classUploadSuccess(res, "addTest"));
      callback();
    } else {
      dispatch(showMyAlert("Something went wrong", "warning"));
    }
  } catch (error) {
    dispatch(showMyAlert("Execption Error (Class - Add Test) - 202", "danger"));
  }
  dispatch(loaderAction(false));
};

// add marks in class
export const addMarksInTest = (body, callback) => async (dispatch) => {
  dispatch(loaderAction(true));
  console.log("add test in class");
  try {
    const res = await serverCallFuction("POST", `class/updateTest`, body);
    if (res.status === "Success") {
      dispatch(showMyAlert("Marks has been added in Test", "success"));
      dispatch(classUploadSuccess(res, "updateMarks"));
      callback();
    } else {
      dispatch(showMyAlert("Something went wrong", "warning"));
    }
  } catch (error) {
    console.log(error);
    dispatch(
      showMyAlert("Execption Error (Class - Add Marks) - 202", "danger")
    );
  }
  dispatch(loaderAction(false));
};

export const GetClassServer = (params) => async (dispatch) => {
  try {
    dispatch(loaderAction(true));
    const res = await serverCallFuction("GET", `class/getClass/${params.b_id}`);
    if (res.status === "Success") {
      dispatch(classUploadSuccess(res, "getClasses"));
    } else {
      dispatch(showMyAlert("Something went wrong! (Class - 202)", "warning"));
    }
    dispatch(loaderAction(false));
  } catch (error) {
    dispatch(showMyAlert("Exeption Error (Class) - 203", "danger"));
  }
};

export const UpdateClassData = (params, body) => async (dispatch) => {
  try {
    dispatch(loaderAction(true));
    const res = await serverCallFuction(
      "PUT",
      `class/updateClass/${params.class_id}`,
      body
    );
    console.log("update class data - ", res);
    if (res.status === "Success") {
      dispatch(classUploadSuccess(res, "updateClass"));
    } else {
      dispatch(showMyAlert("Something went wrong! (Class - 202)", "warning"));
    }
    dispatch(loaderAction(false));
  } catch (error) {
    dispatch(showMyAlert("Exeption Error (Class) - 203", "danger"));
  }
};

const classData = (state = intialState, action) => {
  switch (action.type) {
    case CLASS_ACTION.success:
      if (action.u_type === "getClasses") {
        state = action.payload.data;
      }

      if (action.u_type === "updateClass") {
        state = state.map((s, i) => {
          return s._id === action.payload.data._id ? action.payload.data : s;
        });
      }

      if (action.u_type === "addClass") {
        state = state.concat(action.payload.data);
      }

      if (action.u_type.includes("deleteClass")) {
        const data = action.u_type.split("_");
        state = state.filter((d) => {
          return d._id !== data[1];
        });
      }

      if (action.u_type === "addTest") {
        state = state.map((s, i) => {
          return s._id === action.payload.data._id ? action.payload.data : s;
        });
      }

      if (action.u_type === "updateMarks") {
        state = state.map((s, i) => {
          return s._id === action.payload.data._id ? action.payload.data : s;
        });
      }

    default:
      state = state;
  }
  return state;
};
export default classData;
