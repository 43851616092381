import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useReactToPrint } from "react-to-print";
import serverCallFuction from "../../redux/constantFunction";
import { localActiveBranch } from "../../redux/reducer/branchReducer";

export const formatDate = (date) => {
  return new Intl.DateTimeFormat("en-US", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    timeZone: "UTC",
  }).format(date);
};

const Print_Fees_reciept = () => {
  const b_data = useSelector((state) => state.fetchDataFromLocal);
  const f_s_data = useSelector((state) => state.set_print_object);

  console.log("fs_data - ", f_s_data);

  const pdfRef = useRef();
  const genereatePDF = useReactToPrint({
    content: () => pdfRef.current,
    documentTitle: "Fees Reciept",
    onBeforeGetContent: () => {
      const headElement = document.head;
      const linkElement = document.createElement("link");
      linkElement.rel = "stylesheet";
      linkElement.href =
        "%PUBLIC_URL%/assets/css/A.bootstrap.min.css%2bLineIcons.3.0.css%2btiny-slider.css%2bglightbox.min.css%2cMcc.OzR7N5fb_Y.css.pagespeed.cf.svKjl5Nf5n.css";
      headElement.appendChild(linkElement);

      return new Promise((resolve) => {
        setTimeout(() => {
          resolve();
        }, 500);
      });
    },
  });

  const [fees_entry, set_fees_entry] = useState(null);

  const [today, setToday] = useState("");
  const [inv_no, setInvNo] = useState(0);

  const [c_session, set_c_session] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    const session = b_data.sessions.filter((branch) => {
      return branch.current;
    });

    set_c_session(session[0]);

    const f_data = f_s_data.fees_entry.filter((fees, index) => {
      return fees.session === session[0].session_year;
    })[0];

    set_fees_entry(f_data);

    const currentDate = new Date();
    const options = { day: "numeric", month: "long", year: "numeric" };
    const formattedDate = currentDate.toLocaleDateString("en-US", options);
    setToday(formattedDate);

    setInvNo(
      b_data.config.filter((c) => {
        return c.session === session[0].session_year;
      })[0].inv_no
    );
  }, []);

  let grandTotal = 0;
  let t_discount = 0;
  let t_paid = 0;
  let t_fine = 0;
  let t_total = 0;

  const updateInvNo = async () => {
    const res = await serverCallFuction(
      "PUT",
      `branch/updateInv/${b_data._id}/${c_session.session_year}`,
      null
    );

    if (res.status === "Success") {
      dispatch(localActiveBranch(res.branch));
    }
  };

  return (
    <div className="content-wrapper mt-5">
      <div className="container">
        <div className="row">
          <div className="col-12 mb-4" style={{ textAlign: "right" }}>
            <span
              className="badge bg-primary"
              style={{ cursor: "pointer" }}
              onClick={() => {
                genereatePDF();
                updateInvNo();
              }}
            >
              <i className="fa fa-print"></i> Print
            </span>
          </div>

          <div className="col-lg-12" ref={pdfRef} style={{margin:40}}>
            <div className="row border">
              <div className="col-6 ">
                <div
                  className="header-print1 img-fluid"
                  style={{
                    backgroundImage: `url(${
                      b_data.config &&
                      b_data.config.length > 0 &&
                      b_data.config[0].header
                    })`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "contain",
                    minHeight: 100,
                    height: 100,
                    backgroundPosition: "center",
                    position: "relative",
                  }}
                ></div>
              </div>

              <div className="col-6 m-auto text-right">
                <p className="p-0 m-0">Invoice No - #000{inv_no}</p>
                <p className="p-0 m-0">Date : {today}</p>
                <p className="p-0 m-0">Status : Unpaid</p>
              </div>
            </div>

            <div className="row mt-4">
              <div className="col-4 text-left ">
                <b>
                  <span>Invioce To :</span>
                </b>
                <ul className="m-0 p-0 mt-2">
                  <li>
                    {f_s_data.fields &&
                      f_s_data.fields.filter((f) => f.name === "name")[0].value}
                  </li>
                  <li>Register No : {f_s_data.sr_no}</li>
                  {/* <li>{f_s_data.fields.filter((f) => f.name === "address")[0].value}</li> */}
                  <li>
                    Class : {f_s_data.class_id && f_s_data.class_id.c_name}
                  </li>
                  <li>
                    Father Name : Mr.{" "}
                    {f_s_data && f_s_data.fields
                      ? f_s_data.fields
                          .filter((f) => f.name === "father_name")
                          .map((f) => f.value)[0] || "Not Available"
                      : "Not Available"}
                  </li>
                  <li>Hostel : {f_s_data.hostel ? "Yes" : "No"}</li>
                </ul>
              </div>

              <div className="col-8 text-right ">
                <b>
                  <span>Academic :</span>
                </b>
                <ul className="m-0 p-0 mt-2">
                  <li>{b_data.branch_name}</li>
                  <li>
                    {b_data.address}, {b_data.state} ({b_data.zipcode})
                  </li>

                  <li>
                    {b_data.contact_details && b_data.contact_details[0].phone}
                  </li>
                  <li>
                    {b_data.contact_details && b_data.contact_details[0].email}
                  </li>
                </ul>
              </div>

              <div className="col-12 mt-5">
                <table width={"100%"} className="table table-hover">
                  <thead>
                    <tr>
                      <th>Fees Type</th>
                      <th>Paid Date</th>
                      <th>Status</th>
                      <th>Amount</th>
                      <th>Remaining Amount</th>
                      <th>Discount</th>
                      <th>Fine</th>
                      <th>Paid</th>
                      <th>Balance</th>
                    </tr>
                  </thead>
                  <tbody>
                    {/* <tr>
                      <td colSpan={8} className="text-left">
                      Class - {f_s_data.class_id && f_s_data.class_id.c_name}
                      </td>
                    </tr> */}

                    {f_s_data &&
                      (() => {
                        const items = [];
                        let value = 0;
                        if (f_s_data.class_id) {
                          value =
                            parseFloat(
                              f_s_data.class_id.fees_data.total_amount
                            ) /
                            parseInt(f_s_data.class_id.fees_data.installment);
                        }

                        // /-----admisssion fees entry-------------------------/
                        let admission_entry = null;
                        if (fees_entry) {
                          admission_entry = fees_entry.entry.filter((fees) => {
                            return fees.type === "admission_fees";
                          });
                        }

                        let ad_total = 0;
                        let ad_paid =
                          admission_entry &&
                          (admission_entry.length > 0
                            ? admission_entry[0].total_paid
                            : 0);
                        let ad_remaining_amount =
                          admission_entry &&
                          (admission_entry.length > 0
                            ? admission_entry[0].remaining_amount
                            : 0);
                        let ad_discount =
                          admission_entry &&
                          (admission_entry.length > 0
                            ? admission_entry[0].discount
                            : 0);
                        let ad_fine =
                          admission_entry &&
                          (admission_entry.length > 0
                            ? admission_entry[0].late_fees
                            : 0);

                        ad_total =
                          f_s_data.class_id.fees_data.admission_fees -
                          parseFloat(ad_discount) +
                          parseFloat(ad_fine);

                        grandTotal += ad_total;
                        t_discount += parseFloat(ad_discount);
                        t_paid += parseFloat(ad_paid);
                        t_fine += parseFloat(ad_fine);

                        items.push(
                          <tr key={12}>
                            <td>Admission Fees</td>
                            <td>
                              {admission_entry &&
                                (admission_entry.length > 0
                                  ? formatDate(
                                      new Date(admission_entry[0].pay_date)
                                    )
                                  : "-")}
                            </td>
                            <td>
                              {fees_entry &&
                              fees_entry.entry.some((fees) => {
                                return fees.type === "admission_fees";
                              })
                                ? "Paid"
                                : "Unpaid"}
                            </td>
                            <td>
                              ₹
                              {Math.round(
                                f_s_data.class_id.fees_data.admission_fees * 100
                              ) / 100}
                            </td>
                            <td>
                              ₹0
                              {/* ₹{parseFloat(ad_remaining_amount).toFixed(2)} */}
                            </td>
                            <td>₹{parseFloat(ad_discount).toFixed(2)}</td>
                            <td>₹{parseFloat(ad_fine).toFixed(2)}</td>
                            <td>₹{parseFloat(ad_paid).toFixed(2)}</td>
                            <td>₹{parseFloat(ad_total).toFixed(2)}</td>
                          </tr>
                          // <tr key={i}>
                          //   <th>Value</th>
                          //   <td>₹{Math.round(value * 100) / 100}</td>
                          // </tr>
                        );

                        // ---------------------------------------

                        // /-----exam fees entry-------------------------/
                        let exam_entry = null;
                        if (fees_entry) {
                          exam_entry = fees_entry.entry.filter((fees) => {
                            return fees.type === "exam_fees";
                          });
                        }

                        let total = 0;
                        let paid =
                          exam_entry &&
                          (exam_entry.length > 0
                            ? exam_entry[0].total_paid
                            : 0);
                        let exam_remaining_amount =
                          exam_entry &&
                          (exam_entry.length > 0
                            ? exam_entry[0].remaining_amount
                            : 0);
                        let discount =
                          exam_entry &&
                          (exam_entry.length > 0 ? exam_entry[0].discount : 0);
                        let fine =
                          exam_entry &&
                          (exam_entry.length > 0 ? exam_entry[0].late_fees : 0);

                        total =
                          f_s_data.class_id.fees_data.exam_fees +
                          parseFloat(ad_remaining_amount) -
                          parseFloat(discount) +
                          parseFloat(fine);

                        grandTotal += total;
                        t_discount += parseFloat(discount);
                        t_paid += parseFloat(paid);
                        t_fine += parseFloat(fine);

                        items.push(
                          <tr key={12}>
                            <td>Exam Fees</td>
                            <td>
                              {exam_entry &&
                                (exam_entry.length > 0
                                  ? formatDate(new Date(exam_entry[0].pay_date))
                                  : "-")}
                            </td>
                            <td>
                              {fees_entry &&
                              fees_entry.entry.some((fees) => {
                                return fees.type === "exam_fees";
                              })
                                ? "Paid"
                                : "Unpaid"}
                            </td>
                            <td>
                              ₹
                              {Math.round(
                                f_s_data.class_id.fees_data.exam_fees * 100
                              ) / 100}
                            </td>
                            <td>
                              ₹{parseFloat(ad_remaining_amount).toFixed(2)}
                            </td>
                            <td>₹{parseFloat(discount).toFixed(2)}</td>
                            <td>₹{parseFloat(fine).toFixed(2)}</td>
                            <td>₹{parseFloat(paid).toFixed(2)}</td>
                            <td>₹{parseFloat(total).toFixed(2)}</td>
                          </tr>
                          // <tr key={i}>
                          //   <th>Value</th>
                          //   <td>₹{Math.round(value * 100) / 100}</td>
                          // </tr>
                        );

                        // additional fees

                        for (
                          let i = 0;
                          i < f_s_data.class_id.additional_fees.length;
                          i++
                        ) {
                          const element = f_s_data.class_id.additional_fees[i];

                          let entry = null;
                          let remaining_amount = 0;
                          if (fees_entry) {
                            entry = fees_entry.entry.filter((fees) => {
                              return (
                                fees.type === "additional" && fees.index === i
                              );
                            });

                            if (i > 0) {
                              const r_entry = fees_entry.entry.filter(
                                (fees) => {
                                  return (
                                    fees.type === "additional" &&
                                    fees.index === i - 1
                                  );
                                }
                              );

                              remaining_amount =
                                r_entry &&
                                (r_entry.length > 0
                                  ? r_entry[0].remaining_amount
                                  : 0);
                            }
                          }

                          let total = 0;
                          let paid =
                            entry &&
                            (entry.length > 0 ? entry[0].total_paid : 0);

                          let discount =
                            entry && (entry.length > 0 ? entry[0].discount : 0);
                          let fine =
                            entry &&
                            (entry.length > 0 ? entry[0].late_fees : 0);

                          total =
                            element.amount +
                            parseFloat(
                              i === 0 ? exam_remaining_amount : remaining_amount
                            ) -
                            parseFloat(discount) +
                            parseFloat(fine);

                          grandTotal += element.amount - parseFloat(fine);
                          t_discount += parseFloat(discount);
                          t_paid += parseFloat(paid);
                          t_fine += parseFloat(fine);

                          items.push(
                            <tr key={i}>
                              <td>{element.name}</td>
                              <td>
                                {entry &&
                                  (entry.length > 0
                                    ? formatDate(new Date(entry[0].pay_date))
                                    : "-")}
                              </td>
                              <td>
                                {fees_entry &&
                                fees_entry.entry.some((fees) => {
                                  return (
                                    fees.type === "additional" &&
                                    fees.index === i
                                  );
                                })
                                  ? "Paid"
                                  : "Unpaid"}
                              </td>
                              <td>₹{Math.round(element.amount * 100) / 100}</td>
                              <td>
                                ₹
                                {parseFloat(
                                  i === 0
                                    ? exam_remaining_amount
                                    : remaining_amount
                                ).toFixed(2)}
                              </td>
                              <td>₹{parseFloat(discount).toFixed(2)}</td>
                              <td>₹{parseFloat(fine).toFixed(2)}</td>
                              <td>₹{parseFloat(paid).toFixed(2)}</td>
                              <td>₹{parseFloat(total).toFixed(2)}</td>
                            </tr>
                            // <tr key={i}>
                            //   <th>Value</th>
                            //   <td>₹{Math.round(value * 100) / 100}</td>
                            // </tr>
                          );
                        }

                        // ---------------------------------------
                        let hostelFeesTotal = 0;
                        if (f_s_data.hostel) {
                          f_s_data.class_id.hostel_fees.map((fees) => {
                            hostelFeesTotal += fees.amount;
                          });
                        }

                        // console.log("hostel total ", value);
                        //eddit not
                        value +=
                          parseFloat(hostelFeesTotal) /
                          f_s_data.class_id.fees_data.installment;

                        for (
                          let i = 0;
                          i < f_s_data.class_id.fees_data.installment;
                          i++
                        ) {
                          let entry = null;
                          let remaining_amount = 0;
                          if (fees_entry) {
                            entry = fees_entry.entry.filter((fees) => {
                              return (
                                fees.type === "installment" && fees.index === i
                              );
                            });

                            if (i > 0) {
                              const r_entry = fees_entry.entry.filter(
                                (fees) => {
                                  return (
                                    fees.type === "installment" &&
                                    fees.index === i - 1
                                  );
                                }
                              );

                              remaining_amount =
                                r_entry &&
                                (r_entry.length > 0
                                  ? r_entry[0].remaining_amount
                                  : 0);
                            }
                          }

                          let total = 0;
                          let paid =
                            entry &&
                            (entry.length > 0 ? entry[0].total_paid : 0);

                          let discount =
                            entry && (entry.length > 0 ? entry[0].discount : 0);
                          let fine =
                            entry &&
                            (entry.length > 0 ? entry[0].late_fees : 0);

                          total =
                            value +
                            parseFloat(
                              i === 0 ? exam_remaining_amount : remaining_amount
                            ) -
                            parseFloat(discount) +
                            parseFloat(fine);

                          grandTotal += value - parseFloat(fine);
                          t_discount += parseFloat(discount);
                          t_paid += parseFloat(paid);
                          t_fine += parseFloat(fine);

                          items.push(
                            <tr key={i}>
                              <td>{i + 1} Semester</td>
                              <td>
                                {entry &&
                                  (entry.length > 0
                                    ? formatDate(new Date(entry[0].pay_date))
                                    : "-")}
                              </td>
                              <td>
                                {fees_entry &&
                                fees_entry.entry.some((fees) => {
                                  return (
                                    fees.type === "installment" &&
                                    fees.index === i
                                  );
                                })
                                  ? "Paid"
                                  : "Unpaid"}
                              </td>
                              <td>₹{Math.round(value * 100) / 100}</td>
                              <td>
                                ₹
                                {parseFloat(
                                  i === 0
                                    ? exam_remaining_amount
                                    : remaining_amount
                                ).toFixed(2)}
                              </td>
                              <td>₹{parseFloat(discount).toFixed(2)}</td>
                              <td>₹{parseFloat(fine).toFixed(2)}</td>
                              <td>₹{parseFloat(paid).toFixed(2)}</td>
                              <td>₹{parseFloat(total).toFixed(2)}</td>
                            </tr>
                            // <tr key={i}>
                            //   <th>Value</th>
                            //   <td>₹{Math.round(value * 100) / 100}</td>
                            // </tr>
                          );
                        }

                        return items;
                      })()}

                    {/* {f_s_data &&
                      f_s_data.fees_entry.map((fees, index) => {
                        return fees.entry.map((entry, j) => {
                          return (
                            <tr>
                              <td>Half Yearly Exam Fees</td>
                              <td>30, Nov, 2024</td>
                              <td>Unpaid</td>
                              <td>100.00</td>
                              <td>0</td>
                              <td>0</td>
                              <td>100</td>
                              <td>100</td>
                            </tr>
                          );
                        });
                      })} */}
                  </tbody>
                </table>

                <div className="text-right mb-5 pb-5">
                  <table
                    className="table tabl-hover"
                    style={{ width: "fit-content", marginLeft: "auto" }}
                  >
                    <tbody>
                      <tr>
                        <td>Grand Total : ₹{grandTotal.toFixed(2)}</td>
                      </tr>
                      <tr>
                        <td>Discount : ₹{t_discount.toFixed(2)}</td>
                      </tr>
                      <tr>
                        <td>Paid : ₹{t_paid.toFixed(2)}</td>
                      </tr>
                      <tr>
                        <td>Fine : ₹{t_fine.toFixed(2)}</td>
                      </tr>
                      <tr>
                        <td>Total Paid (With Fine) : ₹{t_paid.toFixed(2)}</td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            Balance : ₹
                            {(grandTotal - t_paid + t_fine).toFixed(2)} /-
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Print_Fees_reciept;
