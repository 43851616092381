import React, { useEffect, useRef, useState } from "react";
import { getStudentData } from "../../redux/reducer/studentReducer";
import { useDispatch, useSelector } from "react-redux";
import PayModal from "./PayModal";
import "./fees_css.css";
import ReactToPrint, { useReactToPrint } from "react-to-print";
import { CreateStudentForm } from "../../redux/reducer/studentForm";
import { print_data_set } from "../../redux/action";
import { useNavigate, useNavigation } from "react-router-dom";
import { GetClassServer } from "../../redux/reducer/class/classReducer";
const excelJS = require("exceljs");
const FeesPage = () => {
  const myState = useSelector((state) => state.getStudentData);
  const b_data = useSelector((state) => state.fetchDataFromLocal);
  const sessoinData = useSelector((state) => state.currentSession);
  const createFormData = useSelector((state) => state.CreateStudentForm);
  const c_session = useSelector((state) => state.currentSession);

  const class_data = useSelector((state) => state.classData);

  const login_user_data = useSelector((state) => state.set_login_user_data);

  const dispatch = useDispatch();
  const [visibleField, setVisibleFields] = useState([]);
  const [class_filter_id, set_class_filter_id] = useState("all");

  const [fees_total_amount, setTotal_amont] = useState(0);

  const pdfRef = useRef();

  const genereatePDF = useReactToPrint({
    content: () => pdfRef.current,
    documentTitle: "Student Fees Data",
  });
  const isUrl = (str) => {
    try {
      new URL(str);
      return true;
    } catch (error) {
      return false;
    }
  };
  useEffect(() => {
    dispatch(
      CreateStudentForm({
        b_id: b_data._id,
        session_year: c_session.session_year,
      })
    );
    dispatch(GetClassServer({ b_id: b_data._id }));
    dispatch(getStudentData({ b_id: b_data._id }));
  }, [b_data._id]);

  useEffect(() => {
    createFormData &&
      setVisibleFields(
        createFormData.fields.map((f, i) => {
          const modifiedValue = f.name;
          return i <= 1 && modifiedValue;
        })
      );
  }, [createFormData]);

  const [selecteStudent, setSelectStudent] = useState(null);
  const [fees_entry, set_fees_entry] = useState(null);

  const onStudentSelecte = (e) => {
    const data = JSON.parse(e.target.value);
    setSelectStudent(data);

    console.log("sdata - ", data);
    // const t_amt =
    //   parseFloat(data.class_id.fees_data.total_amount) +
    //   parseFloat(data.class_id.fees_data.additional_fees);

    // console.log("t-amoutn  - ",t_amt);
    // setTotal_amont(t_amt);

    const session = b_data.sessions.filter((branch) => {
      return branch.current;
    });

    const f_data = data.fees_entry.filter((fees, index) => {
      return fees.session === session[0].session_year;
    })[0];

    set_fees_entry(f_data);
  };

  // pay modal
  const [payModal, setPayModal] = useState(false);
  const [payDataObj, setPayDataObj] = useState({});
  const openPayModal = (obj, type, fees_data, s_id) => {
    setPayDataObj({ ...payDataObj, obj, type, fees_data, s_id });
    setPayModal(!payModal);
  };

  console.log("additional pay - ", payDataObj);

  const [filter, setFilter] = useState("all student fees");

  // excel export function
  const exportExcelFile = () => {
    const workbook = new excelJS.Workbook();
    let worksheet = workbook.addWorksheet("Students List");
    worksheet.properties.defaultRowHeight = 20;

    let index = 1;
    let colums = [
      {
        header: "Sr.No",
        key: "sr",
        width: 10,
      },
      // {
      //   header: "Name",
      //   key: "name",
      //   width: 10,
      // },
      {
        header: "Class",
        key: "class",
        width: 10,
      },
      {
        header: "Total Fees",
        key: "total_fees",
        width: 10,
      },
      {
        header: "Discount",
        key: "discount",
        width: 10,
      },
      {
        header: "Paid Fees",
        key: "paid_fees",
        width: 10,
      },
      {
        header: "Due Fees",
        key: "due_fees",
        width: 10,
      },
    ];

    createFormData &&
      createFormData.fields.map((f, i) => {
        const modifiedValue = f.name.replace(/enter|choose|select/gi, "");
        if (visibleField.some((vis_f) => vis_f === f.name)) {
          index = index + 1;
          colums.splice(index, 0, {
            header: modifiedValue,
            key: modifiedValue,
            width: 10,
          });
        }
      });

    worksheet.columns = colums;

    myState?.map((stu, i) => {
      const fees_data = stu.class_id.fees_data;
      let fees_entry = stu.fees_entry.filter((fees) => {
        return fees.session === sessoinData.session_year;
      });

      const t_amount = parseInt(
        fees_data.total_amount + fees_data.admission_fees + fees_data.exam_fees
      );

      if (fees_entry.length > 0) {
        fees_entry = fees_entry[0];
      }

      const out_standing = parseInt(fees_entry.out_standing);
      let total_paid = 0;
      let due_fees = 0;
      let discount = 0;
      fees_entry.entry.map((entry, j) => {
        total_paid += parseFloat(entry.total_paid);
        if (entry.discount_type) {
          discount += parseInt(entry.discount);
        } else {
          discount +=
            (parseInt(entry.amount) * parseFloat(entry.discount)) / 100;
        }
      });

      console.log(fees_entry.entry);

      due_fees = t_amount - discount - total_paid;

      let rowData = {
        sr: stu.sr_no,

        class: stu.class_id.c_name,
        total_fees: t_amount,
        discount: discount,
        paid_fees: total_paid,
        due_fees: due_fees,
      };

      stu.fields.map((f) => {
        const modifiedValue = f.name.replace(/enter|choose|select/gi, "");
        console.log("modified value - ", modifiedValue);
        rowData[modifiedValue] = f.value;
      });

      worksheet.addRow(rowData);
    });

    workbook.xlsx.writeBuffer().then((data) => {
      let url = window.URL.createObjectURL(
        new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        })
      );
      const a = document.createElement("a");
      a.href = url;
      a.download = "Students Fees List.xlsx";
      a.click();
      window.URL.revokeObjectURL(url);
    });
  };

  const onchangeShowField = (e, f_name) => {
    const { name, value, checked } = e.target;
    if (checked) {
      setVisibleFields((prevFields) => [...prevFields, f_name]);
    } else {
      setVisibleFields((prevFields) => prevFields.filter((f) => f !== f_name));
    }
  };

  const history = useNavigate();

  return (
    <>
      {payModal && (
        <PayModal
          feed_data={payDataObj}
          openEdit={openPayModal}
          b_data={b_data}
          payPrint={(res) => {
            openPayModal();
            setSelectStudent(res.data);
            dispatch(print_data_set(res.data));
            history("/admin/fees_data/print");
          }}
        />
      )}

      <div className="content-wrapper payments">
        <div className="container-fluid flex-grow-1 container-p-y">
          <div className="row">
            <div className="col-6">
              <ul className="slider_button slider-white plain-orange me-2">
                <li
                  className={` me-2 ${
                    filter.includes("all student fees") ? "slider-active" : ""
                  }`}
                  onClick={() => {
                    setFilter("all student fees");
                  }}
                >
                  All Student Fees
                </li>
                <li
                  className={` me-2 ${
                    filter.includes("pay_fees") ? "slider-active" : ""
                  }`}
                  onClick={() => {
                    setFilter("pay_fees");
                  }}
                >
                  Pay Fees
                </li>
              </ul>
            </div>
            <div className="col-6 d-flex justify-content-end">
              {filter === "all student fees" && (
                <>
                  <button
                    className="btn btn-primary me-3"
                    onClick={() => {
                      exportExcelFile();
                    }}
                  >
                    Excel
                  </button>
                  <button
                    className="btn btn-primary"
                    onClick={() => {
                      genereatePDF();
                    }}
                  >
                    PDF
                  </button>
                </>
              )}
            </div>
            {filter === "pay_fees" ? (
              <>
                <div className="col-lg-12 col-12 d-flex mt-4">
                  <div className="form-group">
                    <select
                      className="form-control"
                      name="student_data"
                      id=""
                      onChange={onStudentSelecte}
                    >
                      <option>Choose Sr.No.</option>
                      {myState.length > 0 &&
                        myState.map((student, index) => {
                          return (
                            <option key={index} value={JSON.stringify(student)}>
                              {student.sr_no} - {}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                </div>
                <div className="col-lg-12 mb-4 ">
                  <div className="row mt-4" style={{ textAlign: "left" }}>
                    <div className="col-lg-4 col-12 mb-4">
                      <div className="card">
                        <h6 className="card-header">Student info</h6>
                        <div
                          className="card-body"
                          style={{ maxHeight: 450, overflowY: "scroll" }}
                        >
                          <ul className="p-0 m-0 fees_list">
                            <li className="d-flex justify-content-between">
                              {" "}
                              <span className="fees_heading">
                                Sr. No. -{" "}
                              </span>{" "}
                              <span className="fee_value">
                                {selecteStudent && selecteStudent.sr_no}
                              </span>{" "}
                              <span className="fees_heading">
                                {selecteStudent &&
                                  selecteStudent.class_id.c_type}{" "}
                                -{" "}
                              </span>{" "}
                              <span className="fee_value">
                                {selecteStudent &&
                                  selecteStudent.class_id.c_name}
                              </span>{" "}
                              <span className="fees_heading">Hostel -</span>{" "}
                              <span className="fee_value">
                                {selecteStudent && selecteStudent.hostel
                                  ? "Yes"
                                  : "No"}
                              </span>{" "}
                            </li>
                            {selecteStudent &&
                              selecteStudent.fields.map((file, i) => {
                                return (
                                  <li
                                    key={i}
                                    className="d-flex justify-content-between"
                                  >
                                    {" "}
                                    <span className="fees_heading">
                                      {file.name} -{" "}
                                    </span>{" "}
                                    {isUrl(file.value) ? (
                                      <img
                                        src={file.value}
                                        className="img-fluid "
                                        width={25}
                                        height={25}
                                        alt={file.name}
                                      />
                                    ) : (
                                      <span className="fee_value">
                                        {file.value}
                                      </span>
                                    )}
                                  </li>
                                );
                              })}
                          </ul>
                        </div>
                      </div>
                    </div>

                    {selecteStudent && (
                      <div className="col-lg-8 col-12">
                        <div className="card">
                          <h6 className="card-header">Fees Details</h6>
                          <div className="card-body">
                            <div class="table-responsive text-nowrap mb-1">
                              <table
                                width={"100%"}
                                className="feed_details_table table table-hover myTableForm"
                              >
                                <tr>
                                  <th>Total Amount</th>
                                  <td>
                                    ₹
                                    {selecteStudent &&
                                      selecteStudent.class_id.fees_data
                                        .total_amount}
                                  </td>

                                  <th>Admission Fees</th>
                                  <td>
                                    ₹
                                    {selecteStudent &&
                                      selecteStudent.class_id.fees_data
                                        .admission_fees}
                                  </td>
                                </tr>

                                <tr>
                                  <th>Exam Fees</th>
                                  <td>
                                    ₹
                                    {selecteStudent &&
                                      selecteStudent.class_id.fees_data
                                        .exam_fees}
                                  </td>

                                  <th>Installment</th>
                                  <td>
                                    {selecteStudent &&
                                      selecteStudent.class_id.fees_data
                                        .installment}
                                  </td>
                                </tr>

                                <tr>
                                  <th colSpan={4} className="text-center">
                                    Fees -{" "}
                                    <span className="badge bg-danger">
                                      {fees_entry && fees_entry.out_standing}
                                    </span>
                                  </th>
                                </tr>

                                <tr>
                                  <th>Admission Fees</th>
                                  <td>
                                    ₹
                                    {selecteStudent &&
                                      selecteStudent.class_id.fees_data
                                        .admission_fees}
                                    {fees_entry &&
                                    fees_entry.entry.some(
                                      (fees) => fees.type === "admission_fees"
                                    ) ? (
                                      ""
                                    ) : (
                                      <span
                                        className="pay_btn mx-2"
                                        style={{ cursor: "pointer" }}
                                        onClick={() => {
                                          openPayModal(
                                            selecteStudent.class_id,
                                            "admission_fees",
                                            fees_entry,
                                            selecteStudent._id
                                          );
                                        }}
                                      >
                                        Pay
                                      </span>
                                    )}
                                  </td>
                                  <th>Exam Fees</th>
                                  <td>
                                    ₹
                                    {selecteStudent &&
                                      selecteStudent.class_id.fees_data
                                        .exam_fees}
                                    {fees_entry &&
                                    fees_entry.entry.some(
                                      (fees) => fees.type === "exam_fees"
                                    ) ? (
                                      ""
                                    ) : (
                                      <span
                                        className="pay_btn mx-2"
                                        style={{ cursor: "pointer" }}
                                        onClick={() => {
                                          openPayModal(
                                            selecteStudent.class_id,
                                            "exam_fees",
                                            fees_entry,
                                            selecteStudent._id
                                          );
                                        }}
                                      >
                                        Pay
                                      </span>
                                    )}
                                  </td>
                                </tr>

                                {selecteStudent &&
                                  (() => {
                                    const items = [];
                                    const value =
                                      parseFloat(
                                        selecteStudent.class_id.fees_data
                                          .total_amount
                                      ) /
                                      parseInt(
                                        selecteStudent.class_id.fees_data
                                          .installment
                                      );
                                    for (
                                      let i = 0;
                                      i <
                                      selecteStudent.class_id.fees_data
                                        .installment;
                                      i++
                                    ) {
                                      items.push(
                                        <tr key={i}>
                                          <th>Value</th>
                                          <td>
                                            ₹{Math.round(value * 100) / 100}
                                            {fees_entry &&
                                            fees_entry.entry.some((fees) => {
                                              return (
                                                fees.type === "installment" &&
                                                fees.index === i
                                              );
                                            }) ? (
                                              ""
                                            ) : (
                                              <span
                                                className="pay_btn mx-2"
                                                style={{ cursor: "pointer" }}
                                                onClick={() => {
                                                  openPayModal(
                                                    selecteStudent.class_id,
                                                    "installment_" + i,
                                                    fees_entry,
                                                    selecteStudent._id
                                                  );
                                                }}
                                              >
                                                Pay
                                              </span>
                                            )}
                                          </td>
                                        </tr>
                                      );
                                    }

                                    return items;
                                  })()}

                                <tr>
                                  <th colSpan={4} className="text-center">
                                    Additional Fees
                                  </th>
                                </tr>

                                {selecteStudent &&
                                  selecteStudent.class_id.additional_fees.map(
                                    (ad_fees, i) => {
                                      return (
                                        <>
                                          <tr key={i}>
                                            <th>{ad_fees.name}</th>
                                            <td>₹{ad_fees.amount}</td>
                                            <td>
                                              {fees_entry &&
                                              fees_entry.entry.some((fees) => {
                                                return (
                                                  fees.type.includes(
                                                    "additional"
                                                  ) && fees.index === i
                                                );
                                              }) ? (
                                                ""
                                              ) : (
                                                <span
                                                  className="pay_btn"
                                                  style={{ cursor: "pointer" }}
                                                  onClick={() => {
                                                    openPayModal(
                                                      selecteStudent.class_id,
                                                      "additional_" + i,
                                                      fees_entry,
                                                      selecteStudent._id
                                                    );
                                                  }}
                                                >
                                                  Pay
                                                </span>
                                              )}
                                            </td>
                                          </tr>
                                        </>
                                      );
                                    }
                                  )}

                                {selecteStudent && selecteStudent.hostel && (
                                  <>
                                    <tr>
                                      <th colSpan={4} className="text-center">
                                        Hostel Fees
                                      </th>
                                    </tr>

                                    {selecteStudent &&
                                      selecteStudent.class_id.hostel_fees.map(
                                        (ad_fees, i) => {
                                          return (
                                            <>
                                              <tr key={i}>
                                                <th>{ad_fees.name}</th>
                                                <td>₹{ad_fees.amount}</td>
                                                <td>
                                                  {fees_entry &&
                                                  fees_entry.entry.some(
                                                    (fees) => {
                                                      return (
                                                        fees.type.includes(
                                                          "additional"
                                                        ) && fees.index === i
                                                      );
                                                    }
                                                  ) ? (
                                                    ""
                                                  ) : (
                                                    <span
                                                      className="pay_btn"
                                                      style={{
                                                        cursor: "pointer",
                                                      }}
                                                      onClick={() => {
                                                        openPayModal(
                                                          selecteStudent.class_id,
                                                          "additional_" + i,
                                                          fees_entry,
                                                          selecteStudent._id
                                                        );
                                                      }}
                                                    >
                                                      Pay
                                                    </span>
                                                  )}
                                                </td>
                                              </tr>
                                            </>
                                          );
                                        }
                                      )}
                                  </>
                                )}
                              </table>
                            </div>
                          </div>

                          <div
                            className="card-footer"
                            style={{ textAlign: "right" }}
                          >
                            <div className="d-flex align-items-center justify-content-between">
                              <div>
                                <button
                                  className="btn btn-primary"
                                  onClick={() => {
                                    dispatch(print_data_set(selecteStudent));
                                    history("/admin/fees_data/print");
                                  }}
                                >
                                  Print Reciept
                                </button>
                              </div>

                              <div className="">
                                <span className="pe-2">Total - </span>
                                <span className="">
                                  ₹
                                  {selecteStudent &&
                                    parseFloat(
                                      selecteStudent.class_id.fees_data
                                        .total_amount
                                    ) +
                                      parseFloat(
                                        selecteStudent.class_id.fees_data
                                          .admission_fees
                                      ) +
                                      parseFloat(
                                        selecteStudent.class_id.fees_data
                                          .exam_fees
                                      ) +
                                      parseFloat(
                                        selecteStudent.class_id.additional_fees.reduce(
                                          (acc, item) => {
                                            return (
                                              acc + parseFloat(item.amount)
                                            );
                                          },
                                          0
                                        )
                                      ) +
                                      parseFloat(
                                        selecteStudent.hostel
                                          ? selecteStudent.class_id.hostel_fees.reduce(
                                              (acc, item) => {
                                                return (
                                                  acc + parseFloat(item.amount)
                                                );
                                              },
                                              0
                                            )
                                          : 0
                                      )}
                                </span>
                                {/* {fees_total_amount} */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    <div className="col-lg-12 col-12">
                      {selecteStudent && (
                        <div className="card mt-4">
                          <h5 className="card-header">Fees Entries</h5>
                          <div className="card-body">
                            <div className="table-responsive text-nowrap mb-1">
                              <table className="table table-hover">
                                <thead>
                                  <tr>
                                    <th>Reciept No.</th>
                                    <th>Date</th>
                                    <th>Amount</th>
                                    <th>Discount</th>
                                    <th>Late Fees</th>
                                    <th>Paid Amount</th>
                                    <th>Remaining</th>
                                    <th>Type</th>
                                    <th className="action hiddenOnPrint">
                                      Action
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {selecteStudent &&
                                    selecteStudent.fees_entry.map(
                                      (fees, index) => {
                                        return fees.entry.map((entry, j) => {
                                          return (
                                            <tr>
                                              <td>#{entry.ref_id}</td>
                                              <td>
                                                {entry.pay_date.split("T")[0]}
                                              </td>
                                              <td>{entry.amount}</td>
                                              <td>{entry.discount}</td>
                                              <td>{entry.late_fees}</td>
                                              <td>{entry.total_paid}</td>
                                              <td>{entry.remaining_amount}</td>
                                              <td>{entry.type}</td>
                                              <td className="action hiddenOnPrint">
                                                <span className="badge bg-primary">
                                                  <i className="fa fa-print"></i>
                                                </span>
                                              </td>
                                            </tr>
                                          );
                                        });
                                      }
                                    )}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <div className="col-lg-12 my-4">
                <div
                  className="showFields d-flex flex-wrap align-items-center"
                  style={{ fontSize: "11px" }}
                >
                  <span
                    data-toggle="tooltip"
                    data-html="true"
                    data-placement="top"
                    title="Clear Fields"
                    className="badge bg-danger me-2"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setVisibleFields([]);
                    }}
                  >
                    <i className="fa fa-close"></i>
                  </span>
                  <span className="me-2"> Visible Fields :</span>
                  {createFormData &&
                    createFormData.fields.map((f, i) => {
                      const modifiedValue = f.f_label.replace(
                        /enter|choose|select/gi,
                        ""
                      );

                      return (
                        <>
                          <div className="d-flex align-items-center mb-2">
                            <input
                              type="checkbox"
                              id={modifiedValue}
                              autocomplete="off"
                              checked={visibleField.some((vis_f, i) => {
                                return vis_f === f.name;
                              })}
                              name={modifiedValue}
                              value={modifiedValue}
                              onChange={(e) => {
                                onchangeShowField(e, f.name);
                              }}
                            />
                            <label
                              className="mx-2 "
                              style={{
                                fontSize: "12px",
                                "text-wrap": "nowrap",
                              }}
                            >
                              {modifiedValue}
                            </label>
                          </div>
                        </>
                      );
                    })}
                </div>

                {/* filter div */}
                <div className="border px-2 my-2 w-100 text-left d-flex align-items-center">
                  <span className="me-2">Class Filter - </span>
                  <div class="form-group w-25">
                    <select
                      class="form-control"
                      name="class_filter"
                      id=""
                      onChange={(e) => {
                        set_class_filter_id(e.target.value);
                        // onFilter("class", e.target.value);
                      }}
                    >
                      <option value={"all"}>All Class</option>
                      {class_data &&
                        class_data.map((c, i) => {
                          return (
                            <option key={i} value={c._id}>
                              {c.c_name}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                </div>
                <div
                  ref={pdfRef}
                  style={{ width: "100%" }}
                  class="table-responsive text-nowrap mb-1"
                >
                  <table
                    class="table table-hover"
                    style={{ textAlign: "left" }}
                  >
                    <thead>
                      <tr>
                        <th>Sr.No.</th>
                        {createFormData &&
                          createFormData.fields.map((f, i) => {
                            const modifiedValue = f.f_label.replace(
                              /enter|choose|select/gi,
                              ""
                            );

                            const match = visibleField.some((visf) => {
                              return visf === f.name;
                            });

                            return <>{match && <th>{modifiedValue}</th>}</>;
                          })}
                        <th>Class</th>
                        {login_user_data.config &&
                        login_user_data.config.hostel_module ? (
                          <th>Hostel Fees</th>
                        ) : (
                          <></>
                        )}
                        <th>Add. Fees</th>
                        <th>Total Fees</th>

                        <th>Discount</th>
                        <th>Paid Fees</th>
                        <th>Due Fees</th>
                      </tr>
                    </thead>
                    <tbody className="table-border-bottom-0 order_history_body">
                      {myState
                        .filter((stu) => {
                          return class_filter_id === "all"
                            ? true
                            : stu.class_id._id === class_filter_id;
                        })
                        .map((stu, index) => {
                          const fees_data = stu.class_id.fees_data;
                          const additional_fees = stu.class_id.additional_fees;
                          const hostel_fees_data = stu.class_id.hostel_fees;
                          let fees_entry = stu.fees_entry.filter((fees) => {
                            return fees.session === sessoinData.session_year;
                          });

                          let t_amount = parseInt(
                            fees_data.total_amount +
                              fees_data.admission_fees +
                              fees_data.exam_fees
                          );

                          if (fees_entry.length > 0) {
                            fees_entry = fees_entry[0];
                          }

                          const out_standing = parseInt(
                            fees_entry.out_standing
                          );
                          let total_paid = 0;
                          let due_fees = 0;
                          let discount = 0;
                          let hostel_fees = 0;
                          let add_fees = 0;

                          stu.hostel &&
                            hostel_fees_data.map((f, i) => {
                              hostel_fees += f.amount;
                            });

                          additional_fees.map((f, i) => {
                            add_fees += f.amount;
                          });

                          fees_entry.entry.map((entry, j) => {
                            total_paid += parseFloat(entry.total_paid);
                            if (entry.discount_type) {
                              discount += parseInt(entry.discount);
                            } else {
                              discount +=
                                (parseInt(entry.amount) *
                                  parseFloat(entry.discount)) /
                                100;
                            }
                          });

                          console.log(fees_entry.entry);
                          t_amount = t_amount + hostel_fees + add_fees;
                          due_fees = t_amount - discount - total_paid;

                          return (
                            <tr key={index}>
                              <td>{stu.sr_no}</td>

                              {stu.fields.map((f, j) => {
                                const match = visibleField.some((vis_f) => {
                                  return vis_f === f.name;
                                });
                                return (
                                  <>{match && <td key={j}>{f.value}</td>}</>
                                );
                              })}

                              <td>{stu.class_id.c_name}</td>
                              {login_user_data.config &&
                              login_user_data.config.hostel_module ? (
                                <td>₹{hostel_fees}</td>
                              ) : (
                                <></>
                              )}
                              <td>{add_fees}</td>
                              <td>
                                <b>₹{t_amount}</b>
                              </td>

                              <td>{discount}</td>
                              <td>{total_paid}</td>
                              <td>₹{due_fees}</td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default FeesPage;
