import { ALERT_ACTION, API_CALL_STATUS, PRINT_DATA } from "./constant";
import { fetchData, branchUploadSuccess, branchUploadFailure } from "./branch";
import {
  FormForCreateStudent,
  studentUploadSuccess,
  studentUploadFailure,
} from "./studentFormAction";
const showMyAlert = (msg, type) => (dispatch) => {
  dispatch(showAlertAction(msg, type));
  setTimeout(() => {
    dispatch(hideAlertAction());
  }, 3000);
};

const loaderAction = (status) => {
  if (status) {
    return {
      type: ALERT_ACTION.show_loader,
    };
  } else {
    return {
      type: ALERT_ACTION.hide_loader,
    };
  }
};

const showAlertAction = (msg, type) => {
  return {
    type: ALERT_ACTION.show,
    message: msg,
    alert_type: type,
  };
};
const hideAlertAction = () => {
  return {
    type: ALERT_ACTION.hide,
  };
};

const uploadSuccess = (res, type) => ({
  type: API_CALL_STATUS.success,
  payload: res,
  u_type: type,
});

const uploadFailure = () => ({
  type: API_CALL_STATUS.failed,
});

const toggleMenu = () => ({
  type: API_CALL_STATUS.toogleMenu,
});

const get_user_data = () => ({
  type: API_CALL_STATUS.get_user_data,
});

const set_user_data = () => ({
  type: API_CALL_STATUS.set_user_data,
});

// print action
const print_data_set = (data) => {
  return {
    type: PRINT_DATA.setObject,
    payload: data,
  };
};

export {
  fetchData,
  showAlertAction,
  uploadSuccess,
  uploadFailure,
  showMyAlert,
  branchUploadSuccess,
  branchUploadFailure,
  FormForCreateStudent,
  studentUploadSuccess,
  studentUploadFailure,
  toggleMenu,
  get_user_data,
  set_user_data,
  print_data_set,
  loaderAction,
};
