import React, { useState } from "react";
import { useSelector } from "react-redux";

const Profile = () => {
  const [showForgetPass, setshowForgetPass] = useState(false);
  const openForget = () => {
    setshowForgetPass(!showForgetPass);
  };
  const logged_user_data = useSelector((state) => state.set_login_user_data);

  const [newPassData, setNewPassData] = useState({
    oldPassword: "",
    newPassword: "",
  });

  const changePassword = (e) => {
    setNewPassData({ ...newPassData, [e.target.name]: e.target.value });
  };

  const changeClick = (e) => {
    e.preventDefault();

    if (!newPassData.oldPassword) {
      alert("Please enter old password");
    } else if (!newPassData.newPassword) {
      alert("Please enter new password");
    } else {
      if (!window.confirm("Are you sure, you want to update password?")) {
        return;
      } else {
        console.log(newPassData);
      }
    }
  };
  return (
    <>
      <div className="content-wrapper payments">
        <div className="container-xxl flex-grow-1 container-p-y">
          <div className="row">
            <div className="col-4">
              <div className="card">
                <h5 className="card-header">Profile Information</h5>
                <div className="card-body">
                  <div className="table-responsive text-nowrap mb-1">
                    <table
                      width={"100%"}
                      className="table table-hover"
                      style={{ textAlign: "left" }}
                    >
                      <tr>
                        <th>Name</th>
                        <td>{logged_user_data && logged_user_data.fullname}</td>
                      </tr>
                      <tr>
                        <th>Phone</th>
                        <td>{logged_user_data && logged_user_data.phone}</td>
                      </tr>
                      <tr>
                        <th>Email</th>
                        <td>{logged_user_data && logged_user_data.email}</td>
                      </tr>

                      <tr>
                        <th>Address</th>
                        <td>
                          {logged_user_data &&
                            logged_user_data.address +
                              " " +
                              logged_user_data.state +
                              " " +
                              logged_user_data.zipcode}
                        </td>
                      </tr>

                      <tr>
                        <th>Branch Limit</th>
                        <td>
                          {logged_user_data &&
                            logged_user_data.config.branch_limit}
                        </td>
                      </tr>
                      <tr>
                        <th>Storage Limit</th>
                        <td>
                          {logged_user_data &&
                            logged_user_data.config.storage_limit}
                        </td>
                      </tr>
                    </table>
                  </div>
                  <div
                    className="card-body p-0 pt-4"
                    style={{ textAlign: "left" }}
                  >
                    <p className="btn btn-primary" onClick={openForget}>
                      Forget Password
                    </p>
                  </div>
                </div>
              </div>
            </div>

            {showForgetPass && (
              <div className="col-8">
                <div className="card">
                  <h5 className="card-header">Forget Password</h5>
                  <div className="card-body">
                    <div className="d-flex">
                      <div class="form-group me-4" style={{ width: "50%" }}>
                        <label for="">Enter Old Password</label>
                        <input
                          type="text"
                          class="form-control"
                          name="oldPassword"
                          id=""
                          onChange={changePassword}
                          aria-describedby="helpId"
                          placeholder="Enter old password"
                        />
                      </div>
                      <div class="form-group" style={{ width: "50%" }}>
                        <label for="">Enter New Password</label>
                        <input
                          type="text"
                          class="form-control"
                          name="newPassword"
                          id=""
                          aria-describedby="helpId"
                          onChange={changePassword}
                          placeholder="Enter new password"
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className="card-footer py-0"
                    style={{ textAlign: "left" }}
                  >
                    <p className="btn btn-primary" onClick={changeClick}>
                      Change
                    </p>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Profile;
