import React from "react";

const PrivacyPolicy = () => {
  return (
    <>
      <div className="container my-5" style={{ textAlign: "left" }}>
        <div className="row">
          <div className="col-12">
            <h4>
              Privacy Policy for iVIDHAN - School, Coaching Management Software
            </h4>
            <p>
              "iVIDHAN - School, Coaching Management Software" is committed to
              protecting the privacy and security of your personal information.
              This Privacy Policy explains how we collect, use, and safeguard
              your personal data when you use our school management software.
            </p>
            <h5>Information We Collect</h5>

            <ul>
              <li>
                <strong>Personal Information:</strong> When you register for an
                account or use our software, we may collect personal information
                such as your name, email address, contact information, and other
                details necessary for providing our services.
              </li>
              <li>
                <strong>Student Information:</strong>
                Our software may collect and store student information such as
                student names, grades, attendance records, and other academic
                information. This information is collected to facilitate
                educational processes and improve school administration.
              </li>
              <li>
                <strong>User Activity:</strong>
                We may collect information about your interactions with our
                software, including log data, usage data, and cookies, to
                analyze usage patterns and improve our services.
              </li>
            </ul>

            <h5>How We Use Your Information</h5>
            <ul>
              <li>
                <strong>Providing Services:</strong> We use the information we
                collect to provide and improve our school management software,
                including facilitating communication between students, parents,
                teachers, and administrators, managing academic records, and
                optimizing administrative processes.
              </li>
              <li>
                <strong>Communication:</strong> We may use your contact
                information to send you important updates, announcements, and
                notifications related to our software and your account.
              </li>
              <li>
                <strong>Analytics:</strong> We may use aggregated and anonymized
                data for analytics purposes to understand how our software is
                used and to improve its functionality and user experience.
              </li>
            </ul>

            <h5>Data Security</h5>
            <p>
              We are committed to protecting the security of your personal
              information and have implemented technical and organizational
              measures to safeguard your data against unauthorized access, use,
              or disclosure.
            </p>

            <h5>Data Sharing</h5>
            <p>
              We do not sell, trade, or rent your personal information to third
              parties. However, we may share your information with trusted
              third-party service providers who assist us in operating our
              software or conducting our business, subject to strict
              confidentiality obligations.
            </p>
            <h5>Your Rights</h5>
            <p>
              You have the right to access, update, or delete your personal
              information stored in our software. If you have any questions or
              concerns about your privacy or data protection, please contact us
              at Ph. - +91 6396617714.
            </p>
            <h5>Changes to this Privacy Policy</h5>
            <p>
              We may update this Privacy Policy from time to time to reflect
              changes in our practices or legal requirements. We encourage you
              to review this Privacy Policy periodically for any updates.
            </p>
            <h5>Contact Us</h5>
            <p>
              If you have any questions or concerns about this Privacy Policy or
              our data practices, please contact us at Ph. - +91 6396617714.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default PrivacyPolicy;
