import uploadImage from "./imageUpload";
import fetchDataFromLocal from "./branchReducer";
import branchArrayState from "./branchState";
import getStudentData from "./studentReducer";
import classData from "./class/classReducer";
import set_print_object from "./print/PrintDataReucer";

import { currentSession } from "./branchReducer";
import { combineReducers } from "redux";
import { showAlert, loader } from "./frontEndState";

import CreateStudentForm from "./studentForm";
import { API_CALL_STATUS } from "../action/constant";

const toogleMenuReducer = (state = false, action) => {
  // return state
  switch (action.type) {
    case API_CALL_STATUS.toogleMenu:
      state = !state;
      return state;
    default:
      return state;
  }
};

const set_login_user_data = (state = {}, action) => {
  switch (action.type) {
    case API_CALL_STATUS.set_user_data:
      state = action.payload;
      return state;
    default:
      return state;
  }
};

const rootReducer = combineReducers({
  uploadImage,
  fetchDataFromLocal,
  branchArrayState,
  currentSession,
  showAlert,
  loader,
  getStudentData,
  CreateStudentForm,
  classData,
  toogleMenuReducer,
  set_login_user_data,
  set_print_object,
});

export default rootReducer;
