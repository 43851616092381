import React, { useCallback, useContext, useEffect, useState } from "react";
import { AddClass, SubjectList } from "./SubjectList";
import "./Subject.css";
import { ClassCon } from "../../context/AllContext";
import { AdditionalFees, Fees_add, HostelFees, SetSubjects } from "./Fees_add";
import { useDispatch, useSelector } from "react-redux";
import {
  GetClassServer,
  UpdateClassData,
  deleteClassData,
} from "../../redux/reducer/class/classReducer";
import { fetchDataFromLocal } from "../../redux/reducer/branchReducer";
export const Class_Subject = (props) => {
  const context = useContext(ClassCon);

  const dispatch = useDispatch();

  const b_data = useSelector((state) => state.fetchDataFromLocal);
  const class_data = useSelector((state) => state.classData);
  const set_login_user_data = useSelector((state) => state.set_login_user_data);
  const { addClass, deleteClass } = context;

  const [query, setQuery] = useState("");

  const [showSubjectList, setShowSubjectList] = useState(false);
  const openSubjectModal = () => {
    setShowSubjectList(!showSubjectList);
  };

  const [showAddClass, setShowAddClass] = useState(false);
  const openClassModal = () => {
    setShowAddClass(!showAddClass);
  };

  // get data
  useEffect(() => {
    dispatch(fetchDataFromLocal());
  }, []);

  useEffect(() => {
    if (b_data._id !== undefined) {
      dispatch(GetClassServer({ b_id: b_data._id }));
    }
  }, [b_data]);

  const [selectedData, setSelectedData] = useState(null);

  // fees modal
  const [fees_modal, set_fees_modal] = useState(false);
  const openFeesModal = () => {
    set_fees_modal(!fees_modal);
  };

  // Additional fees
  const [add_fees_modal, set_add_fees_modal] = useState(false);
  const openAddFeesModal = () => {
    set_add_fees_modal(!add_fees_modal);
  };

  // Hostel fees
  const [add_hostel_fees, set_hostel_fees] = useState(false);
  const openAddHostelFees = () => {
    set_hostel_fees(!add_hostel_fees);
  };

  // subjects save
  const [set_subject_modal, set_set_subject_modal] = useState(false);
  const openSetSubjects = () => {
    set_set_subject_modal(!set_subject_modal);
  };

  // update sr no
  const [update_sr_no, set_update_sr_no] = useState(0);
  const onInputUpdate = (e) => {
    set_update_sr_no(e.target.value);
  };

  const onSrUpdateClick = () => {
    console.log("update sr click - ", update_sr_no);
    console.log("select class data - ", selectedData);
    dispatch(
      UpdateClassData({ class_id: selectedData._id }, { sr_no: update_sr_no })
    );
  };

  return (
    <>
      {set_subject_modal && (
        <SetSubjects
          openModal={openSetSubjects}
          s_data={selectedData}
          updateSData={(d) => {
            console.log(d.data);
            setSelectedData(d);
          }}
        />
      )}

      {showAddClass && (
        <AddClass
          b_id={b_data._id}
          openEdit={openClassModal}
          showAlert={props.showAlert}
          addClass={addClass}
        />
      )}

      {fees_modal && (
        <Fees_add
          openModal={openFeesModal}
          s_data={selectedData}
          updateSData={(d) => {
            console.log(d.data);
            setSelectedData(d);
          }}
        />
      )}

      {add_fees_modal && (
        <AdditionalFees
          openModal={openAddFeesModal}
          s_data={selectedData}
          updateSData={(d) => {
            console.log(d.data);
            setSelectedData(d);
          }}
        />
      )}

      {add_hostel_fees && (
        <HostelFees
          openModal={openAddHostelFees}
          s_data={selectedData}
          updateSData={(d) => {
            console.log(d.data);
            // setSelectedData(d);
          }}
        />
      )}

      {showSubjectList && (
        <SubjectList openEdit={openSubjectModal} showAlert={props.showAlert} />
      )}
      <div className="content-wrapper payments">
        <div className="container-fluid flex-grow-1 container-p-y">
          <div className="row">
            <div className="col-lg-12 mb-4 ">
              <div className="row">
                <div className="col-lg-6 col-12 mb-2 mb-lg-0 font-bold">
                  <ul className="slider_button slider-white plain-orange">
                    <li
                      className="slider-active me-2"
                      onClick={() => {
                        openClassModal();
                        // history("/admin/addNewBranch");
                      }}
                    >
                      + Add Class
                    </li>

                    <li
                      className="slider-active me-2"
                      onClick={() => {
                        openSubjectModal();
                        // history("/admin/addNewBranch");
                      }}
                    >
                      All Subjects
                    </li>
                  </ul>
                </div>
                <div className="col-lg-6 col-12 text-right">
                  <input
                    type="text"
                    className="form-control search"
                    placeholder="Search class..."
                    onChange={(e) => {
                      setQuery(e.target.value);
                    }}
                  />
                </div>
              </div>

              <div className="row mt-4">
                <div className="col-12 col-lg-4 ">
                  <div class="table-responsive text-nowrap mb-1">
                    <table
                      class="table table-hover"
                      style={{ textAlign: "left" }}
                    >
                      <thead className="order_history_header">
                        <tr>
                          <th>Class/ Course</th>
                          <th className="action">Action</th>
                        </tr>
                      </thead>
                      <tbody class="table-border-bottom-0 order_history_body">
                        {class_data
                          .filter((classd) => {
                            return classd.c_name
                              .toLowerCase()
                              .includes(query.toLowerCase());
                          })
                          .map((clas, i) => {
                            return (
                              <>
                                <tr>
                                  <td>{clas.c_name}</td>
                                  <td className="action">
                                    <span
                                      className="badge bg-primary me-1"
                                      onClick={() => {
                                        setSelectedData(clas);
                                      }}
                                    >
                                      <i className="fa fa-eye"></i>
                                    </span>
                                    <span className="badge bg-secondary me-1">
                                      <i className="fa fa-edit"></i>
                                    </span>

                                    <span
                                      className="badge bg-danger"
                                      onClick={() => {
                                        dispatch(deleteClassData(clas._id));
                                        // deleteClass(clas._id);
                                      }}
                                    >
                                      <i className="fa fa-trash"></i>
                                    </span>
                                  </td>
                                </tr>
                              </>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                </div>
                {selectedData && (
                  <div className="col-12 col-lg-8 ">
                    <div className="card" style={{ textAlign: "left" }}>
                      <h6 className="card-header text-black">
                        Class/Course Detail
                      </h6>
                      <div className="card-body">
                        <div class="table-responsive text-nowrap mb-1">
                          <table
                            class="table table-hover text-black"
                            style={{ textAlign: "left" }}
                          >
                            <tbody>
                              <tr>
                                <th>Set Sr. No. </th>
                                <td>{selectedData.sr_inc}</td>
                                <td>
                                  <input
                                    type="number"
                                    className="form-control"
                                    placeholder={`set sr no - ${selectedData.sr_inc}`}
                                    onChange={onInputUpdate}
                                    // defaultValue={selectedData.sr_inc}
                                  />
                                </td>
                                <td>
                                  <span
                                    className="badge bg-primary"
                                    style={{ cursor: "pointer" }}
                                    onClick={onSrUpdateClick}
                                  >
                                    Set Sr. No.
                                  </span>
                                </td>
                                
                              </tr>

                              <tr>
                                <th>Name -</th>
                                <td>{selectedData.c_name}</td>
                                <th>Type -</th>
                                <td>{selectedData.c_type}</td>
                              </tr>
                              <tr>
                                <th>Duration -</th>
                                <td>{selectedData.c_duration}</td>
                                <th>Branch -</th>
                                <td>---</td>
                              </tr>

                              {/* fees data */}
                              <tr>
                                <th colSpan={4} className="text-center">
                                  Fees Details
                                  <span
                                    className="badge bg-success mx-2"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      openFeesModal();
                                    }}
                                  >
                                    {selectedData.fees_data.total_amount != 0
                                      ? "Edit"
                                      : "Add"}
                                  </span>
                                </th>
                              </tr>
                              <tr>
                                <th>Total Amount -</th>
                                <td>
                                  <b>
                                    {selectedData.fees_data.total_amount} /-
                                  </b>
                                </td>

                                <th>Installment -</th>
                                <td>
                                  <b>{selectedData.fees_data.installment} /-</b>
                                </td>
                              </tr>

                              <tr>
                                <th>Exam Fees -</th>
                                <td>
                                  <b>{selectedData.fees_data.exam_fees} /-</b>
                                </td>

                                <th>Admission Fees -</th>
                                <td>
                                  <b>
                                    {selectedData.fees_data.admission_fees} /-
                                  </b>
                                </td>
                              </tr>

                              {set_login_user_data.config.hostel_module && (
                                <tr>
                                  <th colSpan={4} className="text-center">
                                    Hostel Fees{" "}
                                    <span
                                      className="badge bg-success mx-2"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        openAddHostelFees();
                                      }}
                                    >
                                      Add
                                    </span>
                                  </th>
                                </tr>
                              )}

                              {set_login_user_data.config.hostel_module &&
                                selectedData.hostel_fees.map((a_fees, i) => {
                                  return (
                                    <>
                                      <tr>
                                        <th>Name -</th>
                                        <td>{a_fees.name}</td>

                                        <th>Amount -</th>
                                        <td>{a_fees.amount} /-</td>
                                      </tr>
                                    </>
                                  );
                                })}

                              {/* Admission fees */}
                              <tr>
                                <th colSpan={4} className="text-center">
                                  Additional Fees{" "}
                                  <span
                                    className="badge bg-success mx-2"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      openAddFeesModal();
                                    }}
                                  >
                                    Add
                                  </span>
                                </th>
                              </tr>

                              {selectedData.additional_fees.map((a_fees, i) => {
                                return (
                                  <>
                                    <tr>
                                      <th>Name -</th>
                                      <td>{a_fees.name}</td>

                                      <th>Amount -</th>
                                      <td>{a_fees.amount} /-</td>
                                    </tr>
                                  </>
                                );
                              })}

                              <tr>
                                <th colSpan={4} className="text-center">
                                  Subjects{" "}
                                  <span
                                    className="badge bg-danger mx-2"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      openSetSubjects();
                                    }}
                                  >
                                    Set
                                  </span>
                                </th>
                              </tr>
                              <tr>
                                <th colSpan="4">
                                  <ul className="subject_list d-flex m-0 p-0">
                                    {selectedData.subjects.map((sub, i) => {
                                      return (
                                        <li className="bg-primary me-2">
                                          {sub.sub_id && sub.sub_id.sub_name}
                                        </li>
                                      );
                                    })}
                                  </ul>
                                </th>
                              </tr>

                              <tr>
                                <th colSpan={4} className="text-center">
                                  Exam Details{" "}
                                </th>
                              </tr>

                              <tr>
                                <th colSpan="4">
                                  <ul className="subject_list d-flex m-0 p-0">
                                    {selectedData.exam_data.map((exam, i) => {
                                      return (
                                        <li className="bg-secondary me-2">
                                          {exam.e_label} - {exam.e_total}
                                        </li>
                                      );
                                    })}
                                  </ul>
                                </th>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
