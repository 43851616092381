import React, { useState } from "react";

const AddFieldModal = ({ openEdit, addDataInArray }) => {
  const style = {
    background: "#00000080",
    display: "block",
  };

  const [addField, setAddField] = useState({
    name: "",
    f_label: "",
    f_type: "text",
    placeholder: "",
    radio_value: [],
  });

  const [r_value, set_r_value] = useState([]);

  const [radioValue, setRadioValue] = useState({
    id: 0,
    name: "",
    value: "",
  });

  const [valueDiv, setValueDiv] = useState(false);

  const onInput = (e) => {
    setAddField({ ...addField, [e.target.name]: e.target.value });

    const { name, value } = e.target;

    if (name === "f_type") {
      if (value === "dropdown") {
        setValueDiv(true);
        set_r_value([
          ...r_value,
          {
            id: 0,
            name: "",
            value: "",
          },
        ]);
      } else if (value === "radio") {
        setValueDiv(true);
      }
    }
  };

  const [addValue, setaddValue] = useState(0);

  const addMoreValue = () => {
    let value = addValue;
    setaddValue(value++);

    console.log(addValue);
    set_r_value([
      ...r_value,
      {
        id: r_value.length,
        name: "",
        value: "",
      },
    ]);
  };

  const removeMoreValue = (id) => {
    console.log(id);
    set_r_value(
      r_value.filter((r, i) => {
        return r.id != id;
      })
    );
  };

  const valueInput = (e, id) => {
    const data = r_value.map((v) => {
      return v.id === id ? { ...v, [e.target.name]: e.target.value } : v;
    });

    set_r_value(data);

    setAddField({...addField, radio_value:data})
  };

  return (
    <div className="modal reviewModal" style={style}>
      <div className="modal-dialog fields_dialog" >
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              Add Field
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={openEdit}
            ></button>
          </div>
          <div className="modal-body" style={{ textAlign: "left" }}>
            <div className="">
              <div class="form-group">
                <label for="">Field Type</label>
                <select
                  class="form-control mb-2"
                  name="f_type"
                  id=""
                  onChange={onInput}
                >
                  <option value={"text"}>Text</option>
                  <option value={"number"}>Number</option>
                  <option value={"date"}>Date</option>
                  <option value={"File"}>File</option>
                  <option value={"Checkbox"}>Checkbox</option>
                  <option value={"dropdown"}>Dropdown</option>
                  <option value={"radio"}>Radio Button</option>
                </select>
              </div>

              <div class="form-group mb-2">
                <label for="">Enter Field Unique Name</label>
                <input
                  type="text"
                  class="form-control"
                  name="name"
                  id=""
                  onChange={onInput}
                  aria-describedby="helpId"
                  placeholder="Enter field unique name...."
                />
                <small id="helpId" class="form-text text-muted">
                  You should not use any special character.
                </small>
              </div>

              <div class="form-group mb-2">
                <label for="">Field Label</label>
                <input
                  type="text"
                  class="form-control"
                  name="f_label"
                  id=""
                  onChange={onInput}
                  aria-describedby="helpId"
                  placeholder="Enter label"
                />
              </div>
              <div class="form-group mb-2">
                <label for="">Field Placeholder</label>
                <input
                  type="text"
                  class="form-control"
                  name="placeholder"
                  id=""
                  onChange={onInput}
                  aria-describedby="helpId"
                  placeholder="Enter placeholder"
                />
              </div>

              {valueDiv &&
                r_value.map((v, i) => {
                  return (
                    <>
                      <div className="row" key={i}>
                        <div className="col-6">
                          <div class="form-group">
                            <label for="">Name</label>
                            <input
                              type="text"
                              class="form-control"
                              name="name"
                              id=""
                              aria-describedby="helpId"
                              placeholder=""
                              onChange={(e) => {
                                valueInput(e, v.id);
                              }}
                            />
                          </div>
                        </div>

                        <div className="col-6">
                          <div class="form-group">
                            <label for="">Value</label>
                            <input
                              type="text"
                              class="form-control"
                              name="value"
                              id=""
                              aria-describedby="helpId"
                              placeholder=""
                              onChange={(e) => {
                                valueInput(e, v.id);
                              }}
                            />

                            <div className="d-flex justify-content-end pt-2">
                              <span
                                className="badge bg-success me-2"
                                onClick={() => {
                                  addMoreValue();
                                }}
                              >
                                <i className="fa fa-plus"></i>
                              </span>
                              <span
                                className="badge bg-danger"
                                onClick={() => {
                                  removeMoreValue(v.id);
                                }}
                              >
                                <i className="fa fa-trash"></i> {v.id}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })}

              <button
                className="btn btn-primary mt-3"
                onClick={() => {
                  addDataInArray(addField);
                }}
              >
                Create
              </button>
            </div>
          </div>
          <div className="modal-footer"></div>
        </div>
      </div>
    </div>
  );
};

export default AddFieldModal;
