import serverCallFuction from "../constantFunction";
import { API_CALL_STATUS } from "../action/constant";
import {
  uploadSuccess,
  uploadFailure,
  showAlertAction,
  showMyAlert,
} from "../action";

const initialState = [];

// UPLOADIDCARD

export const uploadImage = (payload) => async (dispatch) => {
  try {
    const res = await serverCallFuction("POST", "image/addImage", payload);
    // console.log(res);
    console.log(payload);

    if (res.status === "Failed") {
      dispatch(uploadFailure());
      dispatch(showMyAlert(res.message, "danger"));
      // dispatch(showAlertAction(res.message, "warning"));
      // You can also dispatch showAlert action here if needed
    } else {
      dispatch(uploadSuccess(res, "add"));
      dispatch(showMyAlert(res.message, "success"));
      // You can also dispatch showAlert action here if needed
    }
  } catch (error) {
    console.error("Error uploading image:", error);
    dispatch(uploadFailure());
    // You can also dispatch showAlert action here if needed
  }
};

export const uploadImageConfig = (payload, params) => async (dispatch) => {
  try {
    const res = await serverCallFuction(
      "PUT",
      `image/${params.image_id}`,
      payload
    );
    // console.log(res);

    if (res.status === "Success") {
      dispatch(uploadSuccess(res, "configue"));
      dispatch(showMyAlert(res.message, "success"));
      // You can also dispatch showAlert action here if needed
    } else {
      dispatch(uploadFailure());
      dispatch(showMyAlert(res.message, "danger"));
      // dispatch(showAlertAction(res.message, "warning"));
      // You can also dispatch showAlert action here if needed
    }
  } catch (error) {
    console.error("Error uploading image:", error);
    dispatch(uploadFailure());
  }
};

const uploadImageReducer = (state = initialState, action) => {
  switch (action.type) {
    case API_CALL_STATUS.success:
      console.log("Success result");
      // Handle success if needed
      if (action.u_type === "get") {
        state = action.payload.data;
      } else if (action.u_type === "add") {
        state.push(action.payload.data);
      } else if (action.u_type === "configue") {
        console.log("old state - ", state);
        state = state.map((s) => {
          return s._id === action.payload.data._id ? action.payload.data : s;
        });
        console.log(action.payload.data);
        console.log("old state - ", state);
      }

      return state;

    case API_CALL_STATUS.failed:
      // Handle failure if needed
      return state;

    default:
      return state;
  }
};

export const getImdageData = (payload) => async (dispatch) => {
  try {
    const res = await serverCallFuction("GET", `image/${payload.b_id}`);
    console.log(res);

    if (res.status === "Failed") {
      dispatch(uploadFailure());
      dispatch(showMyAlert(res.message, "danger"));
      // dispatch(showAlertAction(res.message, "warning"));
      // You can also dispatch showAlert action here if needed
    } else {
      dispatch(uploadSuccess(res, "get"));
      dispatch(showMyAlert(res.message, "success"));
      // You can also dispatch showAlert action here if needed
    }
  } catch (error) {
    console.error("Error uploading image:", error);
    dispatch(uploadFailure());
    // You can also dispatch showAlert action here if needed
  }
};

export default uploadImageReducer;
