import { ALERT_ACTION } from "../action/constant";

const alert = {};
const preLoader = "";

const loader = (state = preLoader, action) => {
  switch (action.type) {
    case ALERT_ACTION.show_loader:
      state = "preShow";
      return state;
    case ALERT_ACTION.hide_loader:
      state = "";
      return state;
    default:
      return state;
  }
};

const showAlert = (state = alert, action) => {
  switch (action.type) {
    case ALERT_ACTION.show:
      //   setTimeout(() => {
      //     setAlert(null);
      //   }, 3000);
      console.log("reducer call for alert");

      state = {
        msg: action.message,
        type: action.alert_type,
        active: "alert-active",
      };
      console.log(state);

      return state;
    case ALERT_ACTION.hide:
      state = {};
      return state;
    default:
      return state;
  }
};
export { showAlert, loader };
