import { useContext, useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import Alert from "./componant/Alert";
import Navbar from "./section/Navbar";
import TopBar from "./section/TopBar";
import MediaState from "./context/Media/MediaState";

// css import
import "./assets/vendor/fonts/boxicons.css";
import "./assets/vendor/css/core.css";
import "./assets/vendor/css/theme-default.css";
import "./assets/css/demo.css";
import "./assets/vendor/libs/perfect-scrollbar/perfect-scrollbar.css";
import "./assets/vendor/libs/apex-charts/apex-charts.css";

import { useDispatch, useSelector } from "react-redux";

import { fetchDataFromLocal } from "./redux/reducer/branchReducer";
import { API_CALL_STATUS } from "./redux/action/constant";
import { dateDiffrent } from "../globalFunction/CheckDateDiffer";

function AdminLayout() {
  const dispatch = useDispatch();
  const toogleMenu = useSelector((state) => state.toogleMenuReducer);

  const location = useLocation();

  const [userData, setUserData] = useState(null);

  let days = 0;
  const [daysCount, setDaysCount] = useState(0);

  useEffect(() => {
    if (toogleMenu) {
      dispatch({ type: API_CALL_STATUS.toogleMenu });
    }
  }, [location]);

  useEffect(() => {
    // fetData()
    dispatch(fetchDataFromLocal());
  }, []);
  const navigation = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token === null) {
      navigation("../auth");
    } else {
      const admindata = JSON.parse(localStorage.getItem("admin_data"));
      setUserData(admindata);

      dispatch({
        type: API_CALL_STATUS.set_user_data,
        payload: admindata,
      });

      console.log("admin data - ", admindata);
      if (admindata) {
        if (admindata.free_trial) {
          days = dateDiffrent(admindata.createdAt);
          if (days > 7) {
            alert("Your free trial is expired");
            navigation("/");
            localStorage.clear();
          }

          setDaysCount(7 - days);
        } else {
          if (admindata.subscribe_id) {
            days = dateDiffrent(admindata.subs_at);
            console.log("days su - " + days);
            if (admindata.subscribe_id.duration === "1 Month" && days > 30) {
              alert("Your current plan is expired");
              localStorage.clear();
              navigation("/");
            } else if (
              admindata.subscribe_id.duration === "1 Year" &&
              days > 365
            ) {
              alert("Your current plan is expired");
              navigation("/");
            } else if (
              admindata.subscribe_id.duration === "5 Year" &&
              days > 1825
            ) {
              alert("Your current plan is expired");
              navigation("/");
            } else {
              switch (admindata.subscribe_id.duration) {
                case "1 Month":
                  setDaysCount(30 - days);
                  break;
                case "1 Year":
                  setDaysCount(365 - days);
                  break;
                case "5 Year":
                  setDaysCount(1825 - days);
                default:
                  break;
              }
            }
          } else {
            alert("Your free trial is expired - ");
            navigation("/");
          }
        }
      }
    }
  }, []);

  const openMenu = () => {
    if (toogleMenu) {
      dispatch({ type: API_CALL_STATUS.toogleMenu });
    }
  };

  const [showPlan, setShowPlan] = useState(false);
  const tooglePlanDiv = () => {
    setShowPlan(!showPlan);
  };

  return (
    <>
      <MediaState>
        {/* <PostState> */}
        <div className="layout-wrapper layout-content-navbar">
          <div className="layout-container">
            <Navbar />
            <div className="layout-page" onClick={openMenu}>
              <TopBar />
              <Outlet />

              {userData && (userData.subscribe_id || userData.free_trial) && (
                <div className={`fixedPlanInfo ${showPlan && "d-none"}`}>
                  <span
                    className="badge bg-secondary close_btn"
                    onClick={tooglePlanDiv}
                  >
                    <i className="fa fa-close"></i>
                  </span>

                  <ul className="d-flex m-0 p-0 justify-content-end">
                    <li className="pe-0 pe-lg-5">
                      <span className="planInfo px-1">
                        Your Current Plan -{" "}
                      </span>
                      <span className="planInfo">
                        <b>
                          {userData.subscribe_id &&
                            userData.subscribe_id.planName}{" "}
                          {userData.free_trial && "Free Trial"}
                        </b>
                      </span>
                    </li>
                    <li>
                      <span className="planInfo">Active Till - </span>
                      <span className="planInfo px-1">
                        <b> {`${daysCount} Days Left`}</b>
                      </span>
                    </li>
                  </ul>
                </div>
              )}
            </div>
          </div>
        </div>
        {/* </PostState> */}
      </MediaState>
    </>
  );
}

export default AdminLayout;
