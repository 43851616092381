import React, { useState } from "react";
import { SketchPicker } from "react-color";
const ColorPicker = ({choosedColor, old_color}) => {
  const [color, setColor] = useState(old_color); // Initial color, you can set any valid color here

  const handleChange = (newColor) => {
    setColor(newColor.hex);
    choosedColor(newColor.hex)
  };

  const [show, setShow] = useState(false)

  return (
    <>
    <span onClick={()=>{
        setShow(true)
    }}>{color}</span>
    {
        show && <div
        style={{
          position: "absolute",
          right: "0",
        }}
      >
          <span style={{position:"absolute", top:"-2%", left:"-2%", cursor:"pointer"}} onClick={()=>{setShow(false)}}><i className="fa fa-close"></i></span>
        <SketchPicker color={color} onChange={handleChange} />
      </div>
    }
    </>
  );
};

export default ColorPicker;
