import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AddFieldModal from "./AddFieldModal";
import { StudentFormCon } from "../../context/AllContext";
import "./StudentCss.css";
export const Add_S_From = (props) => {
  const [query, setQuery] = useState("");
  const history = useNavigate();

  const context = useContext(StudentFormCon);

  const { byIdForm, studentForms, addform, getData, getAllDataByB_id } =
    context;

  useEffect(() => {
    getAllDataByB_id();
  }, []);

  const [fieldData, setFieldData] = useState([]);

  const [showAddModal, setShowAddModal] = useState(false);

  const openModal = () => {
    setShowAddModal(!showAddModal);
  };

  const addDataInArray = (obj) => {
    console.log(obj);
    setFieldData([...fieldData, obj]);
    openModal();
  };

  const callServer = async () => {
    const res = await addform(fieldData);
    if (res.status === "Success") {
      props.showAlert(res.message, "success");
    } else {
      props.showAlert(res.message, "danger");
    }
  };

  const removeField = (name) => {
    setFieldData(
      fieldData.filter((f) => {
        return f.name != name;
      })
    );
  };

  console.log(byIdForm);

  return (
    <>
      {showAddModal && (
        <AddFieldModal openEdit={openModal} addDataInArray={addDataInArray} />
      )}

      <div className="content-wrapper payments">
        <div className="container-xxl flex-grow-1 container-p-y">
          <div className="row">
            <div className="col-lg-12 mb-4 ">
              <div className="row mt-4" style={{ textAlign: "left" }}>
                <div className="col-12 col-lg-8">
                  {studentForms.map((f, i) => {
                    return (
                      <>
                        <div className="card mb-4" key={i}>
                          <div className="card-body">
                            <div className="row">
                              {f.fields.map((field, f) => {
                                return (
                                  <div className="col-12 col-lg-6 form_list">
                                    <div className="c_div">
                                      <div className="d-flex justify-content-between">
                                        <span>{field.f_label}</span>
                                        <span>{field.f_type}</span>
                                      </div>
                                      <div className="d-flex justify-content-between">
                                        <span>{field.name}</span>
                                        <span>{field.placeholder}</span>
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}

                              <small className=" my-delete">
                                Instruction - This form is only active in your
                                default branch and active session.
                              </small>

                              <div className="col-12 col-lg-6">
                                <label>Session - </label>{" "}
                                <span>{f.session}</span>
                              </div>
                            </div>
                          </div>
                          <div className="card-footer">
                            {f.editStatus && (
                              <button className="btn btn-primary">
                                Final Submit
                              </button>
                            )}
                          </div>
                        </div>
                      </>
                    );
                  })}
                </div>
                <div className="col-12 col-lg-4">
                  <div className="card">
                    <h5 className="card-header">
                      Create Form{" "}
                      <span
                        className="badge bg-primary mx-4"
                        onClick={openModal}
                        style={{ cursor: "pointer" }}
                      >
                        {" "}
                        + Field{" "}
                      </span>
                    </h5>
                    <div className="card-body">
                      <ul className="m-0 p-0">
                        {fieldData.map((f, i) => {
                          return (
                            <>
                              <li className="field_list_li">
                                {" "}
                                <div className=" d-flex justify-content-between">
                                  <span>{f.name}</span>
                                  <span>{f.f_label}</span>
                                </div>
                                <div className=" d-flex justify-content-between">
                                  <span>{f.f_type}</span>
                                  <span>{f.placeholder}</span>
                                </div>
                                <span
                                  className="remove_list_item"
                                  onClick={() => {
                                    removeField(f.name);
                                  }}
                                >
                                  <i className="fa fa-close"></i>
                                </span>
                              </li>
                            </>
                          );
                        })}
                      </ul>
                    </div>
                    <div className="card-footer">
                      <button className="btn btn-primary" onClick={callServer}>
                        Save
                      </button>
                    </div>
                  </div>


                  <div className="card mt-3">
                    <h5 className="card-header">
                      Student Document Setup
                    </h5>

                    <div className="card-body m-0 p-0">
                      <ul className="d-flex flex-wrap">
                        <li className="d-flex " style={{fontSize:"10px", width:"50%"}} ><input type="checkbox" className="me-2" name="doc[]" /> Student Image</li>
                        <li className="d-flex " style={{fontSize:"10px", width:"50%"}} ><input type="checkbox" className="me-2" name="doc[]" /> Father Imag</li>
                        <li className="d-flex " style={{fontSize:"10px", width:"50%"}} ><input type="checkbox" className="me-2" name="doc[]" /> Mother Image</li>
                        <li className="d-flex " style={{fontSize:"10px", width:"50%"}} ><input type="checkbox" className="me-2" name="doc[]" /> Aadhar Image</li>
                        <li className="d-flex " style={{fontSize:"10px", width:"50%"}} ><input type="checkbox" className="me-2" name="doc[]" /> Pan Image</li>
                        <li className="d-flex " style={{fontSize:"10px", width:"50%"}} ><input type="checkbox" className="me-2" name="doc[]" /> Student Sign</li>
                        <li className="d-flex " style={{fontSize:"10px", width:"50%"}} ><input type="checkbox" className="me-2" name="doc[]" /> Guardian Sign</li>
                        <li className="d-flex " style={{fontSize:"10px", width:"50%"}} ><input type="checkbox" className="me-2" name="doc[]" /> Caste Certificate</li>
                        <li className="d-flex " style={{fontSize:"10px", width:"50%"}} ><input type="checkbox" className="me-2" name="doc[]" /> Income Certificate</li>
                        <li className="d-flex " style={{fontSize:"10px", width:"50%"}} ><input type="checkbox" className="me-2" name="doc[]" /> Residential Certificate</li>
                        <li className="d-flex " style={{fontSize:"10px", width:"50%"}} ><input type="checkbox" className="me-2" name="doc[]" /> Migration</li>
                        <li className="d-flex " style={{fontSize:"10px", width:"50%"}} ><input type="checkbox" className="me-2" name="doc[]" /> T.C - (Transfer Certificate)</li>

                      </ul>
                    </div>

                    <div className="card-footer pt-0 ">
                      <button className="btn btn-primary">Save</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
