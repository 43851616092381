import {
  branchUploadFailure,
  branchUploadSuccess,
  loaderAction,
  showMyAlert,
} from "../action";
import { BRANCH_ACTION } from "../action/constant";
import serverCallFuction from "../constantFunction";

export const getAllBranch = () => async (dispatch) => {
  try {
    dispatch(loaderAction(true));
    const res = await serverCallFuction("GET", "branch");

    if (res.status === "Success") {
      dispatch(branchUploadSuccess(res, "getBranches"));
      dispatch(showMyAlert("All branches fetched", "success"));
    } else {
      dispatch(branchUploadFailure());
      dispatch(showMyAlert("Server internal error", "danger"));
    }
    dispatch(loaderAction(false));
  } catch (error) {
    dispatch(branchUploadFailure());
    dispatch(showMyAlert("Execption error", "danger"));
  }
};

export const addNewBranch = (body, callBack) => async (dispatch) => {
  try {
    dispatch(loaderAction(true));
    const res = await serverCallFuction("POST", "branch/stepone", body);

    if (res.status === "Success") {
      dispatch(branchUploadSuccess(res, "addBranch"));
      dispatch(showMyAlert("Branch has been created", "success"));

      if (typeof callBack === "function") {
        callBack();
      }
    } else {
      dispatch(showMyAlert(res.message, "warning"));
      dispatch(branchUploadFailure());
    }
    dispatch(loaderAction(false));
  } catch (error) {
    dispatch(branchUploadFailure());
    dispatch(showMyAlert("Execption error", "danger"));
  }
};

export const updateBranch = (params, body, callBack) => async (dispatch) => {
  try {
    const res = await serverCallFuction(
      "PUT",
      `branch/updateBranch/${params.id}`,
      body
    );
    if (res.status === "Success") {
      dispatch(branchUploadSuccess(res, "updateBranch_" + params.id));
      dispatch(showMyAlert("Branch has been created", "success"));

      if (typeof callBack === "function") {
        callBack();
      }
    } else {
      dispatch(showMyAlert(res.message, "warning"));
      dispatch(branchUploadFailure());
    }
  } catch (error) {
    dispatch(branchUploadFailure());
    dispatch(showMyAlert("Execption error", "danger"));
  }
};

export const DeleteBranch = (id) => async (dispatch) => {
  try {
    const res = await serverCallFuction("DELETE", `branch/${id}`);
    if (res.status === "Success") {
      dispatch(branchUploadSuccess(res, "deleteBranch_" + id));
      dispatch(showMyAlert("Branch has been deleted", "success"));
    } else {
      dispatch(showMyAlert(res.message, "warning"));
      dispatch(branchUploadFailure());
    }
  } catch (error) {
    dispatch(branchUploadFailure());
    dispatch(showMyAlert("Execption error", "danger"));
  }
};

export const ADDSession = (type, id, data, callBack) => async (dispatch) => {
  try {
    const res = await serverCallFuction(
      "PUT",
      `branch/updateBranch/${type}/${id}`,
      data
    );
    if (res.status === "Success") {
      dispatch(branchUploadSuccess(res, "addSession_" + id));
      dispatch(showMyAlert("Session has been Added", "success"));

      if (typeof callBack === "function") {
        callBack();
      }
    } else {
      dispatch(showMyAlert(res.message, "warning"));
      dispatch(branchUploadFailure());
    }
  } catch (error) {
    dispatch(branchUploadFailure());
    dispatch(showMyAlert("Execption error", "danger"));
  }
};

export const setActiveSession = (id, s_id) => async (dispatch) => {
  try {
    const res = await serverCallFuction(
      "PUT",
      `branch/setSessionCurrent/${id}/${s_id}`
    );
    if (res.status === "Success") {
      dispatch(branchUploadSuccess(res, "setActiveSession_" + id));
      dispatch(showMyAlert("Session has been Added", "success"));
    } else {
      dispatch(showMyAlert(res.message, "warning"));
      dispatch(branchUploadFailure());
    }
  } catch (error) {
    dispatch(branchUploadFailure());
    dispatch(showMyAlert("Execption error", "danger"));
  }
};

const selected_branch = [];
const branchArrayState = (state = selected_branch, action) => {
  console.log("branch data function called");
  switch (action.type) {
    case BRANCH_ACTION.success:
      if (action.u_type === "getBranches") {
        state = action.payload.data;
      } else if (action.u_type === "addBranch") {
        state = state.concat(action.payload.data);
      } else if (action.u_type.includes("updateBranch")) {
        const data = action.u_type.split("_");
        state = state.filter((br) => {
          return br._id !== data[1] ? action.payload.data : br;
        });
      } else if (action.u_type.includes("deleteBranch")) {
        const data = action.u_type.split("_");

        state = state.filter((br) => {
          return br._id !== data[1];
        });
      } else if (action.u_type.includes("addSession")) {
        const data = action.u_type.split("_");
        state = state.map((con) => {
          return con._id == data[1] ? action.payload.data : con;
        });
      } else if (action.u_type.includes("setActiveSession")) {
        const data = action.u_type.split("_");
        state = state.map((con) => {
          return con._id == data[1] ? action.payload.data : con;
        });
      }

      return state;
    case BRANCH_ACTION.failed:
      console.log("succes call from branch");
      return state;
    default:
      return state;
  }
};

export default branchArrayState;
