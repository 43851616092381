export const dateDiffrent = (g_date) => {
  // Given date

  const givenDate = new Date(g_date);

  // Current date
  const currentDate = new Date();

  // Calculate the difference in milliseconds
  const timeDifference = currentDate - givenDate;

  // Convert milliseconds to days
  const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

  console.log("g data - "+givenDate + " | c data - "+currentDate + " | diff - "+daysDifference);

  return daysDifference;
};
