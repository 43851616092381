import React from "react";
import "./featured.css";
const Featured = () => {
  return (
    <>
      <div className="container my-5">
        <div className="head">
          <span className="FHeading">
            Find Better Solutions Build Better Products
          </span>
        </div>
        <div className="row">
          <div className="col-md-3 col-10 mx-auto">
            <span className="imgSec">
              <img src="./assets/images/Group.png" alt="" />
            </span>
            <p className="multipleBranch">Multiple Branch</p>
            <span className="mul_con">
              You can manage multiple branch with single software.
            </span>
          </div>
          <div className="col-md-3 col-10 mx-auto">
            <span className="imgSec">
              <img src="./assets/images/form.png" alt="" />
            </span>
            <p className="multipleBranch">Dynamic Student Form</p>
            <span className="mul_con">
              You can create the registration form for the student as per your
              choice.
            </span>
          </div>
          <div className="col-md-3 col-10 mx-auto">
            <span className="imgSec">
              <img src="./assets/images/fees.png" alt="" />
            </span>
            <p className="multipleBranch">Fees Management</p>
            <span className="mul_con">
              Effortless Fee Management: Simplify, Track, Thrive!
            </span>
          </div>
          <div className="col-md-3 col-10 mx-auto">
            <span className="imgSec">
              <img src="./assets/images/idcard.png" alt="" />
            </span>
            <p className="multipleBranch">Id Card Management</p>
            <span className="mul_con">
              With this software you can setup the ID card yourself.
            </span>
          </div>
        </div>
        <div className="row mt-md-5 pt-5">
        <div className="col-md-3 col-10 mx-auto">
            <span className="imgSec">
              <img src="./assets/images/exam.png" alt="" />
            </span>
            <p className="multipleBranch">Marks Management</p>
            <span className="mul_con">It provide to manage exam marking</span>
          </div>
          <div className="col-md-3 col-10 mx-auto">
            <span className="imgSec">
              <img src="./assets/images/staff.png" alt="" />
            </span>
            <p className="multipleBranch">Staff Management</p>
            <span className="mul_con">
              You can manage your staff with iVIDHAN.
            </span>
          </div>
          <div className="col-md-3 col-10 mx-auto">
            <span className="imgSec">
              <img src="./assets/images/support.png" alt="" />
            </span>
            <p className="multipleBranch">24X7 Support</p>
            <span className="mul_con">Feel free to call us</span>
          </div>
          <div className="col-md-3 col-10 mx-auto">
            <span className="imgSec">
              <img src="./assets/images/upcoming.png" alt="" />
            </span>
            <p className="multipleBranch">Upcomming </p>
            <span className="mul_con">
              Student attendance, quotes upload, Group chatting etc features is
              coming soon.
            </span>
          </div>
        </div>
        {/*  */}

        {/* <div className="fetures_wrapper mt-5">
          <ul className="feCon">
            <li className="itemsF me-3 ">
              <span className="imgSec">
                <img src="./assets/images/Group.png" alt="" />
              </span>
              <p className="multipleBranch">Multiple Branch</p>
              <span className="mul_con">
                You can manage multiple branch with single software.
              </span>
            </li>
            <li className="itemsF me-3 ">
              <span className="imgSec">
                <img src="./assets/images/Group.png" alt="" />
              </span>
              <p className="multipleBranch">Multiple Branch</p>
              <span className="mul_con">
                You can manage multiple branch with single software.
              </span>
            </li>
            <li className="itemsF me-3">
              <span className="imgSec">
                <img src="./assets/images/Group.png" alt="" />
              </span>
              <p className="multipleBranch">Multiple Branch</p>
              <span className="mul_con">
                You can manage multiple branch with single software.
              </span>
            </li>
            <li className="itemsF me-3">
              <span className="imgSec">
                <img src="./assets/images/Group.png" alt="" />
              </span>
              <p className="multipleBranch">Multiple Branch</p>
              <span className="mul_con">
                You can manage multiple branch with single software.
              </span>
            </li>
          </ul>
          <ul className="feCon mt-5">
            <li className="itemsF me-3">
              <span className="imgSec">
                <img src="./assets/images/Group.png" alt="" />
              </span>
              <p className="multipleBranch">Multiple Branch</p>
              <span className="mul_con">
                You can manage multiple branch with single software.
              </span>
            </li>
            <li className="itemsF me-3">
              <span className="imgSec">
                <img src="./assets/images/Group.png" alt="" />
              </span>
              <p className="multipleBranch">Multiple Branch</p>
              <span className="mul_con">
                You can manage multiple branch with single software.
              </span>
            </li>
            <li className="itemsF me-3">
              <span className="imgSec">
                <img src="./assets/images/Group.png" alt="" />
              </span>
              <p className="multipleBranch">Multiple Branch</p>
              <span className="mul_con">
                You can manage multiple branch with single software.
              </span>
            </li>
            <li className="itemsF me-3">
              <span className="imgSec">
                <img src="./assets/images/Group.png" alt="" />
              </span>
              <p className="multipleBranch">Multiple Branch</p>
              <span className="mul_con">
                You can manage multiple branch with single software.
              </span>
            </li>
          </ul>
        </div> */}
      </div>
    </>
  );
};

export default Featured;
