import React, { useState } from 'react';
import { StudentsCon } from '../AllContext';

const StudentState = (props) => {
  const [students, setStudents] = useState([]);

  const host = process.env.REACT_APP_URL;
  const token = localStorage.getItem('token');
  const b_data = JSON.parse(localStorage.getItem('selected_branch'));

  const getStudent = async () => {
    const b_id = b_data._id;
    const session = b_data.sessions.filter((ses) => {
      return ses.current;
    });
    const session_year = session[0].session_year;
    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'auth-token': token,
      },
    };
    const response = await fetch(
      `${host}/api/student/getStudents/${b_id}`,
      requestOptions
    );
    const datares = await response.json();

    console.log(datares);
    if (datares.status === 'Success') {
      setStudents(datares.data);
    }

    return datares.status;
  };

  const addStudent = async (data) => {
    const b_id = b_data._id;
    const session = b_data.sessions.filter((ses) => {
      return ses.current;
    });
    const session_year = session[0].session_year;

    data.b_id = b_id;
    data.session = session_year;
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'auth-token': token,
      },
      body: JSON.stringify(data),
    };
    const response = await fetch(`${host}/api/student/add`, requestOptions);
    const datares = await response.json();

    console.log(datares);
    if (datares.status === 'Success') {
      setStudents([...students, datares.data]);
    }
    return datares.status;
  };

  const deleteStudent = async (id) => {
    
    const requestOptions = {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'auth-token': token,
      },
      
    };
    const response = await fetch(`${host}/api/student/${id}`, requestOptions);
    const datares = await response.json();

    console.log(datares);
    if (datares.status === 'Success') {
      setStudents(students.filter((data)=>{
        return data._id != id
      }));
    }
    return datares.status;
  };

  const udpateStudent = async (id, data) => {
    
    const requestOptions = {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'auth-token': token,
      },
      body:JSON.stringify(data)
    };


    console.log("upate request");
    console.log(id);
    console.log(data);
    const response = await fetch(`${host}/api/student/${id}`, requestOptions);
    const datares = await response.json();

    console.log(datares);
    if (datares.status === 'Success') {
      setStudents(students.filter((data)=>{
        return data._id != id
      }));
    }
    return datares.status;
  };

  return (
    <StudentsCon.Provider value={{ students, getStudent, addStudent, deleteStudent,udpateStudent }}>
      {props.children}
    </StudentsCon.Provider>
  );
};

export default StudentState;
