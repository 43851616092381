import React, { useEffect, useRef, useState } from "react";
import ReactToPrint,{ useReactToPrint } from "react-to-print";

const IdCardPreview = ({ obj, openmodal, imageData, allImageData }) => {
  const style = {
    background: "#00000080",
    display: "block",
  };
  // console.log(imageData);
  console.log(obj);
  // console.log(allImageData);

  const [configData, setConfigData] = useState([]);

  useEffect(() => {
    const data = allImageData.filter((image) => {
      console.log(image);
      return image.type === "idCard";
    });

    setConfigData(data);
  }, []);

  const printRef = useRef();

  const printIdCard =useReactToPrint({
    content: () => printRef.current,
    documentTitle: "Student ID Card",
  });

  return (
    <div className="modal reviewModal" style={style}>
      <div className="modal-dialog" style={{ width: "50%" }}>
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              Id Card
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() => {
                openmodal();
              }}
            ></button>
          </div>
          <div className="modal-body" style={{ textAlign: "left" }}>
            <div className="d-flex" ref={printRef}>
              <div className="idDiv front">
                {imageData && (
                  <img src={imageData.idCardFront} alt="Id Card Front" />
                )}

                <div className="upper_div">
                  {configData.length > 0 &&
                    configData[0].config.map((config, index) => {
                      const m_key = config.tag_value.replace(/[{}]/g, "");

                      const s_data = obj.fields.filter((f) => {
                        return f.name === m_key;
                      });
                      return (
                        <div className="" key={index} style={config.style}>
                          {s_data[0].value}
                        </div>
                      );
                    })}
                </div>
              </div>

              <div className="idDiv back"></div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              className="btn btn-primary"
              onClick={printIdCard}
            >
              Print ID card
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IdCardPreview;
