import React, { useEffect, useState } from "react";
import "./price.css";
import serverCallFuction from "../../../adminLayout/redux/constantFunction";
import { Link } from "react-router-dom";
const Price = () => {
  const [subscriptions, setSubscriptions] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        const subscriptionsData = await serverCallFuction(
          "GET",
          "subscription/getSubscription"
        );
        console.log("Subscriptions:", subscriptionsData);
        if (subscriptionsData.status === "Success"){
setSubscriptions(subscriptionsData.data);
        } 
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    fetchData();
  }, []);
  return (
    <>
      <div className="container my-5" id="pricing">
        <div className="row ">
          <div className="col-12 my-5">
            <div className="howHeading">Best pricing designed for you</div>
          </div>

          {subscriptions.length > 0 ? (
            subscriptions.map((subscription, index) => (
              <div key={index} className="col-10 mx-auto mt-3 mt-md-0 col-md-4">
                <div className="card linearCls">
                  <div className="card-body">
                    <h5 className="card-title">{subscription.planName}</h5>
                    <div className="pricecon d-flex mb-1">
                      <span className="priceDiv">₹{subscription.price}/</span>
                      <p className="mon">{subscription.duration}</p>
                    </div>

                    <strike className="my-2">₹{subscription.perday}</strike>

                    <h6>Features</h6>
                    <ul className="feature_list">
                      {subscription.features.map((feature, index) => (
                        <li key={index}>{feature.feature_values}</li>
                      ))}
                    </ul>
                    <Link to={"auth/checkout/" + subscription._id }>
                      <span className="priceContan">Get Now</span>
                    </Link>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div>No subscriptions available</div>
          )}

          {/*  {subscriptions.map((subscription, index) => (
            <div key={index} className="col-10 mx-auto mt-3 mt-md-0 col-md-4">
              <div className="card linearCls">
                <div className="card-body">
                  <h5 className="card-title">{subscription.planName}</h5>
                  <div className="pricecon d-flex mb-3">
                    <span className="priceDiv">{subscription.price}</span>
                    <p className="mon">{subscription.duration}</p>
                  </div>

                  <h6>Features</h6>
                  <ul className="feature_list">
                    {subscription.features.map((feature, index) => (
                      <li key={index}>{feature.feature_values}</li>
                    ))}
                  </ul>
                  <span className="priceContan">Get Now</span>
                </div>
              </div>
            </div>
          ))} */}
          {/* 
          <div className="col-10 mx-auto mt-3 mt-md-0 col-md-4">
            <div className="card linearCls">
              <div className="card-body">
                <h5 className="card-title">Basic</h5>
                <div className="pricecon d-flex mb-3">
                  <span className="priceDiv">₹ 899.00/</span>
                  <p className="mon">month</p>
                </div>

                <h6>Features</h6>
                <ul className="feature_list">
                  <li>Multiple Branch Manage</li>
                  <li>Class/Subject Configration</li>
                  <li>Dynamic Student Registration Form</li>
                  <li>ID Card/ Marksheet Manage</li>
                  <li>Fees Management</li>
                  <li>Exam Management</li>
                  <li>Staff Management</li>
                  <li>24X7 Support</li>
                </ul>
                <span className="priceContan">Get Now</span>
              </div>
            </div>
          </div>
          <div className="col-10 mx-auto mt-3 mt-md-0 col-md-4">
            <div className="card linearCls">
              <div className="card-body">
                <h5 className="card-title">Intermediate</h5>
                <div className="mrp text-center">
                  <strike>₹ 9,999.00</strike>{" "}
                  <span className="badge bg-success">SAVE 75%</span>
                </div>
                <div className="pricecon d-flex my-3 align-items-center">
                  <span className="priceDiv">₹ 5,999.00/</span>
                  <p className="mon">year</p>
                </div>

                <h6>Features</h6>
                <ul className="feature_list">
                  <li>Multiple Branch Manage</li>
                  <li>Class/Subject Configration</li>
                  <li>Dynamic Student Registration Form</li>
                  <li>ID Card/ Marksheet Manage</li>
                  <li>Fees Management</li>
                  <li>Exam Management</li>
                  <li>Staff Management</li>
                  <li>24X7 Support</li>
                </ul>
                <span className="priceContan">Get Now</span>
              </div>
            </div>
          </div>
          <div className="col-10 mx-auto mt-3 mt-md-0 col-md-4">
            <div className="card linearCls">
              <div className="card-body">
                <h5 className="card-title">Advance</h5>
                <div className="mrp text-center">
                  <strike>₹ 49,999.00</strike>{" "}
                  <span className="badge bg-success">SAVE 75%</span>
                </div>
                <div className="pricecon d-flex mb-3">
                  <span className="priceDiv">₹ 26,999.00/</span>
                  <p className="mon">5 year</p>
                </div>

                <h6>Features</h6>
                <ul className="feature_list">
                  <li>Multiple Branch Manage</li>
                  <li>Class/Subject Configration</li>
                  <li>Dynamic Student Registration Form</li>
                  <li>ID Card/ Marksheet Manage</li>
                  <li>Fees Management</li>
                  <li>Exam Management</li>
                  <li>Staff Management</li>
                  <li>24X7 Support</li>
                </ul>
                <span className="priceContan">Get Now</span>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default Price;
