import React from 'react'
import "./header.css"
import { Link } from 'react-router-dom';
const Header = () => {
  return (
    <section className="header_section py-3">
      <div className="container ">
        <div className="header_con d-flex justify-content-between">
          <span className="ividhan_heading">
            iVIDHAN - An Education Software
          </span>
          <div className="button_container">
            <Link to={"tel:+916396617714"}>
            <span className="contact me-2">Contact Us</span>
            </Link>

            <Link to={"/admin"}>
            <span className="login me-2">Login</span></Link>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Header
