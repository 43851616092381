import React from "react";

const Terms_Condition = () => {
  return (
    <div className="container my-5" style={{ textAlign: "left" }}>
      <div className="row">
        <div className="col-12">
          <h4>Terms and Conditions of Use</h4>
          <p>
            These terms and conditions govern your use of iVIDHAN - School, Coaching Management Software ("the Software"). By accessing or using
            the Software, you agree to be bound by these terms and conditions.
            If you do not agree with these terms and conditions, please do not
            use the Software.
          </p>
          <h5>Use of the Software</h5>
          <ul>
            <li>
              <strong>Account Registration: </strong>In order to access certain
              features of the Software, you may be required to register for an
              account. You agree to provide accurate, current, and complete
              information during the registration process and to keep your
              account information up to date.
            </li>

            <li>
              <strong>User Responsibilities: </strong>You are responsible for
              maintaining the confidentiality of your account credentials and
              for all activities that occur under your account. You agree to use
              the Software in compliance with applicable laws, regulations, and
              school policies.
            </li>
          </ul>

          <h5>Intellectual Property</h5>
          <ul>
            <li>
              <strong>Ownership:</strong> The Software and its contents,
              including but not limited to text, graphics, logos, button icons,
              images, audio clips, and software, are the property of VIPsGrow - IT Solution or its licensors and are protected by copyright,
              trademark, and other intellectual property laws.
            </li>
            <li>
              <strong>License:</strong> VIPsGrow - IT Solution grants you a
              limited, non-exclusive, non-transferable license to use the
              Software for your personal or internal business purposes. You may
              not modify, adapt, sublicense, or reverse engineer the Software.
            </li>
          </ul>

          <h5>Limitation of Liability</h5>
          <ul>
            <li>
              <strong>Disclaimer:</strong> The Software is provided on an "as
              is" and "as available" basis, without any warranties of any kind,
              either express or implied. VIPsGrow - IT Solution disclaims all
              warranties, including but not limited to the implied warranties of
              merchantability, fitness for a particular purpose, and
              non-infringement.
            </li>
            <li>
              <strong>Limitation of Liability:</strong> VIPsGrow - IT Solution
              shall not be liable for any direct, indirect, incidental, special,
              consequential, or punitive damages arising out of or in connection
              with your use of the Software, even if advised of the possibility
              of such damages.
            </li>
          </ul>
          <h5>Indemnification</h5>
          <p>
            You agree to indemnify and hold VIPsGrow - IT Solution harmless from
            and against any and all claims, liabilities, damages, losses, costs,
            and expenses, including legal fees, arising out of or in connection
            with your use of the Software or your violation of these terms and
            conditions.
          </p>
          <h5>Governing Law</h5>
          <p>
            These terms and conditions shall be governed by and construed in
            accordance with the laws of [Your Jurisdiction], without regard to
            its conflict of law principles. Any dispute arising out of or in
            connection with these terms and conditions shall be subject to the
            exclusive jurisdiction of the courts of [Your Jurisdiction].
          </p>
          <h5>Changes to these Terms and Conditions</h5>
          <p>
            VIPsGrow - IT Solution reserves the right to modify or amend these
            terms and conditions at any time. Changes will be effective
            immediately upon posting to the Software. Your continued use of the
            Software following the posting of changes constitutes your
            acceptance of such changes.
          </p>
          <h5>Contact Us</h5>
          <p>
            If you have any questions or concerns about these terms and
            conditions, please contact us at info@vipsgrow.in.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Terms_Condition;
