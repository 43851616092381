import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { addNewBranch, updateBranch } from "../../redux/reducer/branchState";
import { useNavigate, useParams } from "react-router-dom";

const AddBranch = (props) => {
  const dispatch = useDispatch();
  const history = useNavigate();
  const params = useParams();

  // add branch configration

  const [newBranch, setNewBranch] = useState({
    name: "",
    type: "",
    address: "",
    state: "",
    zipcode: "",
  });
  const [contactData, setContactData] = useState([
    {
      id: 0,
      name: "",
      phone: "",
      email: "",
    },
  ]);
  const addBranchServer = async (e) => {
    e.preventDefault();
    if (newBranch.name === "") {
      alert("Please Enter Branch Name");
      return;
    } else if (newBranch.type === "") {
      alert("Please choose Branch Type");
      return;
    } else if (newBranch.address === "") {
      alert("Please enter the Address");
      return;
    } else if (newBranch.state === "") {
      alert("Please enter the State");
      return;
    } else if (newBranch.zipcode === "") {
      alert("Please enter zipcode");
      return;
    } else {
      dispatch(
        addNewBranch(newBranch, () => {
          history("../branch");
        })
      );
    }
  };

  const onBranchInput = (e) => {
    setNewBranch({ ...newBranch, [e.target.name]: e.target.value });
  };

  const setContactDetails = (e, id) => {
    const data = contactData.map((con, i) => {
      return con.id == id ? { ...con, [e.target.name]: e.target.value } : con;
    });

    setContactData(data);

    setNewBranch({ ...newBranch, ["contact_data"]: data });
  };

  // edit branch start here

  const branchS_State = useSelector((state) => state.branchArrayState);
  const [editBranch, setEditBranch] = useState(null);
  useEffect(() => {
    if (params.id) {
      branchS_State.filter((fil) => {
        if (fil._id === params.id) {
          setEditBranch(fil);
          setNewBranch({
            ...newBranch,
            ["name"]: fil.branch_name,
            ["type"]: fil.b_type,
            ["address"]: fil.address,
            ["state"]: fil.state,
            ["zipcode"]: fil.zipcode,
          });
          console.log(fil.contact_details);
          setContactData(fil.contact_details);
        }
      });
    }
  }, []);

  const updateBranchServer = (e, id) => {
    e.preventDefault();
    if (newBranch.name === "") {
      alert("Please Enter Branch Name");
      return;
    } else if (newBranch.type === "") {
      alert("Please choose Branch Type");
      return;
    } else if (newBranch.address === "") {
      alert("Please enter the Address");
      return;
    } else if (newBranch.state === "") {
      alert("Please enter the State");
      return;
    } else if (newBranch.zipcode === "") {
      alert("Please enter zipcode");
      return;
    } else {
      dispatch(
        updateBranch({ id: id }, newBranch, () => {
          history("../branch");
        })
      );
    }
  };

  // useEffect(() => {
  //   const data = editBranch.contact_details.map((con, i) => {
  //     return {
  //       id: i,
  //       name: con.name,
  //       phone: con.phone,
  //       email: con.email,
  //     };
  //   });

  //   setContactData(data);

  //   console.log(editBranch.contact_details);
  // }, [editBranch.contact_details]);

  return (
    <div className="content-wrapper payments">
      <div className="container-xxl flex-grow-1 container-p-y">
        <div className="row">
          <div className="col-lg-12 mb-4 ">
            <div class="card">
              <h5 class="card-header"></h5>

              <div className="card-body">
                <div className="row">
                  <div className="col-12 col-lg-6">
                    <div class="form-group mb-2">
                      <label for="">Name</label>
                      <input
                        type="text"
                        class= "form-control"
                        name="name"
                        id=""
                        onChange={onBranchInput}
                        aria-describedby="helpId"
                        placeholder="Enter School/Collage/Coaching etc name..."
                        defaultValue={editBranch && editBranch.branch_name}
                      />
                    </div>

                    <div class="form-group">
                      <label for="">Type</label>
                      <select
                        class="form-control mb-3"
                        name="type"
                        id=""
                        onChange={onBranchInput}
                      >
                        <option>Choose Type</option>
                        <option
                          selected={
                            editBranch && editBranch.b_type === "collage"
                          }
                          value={"collage"}
                        >
                          Collage
                        </option>
                        <option
                          selected={
                            editBranch && editBranch.b_type === "school"
                          }
                          value={"school"}
                        >
                          School
                        </option>
                        <option
                          selected={
                            editBranch && editBranch.b_type === "coacing"
                          }
                          value={"coacing"}
                        >
                          Coaching
                        </option>
                        <option
                          selected={
                            editBranch && editBranch.b_type === "acedemy"
                          }
                          value={"acedemy"}
                        >
                          Acedemy
                        </option>
                      </select>
                      {/* <small id="helpId" class="form-text text-muted">
                      Help text
                    </small> */}
                    </div>

                    <h5
                      style={{
                        textAlign: "left",
                        fontFamily: "pop_bold",
                        color: "black",
                      }}
                    >
                      Branch Address
                    </h5>

                    <div class="form-group mb-2">
                      <label for="">Address</label>

                      <input
                        type="text"
                        class="form-control"
                        name="address"
                        id=""
                        onChange={onBranchInput}
                        aria-describedby="helpId"
                        placeholder="Enter street, area..."
                        defaultValue={editBranch && editBranch.address}
                      />
                    </div>

                    <div className="row">
                      <div className="col-lg-6">
                        <div class="form-group">
                          <label for="">State</label>
                          <select
                            class="form-control"
                            name="state"
                            id=""
                            onChange={onBranchInput}
                          >
                            <option>Choose State</option>
                            <option value={"utter pradesh"}>
                              Utter Pradesh
                            </option>
                            <option value={"delhi"}>Delhi</option>
                          </select>
                        </div>
                      </div>

                      <div className="col-lg-6">
                        <div class="form-group">
                          <label for="">Zipcode</label>
                          <input
                            type="number"
                            class="form-control"
                            name="zipcode"
                            id=""
                            onChange={onBranchInput}
                            aria-describedby="helpId"
                            placeholder="000000"
                            defaultValue={editBranch && editBranch.zipcode}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-12 col-lg-6">
                    <h5
                      style={{
                        textAlign: "left",
                        fontFamily: "pop_bold",
                        color: "black",
                      }}
                      className="mt-3 mt-lg-0"
                    >
                      Contact Details
                    </h5>
                    {contactData &&
                      contactData.map((contact, index) => {
                        return (
                          <>
                            <div className="row mb-2" key={index}>
                              <div className="col-lg-6">
                                <div class="form-group">
                                  <label for="">Name</label>
                                  <input
                                    type="text"
                                    class="form-control"
                                    name="name"
                                    id=""
                                    onChange={(e) => {
                                      setContactDetails(e, contact.id);
                                    }}
                                    aria-describedby="helpId"
                                    placeholder="Enter branch contact person name..."
                                    defaultValue={contact.name}
                                  />
                                  <small
                                    id="helpId"
                                    class="form-text text-muted"
                                  >
                                    {" "}
                                  </small>
                                </div>
                              </div>

                              <div className="col-lg-6">
                                <div class="form-group">
                                  <label for="">Phone</label>
                                  <input
                                    type="number"
                                    class="form-control"
                                    name="phone"
                                    id=""
                                    onChange={(e) => {
                                      setContactDetails(e, contact.id);
                                    }}
                                    aria-describedby="helpId"
                                    placeholder="Enter branch phone no..."
                                    defaultValue={contact.phone}
                                  />
                                </div>
                              </div>
                            </div>

                            <div class="form-group mb-3">
                              <label for="">Email</label>
                              <input
                                type="email"
                                class="form-control"
                                name="email"
                                id=""
                                onChange={(e) => {
                                  setContactDetails(e, contact.id);
                                }}
                                aria-describedby="helpId"
                                placeholder="Enter branch email..."
                                defaultValue={contact.email}
                              />
                            </div>

                            <div className="d-flex justify-content-end">
                              {contactData.length == 2 ? (
                                <></>
                              ) : (
                                <span
                                  className="text-muted me-4"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    if (contactData.length < 2) {
                                      setContactData([
                                        ...contactData,
                                        {
                                          id: contact.id++,
                                          name: "",
                                          phone: "",
                                          email: "",
                                        },
                                      ]);
                                    }
                                  }}
                                >
                                  + Add
                                </span>
                              )}

                              {contactData.length == 1 ? (
                                <></>
                              ) : (
                                <span
                                  className="text-muted"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    if (contactData.length > 1) {
                                      setContactData(
                                        contactData.filter((con) => {
                                          return con.id != contact.id;
                                        })
                                      );
                                    }
                                  }}
                                >
                                  - Remove
                                </span>
                              )}
                            </div>
                          </>
                        );
                      })}
                  </div>
                </div>
              </div>

              <div className="card-footer text-right">
                <button
                  className="btn btn-secondary me-4"
                  onClick={() => {
                    history("../branch");
                  }}
                >
                  Cancel
                </button>
                <button
                  className="btn btn-primary"
                  onClick={(e) => {
                    if (params.id) {
                      updateBranchServer(e, params.id);
                    } else {
                      addBranchServer(e);
                    }
                  }}
                >
                  {params.id ? "Save" : "Create"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddBranch;
