// DraggableItem.js
import React, { useState, useEffect } from 'react';
import { useDrag } from 'react-dnd';

const DraggableItem = ({ id, tag_value, style, children }) => {
  const [{ isDragging, offset }, drag] = useDrag({
    type: 'ITEM',
    item: { id , tag_value, style, children },
    
  });


  return (
    <div
      ref={drag}
   
    >
      {children}
    </div>
  );
};

export default DraggableItem;
