import React, { useEffect, useRef } from "react";
import flvjs from "flv.js";
const LiveClasses = () => {
  const videoRef = useRef(null);
  const mediaStream = useRef(null);

  useEffect(() => {
    async function startStreming() {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          video: true,
          audio: true,
        });

        mediaStream.current = stream;

        if (videoRef.current) {
          videoRef.current.srcObject = stream;
          // videoRef.current.play();
        }

        if (!flvjs.isSupported()) {
          alert("not supported");
        }

        const flvPlayer = flvjs.createPlayer(videoRef.current, {
          type: "flv",
          url: "rtmp://localhost:1935/live",
          isLive: true,
        });

        // flvPlayer.attachMediaElement(videoRef.current);
        flvPlayer.load();
      } catch (error) {
        console.log(error);
      }
    }

    startStreming();
    return () => {
      if (mediaStream.current) {
        mediaStream.current.getTracks().forEach((track) => {
          track.stop();
        });
      }
    };
  }, []);
  return (
    <div className="content-wrapper payments">
      <div className="container-xxl flex-grow-1 container-p-y">
        <div className="row">
          <div className="col-12">
            <video
              ref={videoRef}
              autoPlay
              muted
              style={{ border: "1px solid", width: "300px", height: "300px" }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LiveClasses;
